import { notification } from 'antd'
import React from 'react'
import { AccountConnection } from '../api-client/interface/AccountConnection'
import { ApiClient } from '../api-client/interface/ApiClient'
import { ConnectedAccounts } from '../components/ConnectedAccounts'

type Props = {
  apiClient: ApiClient
}

type State = {
  connectedAccounts: AccountConnection[]
}

export class ConnectedAccountsContainer extends React.Component<Props, State> {
  public state: State = {
    connectedAccounts: [],
  }

  componentDidMount() {
    this.getConnectedAccounts()
  }

  render() {
    return (
      <ConnectedAccounts
        connectedAccounts={this.state.connectedAccounts}
        onRemoveAccount={this.handleRemoveAccount}
        onConnectButtonClick={this.handleConnectAccount}
      />
    )
  }

  private async getConnectedAccounts() {
    try {
      this.setState({
        connectedAccounts: await this.props.apiClient.getConnectedAccounts(),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your connected accounts.',
      })
    }
  }

  private handleConnectAccount = async (authLink: string) => {
    let authTab = window.open(authLink)

    if (authTab) {
      const timer = setInterval(() => {
        if (authTab && authTab.closed) {
          this.getConnectedAccounts()
          clearInterval(timer)
        }
      }, 500)
    }
  }

  private handleRemoveAccount = async (provider: string, authId: string) => {
    try {
      await this.props.apiClient.removeConnectedAccount(provider, authId)
      this.getConnectedAccounts()
    } catch (e) {
      notification.error({
        message: 'An error occurred while removing your connected account.',
      })
    }
  }
}
