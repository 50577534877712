import * as React from 'react'
import { Checkbox, Input, Button } from 'antd'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import { CancelSubscriptionFeedback } from '../api-client/interface/Connection'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  userId: number
  email: string

  displayStep: boolean
  feedback: CancelSubscriptionFeedback
  onReasonsChange: (reasons: string[]) => void
  onRivalEditorChange: (cancelRivalEditor: string) => void
  onCommentChange: (comment: string) => void
  handleCancellationReasons: () => Promise<void>
}

type State = {
  noReasonsSelected: boolean
  noCommentAdded: boolean
  noRivalEditorAdded: boolean
  notUsingOptionExpanded: boolean
}

export const SURVEY_OPTIONS: { [x: string]: string } = {
  expensive: 'Codeanywhere’s Premium plans are too expensive',
  badCustomerSupport: 'I am disappointed with Codeanywhere’s Customer Support',
  notAsAdvertised: 'Does not work as advertised',
  techIssues: 'Too many technical issues',
  lackOfFeatures: 'Lack of Features',
  slow: 'Codeanywhere’s interface is too slow',
  notUsing: 'I am not using it enough to justify the expense',
}

export class CancelFeedbackStep extends React.Component<Props, State> {
  public state: State = {
    noReasonsSelected: false,
    noCommentAdded: false,
    noRivalEditorAdded: false,
    notUsingOptionExpanded: false,
  }

  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowFeedbackEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <div className="cancellation__plan">
          <h1 className="cancellation__plan-title">What went wrong?</h1>
          <h2 className="cancellation__plan-subtitle">
            We're always improving our service and your feedback matters.
          </h2>
        </div>

        <form id="cancel-survey-form" className="cancel-survey">
          <div
            className="feedback__warning-reasons cancellation__warning"
            style={{
              display: this.state.noReasonsSelected ? 'block' : 'none',
            }}
          >
            Please select at least one cancellation reason.
          </div>
          <div
            className="feedback__warning-rival-editor cancellation__warning"
            style={{
              display: this.state.noRivalEditorAdded ? 'block' : 'none',
            }}
          >
            Please specify which IDE you are using instead of Codeanywhere.
          </div>
          <h3 className="cancel-survey__title">
            What are your reason(s) to cancel your Codeanywhere account, please
            check all that apply.
          </h3>
          <CheckboxGroup
            value={this.props.feedback.reasons}
            onChange={reasons => {
              this.props.onReasonsChange(reasons as string[])

              if (reasons.includes('notUsing')) {
                this.setState({ notUsingOptionExpanded: true })
              }

              if (
                this.state.notUsingOptionExpanded &&
                !reasons.includes('notUsing')
              ) {
                this.setState({ notUsingOptionExpanded: false })
              }
            }}
          >
            {Object.keys(SURVEY_OPTIONS).map(option => (
              <div key={option}>
                <Checkbox
                  value={option}
                  style={{
                    display: 'block',
                  }}
                >
                  {SURVEY_OPTIONS[option]}
                </Checkbox>
                {this.state.notUsingOptionExpanded && option === 'notUsing' && (
                  <Input
                    value={this.props.feedback.rivalEditor}
                    onChange={e => {
                      this.props.onRivalEditorChange(e.currentTarget.value)
                    }}
                    placeholder="Which IDE are you using instead of Codeanywhere?"
                    className="rival-editor"
                  />
                )}
              </div>
            ))}
          </CheckboxGroup>

          <div
            className="feedback__warning-comment cancellation__warning"
            style={{
              display: this.state.noCommentAdded ? 'block' : 'none',
            }}
          >
            Do you have any other comments or suggestions that you would like to
            see in Codeanywhere?
          </div>
          <h3 className="cancel-survey__title">
            Sorry to see you go! Let us know what we could have done
            differently!
          </h3>
          <Input.TextArea
            value={this.props.feedback.comment}
            onChange={e => this.props.onCommentChange(e.target.value)}
          ></Input.TextArea>
        </form>

        <div className="cancellation__actions">
          <Button
            id="cancel-membership-feedback--button"
            className="cancellation__action cancellation__action--type-cancel"
            onClick={() => {
              this.handleFeedbackSubmit()
            }}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-feedback--button"
            type="primary"
            className="cancellation__action cancellation__action--type-success"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'cancelFlowKeep',
                  userId: this.props.userId,
                  emailHash: String(sha256(this.props.email)),
                },
              })
            }}
          >
            <a href="/" target="_self" rel="noopener noreferrer">
              Keep Membership
            </a>
          </Button>
        </div>
      </>
    ) : null
  }

  private handleFeedbackSubmit = async () => {
    let noReasonsSelected = false
    let noCommentAdded = false
    let noRivalEditorAdded = false

    if (this.props.feedback.reasons.length === 0) {
      noReasonsSelected = true
    }
    if (this.props.feedback.comment === '') {
      noCommentAdded = true
    }
    if (
      this.props.feedback.reasons.includes('notUsing') &&
      !this.props.feedback.rivalEditor
    ) {
      noRivalEditorAdded = true
    }

    if (noReasonsSelected || noCommentAdded || noRivalEditorAdded) {
      this.setState({
        noReasonsSelected,
        noCommentAdded,
        noRivalEditorAdded,
      })
    } else {
      document.querySelector('body')?.scrollTo(0, 0)
      await this.props.handleCancellationReasons()
      TagManager.dataLayer({
        dataLayer: {
          event: 'cancelFlowSendFeedback',
          userId: this.props.userId,
          emailHash: String(sha256(this.props.email)),
          feedback: this.props.feedback,
        },
      })
    }
  }
}
