export const entries = (obj: { [x: string]: any }) => {
  var ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i) // preallocate the Array
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

  return resArray
}

//TODO: types
export const assign = (target: any, args: any[]) => {
  if (target === null || target === undefined) {
    throw new TypeError('Cannot convert undefined or null to object')
  }

  var to = Object(target)

  for (var index = 1; index < args.length; index++) {
    var nextSource = args[index]

    if (nextSource !== null && nextSource !== undefined) {
      for (var nextKey in nextSource) {
        // Avoid bugs when hasOwnProperty is shadowed
        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
          to[nextKey] = nextSource[nextKey]
        }
      }
    }
  }
  return to
}
