import * as React from 'react'

import '../styles/AddonNumberPicker.css'

type Props = {
  addonNumber: number
  disabled: boolean
  onAddonNumberChange: (addonNumber: number) => void
}

export class AddonNumberPicker extends React.PureComponent<Props> {
  render() {
    return (
      <div className="quantity-picker">
        <span
          onClick={() => {
            if (this.props.addonNumber === 0 || this.props.disabled) {
              return
            }
            this.props.onAddonNumberChange(this.props.addonNumber - 1)
          }}
          className="decrement"
        >
          <span>-</span>
        </span>

        <input
          type="number"
          value={this.props.addonNumber}
          onChange={e => {
            this.props.onAddonNumberChange(parseInt(e.target.value))
          }}
          disabled={this.props.disabled}
          min="0"
        />
        <span
          onClick={() => {
            if (this.props.disabled) {
              return
            }

            this.props.onAddonNumberChange(this.props.addonNumber + 1)
          }}
          className="increment"
        >
          <span>+</span>
        </span>
      </div>
    )
  }
}
