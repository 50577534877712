import * as React from 'react'

type Props = {
  usage: number
  limit: number
  resource: string
}

export class ResourcesUsage extends React.PureComponent<Props> {
  render() {
    return (
      <div className="upgrade-row">
        <div className="upgrade-row__top">
          <div
            className={`resource__progress ${
              this.props.limit === 0 ||
              this.props.usage / this.props.limit > 0.66
                ? 'red'
                : this.props.usage / this.props.limit < 0.33
                ? 'green'
                : 'yellow'
            }`}
            style={{
              width: `${(this.props.usage / this.props.limit) * 100}%`,
              height: '5px',
            }}
          ></div>
        </div>
        <div className="upgrade-row__bot">
          <div className="upgrade-row__bot--left">
            {this.props.usage}/{this.props.limit}
          </div>
          <div className="upgrade-row__bot--right">{this.props.resource}</div>
        </div>
      </div>
    )
  }
}
