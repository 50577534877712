import React from 'react'
import { ResourcesUsage } from './ResourcesUsage'
import { Spin } from 'antd'

type Props = {
  usedContainers: number
  maxContainers: number
  usedRemoteConnections: number
  maxRemoteConnections: number
  loadingResources: boolean
}

export class AccountLimits extends React.PureComponent<Props> {
  render() {
    return (
      <div className="card-wrap">
        <h1 id="resources-usage">Need more resources?</h1>
        <div className="card resources">
          <div
            style={{
              display: this.props.loadingResources ? 'flex' : 'none',
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Spin size="large" />
            <div
              style={{
                flexBasis: '100%',
                textAlign: 'center',
                marginTop: '5px',
              }}
            >
              Loading resources info...
            </div>
          </div>
          <div className="card__section col">
            <div className="card-title2 center">Account Limits</div>
            <div
              className="upgrade-wrap"
              style={{
                borderTop: 0,
                width: '100%',
                marginTop: 10,
                marginBottom: 30,
              }}
            >
              <ResourcesUsage
                usage={this.props.usedContainers}
                limit={this.props.maxContainers}
                resource="Containers"
              />
              <ResourcesUsage
                usage={this.props.usedRemoteConnections}
                limit={this.props.maxRemoteConnections}
                resource="Remote Connections"
              />
            </div>

            <div className="card-footer">
              <button
                className="btn btn--purple"
                onClick={() => window.open('/checkout', '_blank')}
                id="btn-expand-resources"
              >
                Change
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
