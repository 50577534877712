import React from 'react'
import { AccountLimits } from '../components/AccountLimits'
import { ApiClient } from '../api-client/interface/ApiClient'
import { notification } from 'antd'

type Props = {
  apiClient: ApiClient
}
type State = {
  usedContainers: number
  maxContainers: number
  usedRemoteConnections: number
  maxRemoteConnections: number
  loadingResources: boolean
}

export class AccountLimitsContainer extends React.Component<Props, State> {
  public state: State = {
    usedContainers: -1,
    maxContainers: -1,
    usedRemoteConnections: -1,
    maxRemoteConnections: -1,
    loadingResources: true,
  }

  public componentDidMount() {
    this.getAccountDetails()
  }

  render() {
    return (
      <AccountLimits
        usedContainers={this.state.usedContainers}
        maxContainers={this.state.maxContainers}
        usedRemoteConnections={this.state.usedRemoteConnections}
        maxRemoteConnections={this.state.maxRemoteConnections}
        loadingResources={this.state.loadingResources}
      />
    )
  }

  private async getAccountDetails() {
    try {
      const details = await this.props.apiClient.getAccountDetails()

      this.setState({
        usedContainers: details.usage.devboxes,
        maxContainers: details.limits.devboxes,
        usedRemoteConnections: details.usage['remote-servers'],
        maxRemoteConnections: details.limits['remote-servers'],
        loadingResources: false,
      })
    } catch (ex) {
      notification.error({
        message: 'Error getting account details',
        description: String(ex),
      })
    }
  }
}
