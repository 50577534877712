import * as React from 'react'
import { Button /* , Modal */ } from 'antd'
import VSCodeEditor from '../styles/img/vscode-editor.png'
import ShareIcon from '../styles/img/share.svg'
import IntelliSenseIcon from '../styles/img/intellisense.svg'
import DebuggerIcon from '../styles/img/debugger.svg'
import GitGuiIcon from '../styles/img/git-gui.svg'
import ExtensionsIcon from '../styles/img/extensions.svg'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  userId: number
  email: string

  username: string
  firstName: string

  displayStep: boolean
  goToNextStep: () => void
}

/* type State = {
  modalVisible: boolean
} */

export class CancelNewEditorStep extends React.Component<Props /* , State */> {
  /* public state: State = {
    modalVisible: false,
  } */

  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowNewEditorEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <div className="cancellation__plan">
          <h1 className="cancellation__plan-title">
            {this.props.firstName !== ''
              ? this.props.firstName
              : this.props.username}
            , did you try out our new VS Code based IDE?
          </h1>
          <h2 className="cancellation__plan-subtitle">
            We developed a new and improved editor with a completely revamped
            experience.
          </h2>
        </div>
        <div className="cancellation-ilustration-wrapper">
          <img
            src={VSCodeEditor}
            alt="VS Code editor"
            className="small-ilustration"
          />
        </div>
        <div className="cancellation__bullets feature__bullets">
          <h2 className="feature__bullets-title">What are the benefits?</h2>
          <ul className="cancellation__bullets-list">
            <li className="cancellation__bullets-list-item">
              <img src={ShareIcon} alt="Share icon" className="feature-icon" />
              Share &amp; collaborate in real time
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={IntelliSenseIcon}
                alt="IntelliSense icon"
                className="feature-icon"
              />
              IntelliSense
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={DebuggerIcon}
                alt="Debugger icon"
                className="feature-icon"
              />
              Integrated Debugger
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={GitGuiIcon}
                alt="Git GUI icon"
                className="feature-icon"
              />
              GUI Git Client
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={ExtensionsIcon}
                alt="Extensions icon"
                className="feature-icon"
              />
              Vast library of extensions
            </li>
          </ul>
          <h2 className="feature__bullets-title">How to use?</h2>
          <ul className="cancellation__bullets-list">
            <li className="cancellation__bullets-list-item">
              <span className="ordinal">1.</span>Create a container by clicking
              the <span className="green-emphasis">New Container</span> button.
            </li>
            <li className="cancellation__bullets-list-item">
              <span className="ordinal">2.</span>Click the{' '}
              <span className="green-emphasis">Open IDE</span> button.
            </li>
          </ul>
        </div>
        <div className="cancellation__plan-spacing"></div>
        <div className="cancellation__actions">
          <Button
            id="cancel-membership-neweditor--button"
            className="cancellation__action cancellation__action--type-cancel"
            onClick={() => {
              document.querySelector('body')?.scrollTo(0, 0)
              this.props.goToNextStep()
            }}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-neweditor--button"
            type="primary"
            className="cancellation__action cancellation__action--type-success"
            /* onClick={() => this.setState({ modalVisible: true })} */
            onClick={() =>
              TagManager.dataLayer({
                dataLayer: {
                  event: 'cancelFlowTryNewEditor',
                  userId: this.props.userId,
                  emailHash: String(sha256(this.props.email)),
                },
              })
            }
          >
            <a href="/" target="_self" rel="noopener noreferrer">
              Try now
            </a>
          </Button>
        </div>
      </>
    ) : null
  }
}
