import blank from '../../styles/img/container-types/default.png'
import c from '../../styles/img/container-types/c.png'
import dotnet from '../../styles/img/container-types/dotnet.png'
import firehose from '../../styles/img/container-types/firehose.png'
import go from '../../styles/img/container-types/go.png'
import html from '../../styles/img/container-types/html.png'
import java from '../../styles/img/container-types/java.png'
import node from '../../styles/img/container-types/nodejs.png'
import php from '../../styles/img/container-types/php.png'
import python from '../../styles/img/container-types/python.png'
import ruby from '../../styles/img/container-types/ruby.png'
import stemi from '../../styles/img/container-types/stemi.png'
import wordpress from '../../styles/img/container-types/wordpress.png'

export const CONTAINER_TYPE: { [x: string]: string } = {
  default: blank,
  c,
  cpp: c,
  'C#': dotnet,
  firehose,
  go,
  html,
  java,
  node,
  php,
  python,
  ruby,
  stemi,
  wordpress,
}
