import {
  Elements,
  RecurlyProvider,
  UseRecurlyInstance,
} from '@recurly/react-recurly'
import * as React from 'react'
import { BillingInfoDto } from '../containers/CheckoutStep3Container'
import { Validation } from '../helpers/validation/Validation'
import { RecurlyCardElementsWrapper } from './RecurlyCardElementsWrapper'
import { CheckoutTermsOfService } from './CheckoutTermsOfService'
import { CheckoutBillingSecurity } from './CheckoutBillingSecurity'
import WarningSignIcon from '../styles/img/warning-sign.svg'
import { Subscription } from '../api-client/interface/Subscription'
import { Spin } from 'antd'

type Props = {
  validation: Validation
  form: React.RefObject<HTMLFormElement>
  billingInfo: BillingInfoDto
  additionalFieldsActive: boolean
  onAdditionalFieldsChange: (additionalFieldsActive: boolean) => void
  onBillingInfoChange: (billingInfo: BillingInfoDto) => void
  setRecurly: (recurly: UseRecurlyInstance) => void
  onConfirm: () => Promise<void>
  loadingPaymentConfirm: boolean
  subscription: Subscription | null
  loadingSubscription: boolean
  refundPeriodExpired: boolean
  onFirstSubscription: boolean
}

export class CheckoutStep3BillingInfo extends React.Component<Props> {
  render() {
    return (
      <RecurlyProvider
        publicKey={String(process.env.REACT_APP_RECURLY_PUBLIC_KEY)}
      >
        <Elements>
          <div className="order-section">
            <h3 className={'order-title'}>Payment Information</h3>
            <form
              className="editBillingInformation ant-row"
              ref={this.props.form}
            >
              <div
                className={`ant-col-24 ant-col-sm-12 billing-information-field ant-col${
                  this.props.validation.errors['firstName'] ? ' error' : ''
                }`}
              >
                <label>First name</label>
                <input
                  type="text"
                  value={this.props.billingInfo.firstName}
                  data-recurly="first_name"
                  data-key="first_name"
                  onChange={e => {
                    this.props.onBillingInfoChange({
                      ...this.props.billingInfo,
                      firstName: e.target.value,
                    })
                  }}
                />
                <div className="input-error">
                  <div>
                    <img src={WarningSignIcon} alt="Validation warning icon" />
                  </div>
                  {this.props.validation.errors['firstName']}
                </div>
              </div>
              <div
                className={`ant-col-24 ant-col-sm-12 billing-information-field ant-col${
                  this.props.validation.errors['lastName'] ? ' error' : ''
                }`}
              >
                <label>Last name</label>
                <input
                  type="text"
                  value={this.props.billingInfo.lastName}
                  data-recurly="last_name"
                  data-key="last_name"
                  onChange={e => {
                    this.props.onBillingInfoChange({
                      ...this.props.billingInfo,
                      lastName: e.target.value,
                    })
                  }}
                />
                <div className="input-error">
                  <div>
                    <img src={WarningSignIcon} alt="Validation warning icon" />
                  </div>
                  {this.props.validation.errors['lastName']}
                </div>
              </div>
              <div className="ant-col ant-col-24"></div>
              <input
                type="hidden"
                name="recurly-token"
                data-recurly="token"
                value=""
              ></input>
              <RecurlyCardElementsWrapper setRecurly={this.props.setRecurly} />
              <div
                className={`ant-col-6 billing-information-field ant-col${
                  this.props.validation.errors['postalCode'] ? ' error' : ''
                }`}
              >
                <label>Postal code</label>
                <input
                  type="text"
                  value={this.props.billingInfo.postalCode}
                  data-recurly="postal_code"
                  data-key="postal_code"
                  onChange={e => {
                    this.props.onBillingInfoChange({
                      ...this.props.billingInfo,
                      postalCode: e.target.value,
                    })
                  }}
                />
                <div className="input-error">
                  <div>
                    <img src={WarningSignIcon} alt="Validation warning icon" />
                  </div>
                  {this.props.validation.errors['postalCode']}
                </div>
              </div>
              {this.renderCountrySelect()}
              <div
                className={'ant-col ant-col-24'}
                style={{
                  display: this.props.additionalFieldsActive ? 'block' : 'none',
                }}
              ></div>
              <div
                className={`ant-col-24 ant-col-sm-12 billing-information-field ant-col`}
                style={{
                  display: this.props.additionalFieldsActive ? 'block' : 'none',
                }}
              >
                <label>Address</label>
                <input
                  type="text"
                  value={this.props.billingInfo.address}
                  data-recurly="address1"
                  data-key="address1"
                  onChange={e => {
                    this.props.onBillingInfoChange({
                      ...this.props.billingInfo,
                      address: e.target.value,
                    })
                  }}
                />
                <div className="input-error">
                  <div>
                    <img src={WarningSignIcon} alt="Validation warning icon" />
                  </div>
                  {this.props.validation.errors['address']}
                </div>
              </div>
              <div
                className={`ant-col-24 ant-col-sm-12 billing-information-field ant-col`}
                style={{
                  display: this.props.additionalFieldsActive ? 'block' : 'none',
                }}
              >
                <label>City</label>
                <input
                  type="text"
                  value={this.props.billingInfo.city}
                  data-recurly="city"
                  data-key="city"
                  onChange={e => {
                    this.props.onBillingInfoChange({
                      ...this.props.billingInfo,
                      city: e.target.value,
                    })
                  }}
                />
                <div className="input-error">
                  <div>
                    <img src={WarningSignIcon} alt="Validation warning icon" />
                  </div>
                  {this.props.validation.errors['city']}
                </div>
              </div>
              <div
                className={'ant-col ant-col-24'}
                style={{
                  display: this.props.additionalFieldsActive ? 'block' : 'none',
                }}
              ></div>
              <div
                className={`ant-col-24 ant-col-sm-12 billing-information-field ant-col`}
                style={{
                  display: this.props.additionalFieldsActive ? 'block' : 'none',
                }}
              >
                <label>VAT number</label>
                <input
                  type="text"
                  value={this.props.billingInfo.vatNumber}
                  data-recurly="vat_number"
                  data-key="vat_number"
                  onChange={e => {
                    this.props.onBillingInfoChange({
                      ...this.props.billingInfo,
                      vatNumber: e.target.value,
                    })
                  }}
                />
              </div>
              <div
                className={`ant-col-24 ant-col-sm-12 billing-information-field ant-col`}
                style={{
                  display: this.props.additionalFieldsActive ? 'block' : 'none',
                }}
              >
                <label>Company</label>
                <input
                  type="text"
                  value={this.props.billingInfo.company}
                  data-recurly="company"
                  data-key="company"
                  onChange={e => {
                    this.props.onBillingInfoChange({
                      ...this.props.billingInfo,
                      company: e.target.value,
                    })
                  }}
                />
              </div>
              <div
                className={'ant-col ant-col-24'}
                style={{
                  display: this.props.additionalFieldsActive ? 'block' : 'none',
                }}
              ></div>
              <div
                className="ant-col ant-col-24 billing-description vat-number"
                onClick={() =>
                  this.props.onAdditionalFieldsChange(
                    !this.props.additionalFieldsActive
                  )
                }
              >
                {this.props.additionalFieldsActive ? 'Hide' : 'Show'} additional
                fields
              </div>
              <CheckoutTermsOfService />
              <div className="ant-col ant-col-24">
                <button
                  type="submit"
                  className="checkout-button billing-checkout-button"
                  onClick={e => {
                    e.preventDefault()
                    this.props.onConfirm()
                  }}
                  id="checkout-confirm-payment"
                  disabled={this.props.loadingPaymentConfirm}
                >
                  Continue
                  <Spin
                    size="default"
                    style={{
                      display: this.props.loadingPaymentConfirm
                        ? 'inline-block'
                        : 'none',
                    }}
                  />
                </button>
              </div>
              <CheckoutBillingSecurity
                subscription={this.props.subscription}
                loadingSubscription={this.props.loadingSubscription}
                refundPeriodExpired={this.props.refundPeriodExpired}
                onFirstSubscription={this.props.onFirstSubscription}
              />
            </form>
          </div>
        </Elements>
      </RecurlyProvider>
    )
  }

  private renderCountrySelect() {
    return (
      <div
        className={`ant-col-24 ant-col-sm-12 billing-information-field ant-col`}
      >
        <label>Country</label>
        <select
          name="country"
          id="billingCountry"
          onChange={e => {
            this.props.onBillingInfoChange({
              ...this.props.billingInfo,
              country: e.target.value,
            })
          }}
          style={{
            paddingTop: '8px',
          }}
          value={this.props.billingInfo.country || ''}
          data-recurly="country"
        >
          <option disabled value="">
            Country
          </option>
          <option value="AF" key="AF">
            Afghanistan
          </option>
          <option value="AX" key="AX">
            Åland Islands
          </option>
          <option value="AL" key="AL">
            Albania
          </option>
          <option value="DZ" key="DZ">
            Algeria
          </option>
          <option value="AS" key="AS">
            American Samoa
          </option>
          <option value="AD" key="AD">
            Andorra
          </option>
          <option value="AO" key="AO">
            Angola
          </option>
          <option value="AI" key="AI">
            Anguilla
          </option>
          <option value="AQ" key="AQ">
            Antarctica
          </option>
          <option value="AG" key="AG">
            Antigua and Barbuda
          </option>
          <option value="AR" key="AR">
            Argentina
          </option>
          <option value="AM" key="AM">
            Armenia
          </option>
          <option value="AW" key="AW">
            Aruba
          </option>
          <option value="AU" key="AU">
            Australia
          </option>
          <option value="AT" key="AT">
            Austria
          </option>
          <option value="AZ" key="AZ">
            Azerbaijan
          </option>
          <option value="BS" key="BS">
            Bahamas
          </option>
          <option value="BH" key="BH">
            Bahrain
          </option>
          <option value="BD" key="BD">
            Bangladesh
          </option>
          <option value="BB" key="BB">
            Barbados
          </option>
          <option value="BY" key="BY">
            Belarus
          </option>
          <option value="BE" key="BE">
            Belgium
          </option>
          <option value="BZ" key="BZ">
            Belize
          </option>
          <option value="BJ" key="BJ">
            Benin
          </option>
          <option value="BM" key="BM">
            Bermuda
          </option>
          <option value="BT" key="BT">
            Bhutan
          </option>
          <option value="BO" key="BO">
            Bolivia, Plurinational State of
          </option>
          <option value="BQ" key="BQ">
            Bonaire, Sint Eustatius and Saba
          </option>
          <option value="BA" key="BA">
            Bosnia and Herzegovina
          </option>
          <option value="BW" key="BW">
            Botswana
          </option>
          <option value="BV" key="BV">
            Bouvet Island
          </option>
          <option value="BR" key="BR">
            Brazil
          </option>
          <option value="IO" key="IO">
            British Indian Ocean Territory
          </option>
          <option value="BN" key="BN">
            Brunei Darussalam
          </option>
          <option value="BG" key="BG">
            Bulgaria
          </option>
          <option value="BF" key="BF">
            Burkina Faso
          </option>
          <option value="BI" key="BI">
            Burundi
          </option>
          <option value="KH" key="KH">
            Cambodia
          </option>
          <option value="CM" key="CM">
            Cameroon
          </option>
          <option value="CA" key="CA">
            Canada
          </option>
          <option value="CV" key="CV">
            Cape Verde
          </option>
          <option value="KY" key="KY">
            Cayman Islands
          </option>
          <option value="CF" key="CF">
            Central African Republic
          </option>
          <option value="TD" key="TD">
            Chad
          </option>
          <option value="CL" key="CL">
            Chile
          </option>
          <option value="CN" key="CN">
            China
          </option>
          <option value="CX" key="CX">
            Christmas Island
          </option>
          <option value="CC" key="CC">
            Cocos (Keeling) Islands
          </option>
          <option value="CO" key="CO">
            Colombia
          </option>
          <option value="KM" key="KM">
            Comoros
          </option>
          <option value="CG" key="CG">
            Congo
          </option>
          <option value="CD" key="CD">
            Congo, the Democratic Republic of the
          </option>
          <option value="CK" key="CK">
            Cook Islands
          </option>
          <option value="CR" key="CR">
            Costa Rica
          </option>
          <option value="CI" key="CI">
            Côte d'Ivoire
          </option>
          <option value="HR" key="HR">
            Croatia
          </option>
          <option value="CU" key="CU">
            Cuba
          </option>
          <option value="CW" key="CW">
            Curaçao
          </option>
          <option value="CY" key="CY">
            Cyprus
          </option>
          <option value="CZ" key="CZ">
            Czech Republic
          </option>
          <option value="DK" key="DK">
            Denmark
          </option>
          <option value="DJ" key="DJ">
            Djibouti
          </option>
          <option value="DM" key="DM">
            Dominica
          </option>
          <option value="DO" key="DO">
            Dominican Republic
          </option>
          <option value="EC" key="EC">
            Ecuador
          </option>
          <option value="EG" key="EG">
            Egypt
          </option>
          <option value="SV" key="SV">
            El Salvador
          </option>
          <option value="GQ" key="GQ">
            Equatorial Guinea
          </option>
          <option value="ER" key="ER">
            Eritrea
          </option>
          <option value="EE" key="EE">
            Estonia
          </option>
          <option value="ET" key="ET">
            Ethiopia
          </option>
          <option value="FK" key="FK">
            Falkland Islands (Malvinas)
          </option>
          <option value="FO" key="FO">
            Faroe Islands
          </option>
          <option value="FJ" key="FJ">
            Fiji
          </option>
          <option value="FI" key="FI">
            Finland
          </option>
          <option value="FR" key="FR">
            France
          </option>
          <option value="GF" key="GF">
            French Guiana
          </option>
          <option value="PF" key="PF">
            French Polynesia
          </option>
          <option value="TF" key="TF">
            French Southern Territories
          </option>
          <option value="GA" key="GA">
            Gabon
          </option>
          <option value="GM" key="GM">
            Gambia
          </option>
          <option value="GE" key="GE">
            Georgia
          </option>
          <option value="DE" key="DE">
            Germany
          </option>
          <option value="GH" key="GH">
            Ghana
          </option>
          <option value="GI" key="GI">
            Gibraltar
          </option>
          <option value="GR" key="GR">
            Greece
          </option>
          <option value="GL" key="GL">
            Greenland
          </option>
          <option value="GD" key="GD">
            Grenada
          </option>
          <option value="GP" key="GP">
            Guadeloupe
          </option>
          <option value="GU" key="GU">
            Guam
          </option>
          <option value="GT" key="GT">
            Guatemala
          </option>
          <option value="GG" key="GG">
            Guernsey
          </option>
          <option value="GN" key="GN">
            Guinea
          </option>
          <option value="GW" key="GW">
            Guinea-Bissau
          </option>
          <option value="GY" key="GY">
            Guyana
          </option>
          <option value="HT" key="HT">
            Haiti
          </option>
          <option value="HM" key="HM">
            Heard Island and McDonald Islands
          </option>
          <option value="VA" key="VA">
            Holy See (Vatican City State)
          </option>
          <option value="HN" key="HN">
            Honduras
          </option>
          <option value="HK" key="HK">
            Hong Kong
          </option>
          <option value="HU" key="HU">
            Hungary
          </option>
          <option value="IS" key="IS">
            Iceland
          </option>
          <option value="IN" key="IN">
            India
          </option>
          <option value="ID" key="ID">
            Indonesia
          </option>
          <option value="IR" key="IR">
            Iran, Islamic Republic of
          </option>
          <option value="IQ" key="IQ">
            Iraq
          </option>
          <option value="IE" key="IE">
            Ireland
          </option>
          <option value="IM" key="IM">
            Isle of Man
          </option>
          <option value="IL" key="IL">
            Israel
          </option>
          <option value="IT" key="IT">
            Italy
          </option>
          <option value="JM" key="JM">
            Jamaica
          </option>
          <option value="JP" key="JP">
            Japan
          </option>
          <option value="JE" key="JE">
            Jersey
          </option>
          <option value="JO" key="JO">
            Jordan
          </option>
          <option value="KZ" key="KZ">
            Kazakhstan
          </option>
          <option value="KE" key="KE">
            Kenya
          </option>
          <option value="KI" key="KI">
            Kiribati
          </option>
          <option value="KP" key="KP">
            Korea, Democratic People's Republic of
          </option>
          <option value="KR" key="KR">
            Korea, Republic of
          </option>
          <option value="KW" key="KW">
            Kuwait
          </option>
          <option value="KG" key="KG">
            Kyrgyzstan
          </option>
          <option value="LA" key="LA">
            Lao People's Democratic Republic
          </option>
          <option value="LV" key="LV">
            Latvia
          </option>
          <option value="LB" key="LB">
            Lebanon
          </option>
          <option value="LS" key="LS">
            Lesotho
          </option>
          <option value="LR" key="LR">
            Liberia
          </option>
          <option value="LY" key="LY">
            Libya
          </option>
          <option value="LI" key="LI">
            Liechtenstein
          </option>
          <option value="LT" key="LT">
            Lithuania
          </option>
          <option value="LU" key="LU">
            Luxembourg
          </option>
          <option value="MO" key="MO">
            Macao
          </option>
          <option value="MK" key="MK">
            Macedonia, the former Yugoslav Republic of
          </option>
          <option value="MG" key="MG">
            Madagascar
          </option>
          <option value="MW" key="MW">
            Malawi
          </option>
          <option value="MY" key="MY">
            Malaysia
          </option>
          <option value="MV" key="MV">
            Maldives
          </option>
          <option value="ML" key="ML">
            Mali
          </option>
          <option value="MT" key="MT">
            Malta
          </option>
          <option value="MH" key="MH">
            Marshall Islands
          </option>
          <option value="MQ" key="MQ">
            Martinique
          </option>
          <option value="MR" key="MR">
            Mauritania
          </option>
          <option value="MU" key="MU">
            Mauritius
          </option>
          <option value="YT" key="YT">
            Mayotte
          </option>
          <option value="MX" key="MX">
            Mexico
          </option>
          <option value="FM" key="FM">
            Micronesia, Federated States of
          </option>
          <option value="MD" key="MD">
            Moldova, Republic of
          </option>
          <option value="MC" key="MC">
            Monaco
          </option>
          <option value="MN" key="MN">
            Mongolia
          </option>
          <option value="ME" key="ME">
            Montenegro
          </option>
          <option value="MS" key="MS">
            Montserrat
          </option>
          <option value="MA" key="MA">
            Morocco
          </option>
          <option value="MZ" key="MZ">
            Mozambique
          </option>
          <option value="MM" key="MM">
            Myanmar
          </option>
          <option value="NA" key="NA">
            Namibia
          </option>
          <option value="NR" key="NR">
            Nauru
          </option>
          <option value="NP" key="NP">
            Nepal
          </option>
          <option value="NL" key="NL">
            Netherlands
          </option>
          <option value="NC" key="NC">
            New Caledonia
          </option>
          <option value="NZ" key="NZ">
            New Zealand
          </option>
          <option value="NI" key="NI">
            Nicaragua
          </option>
          <option value="NE" key="NE">
            Niger
          </option>
          <option value="NG" key="NG">
            Nigeria
          </option>
          <option value="NU" key="NU">
            Niue
          </option>
          <option value="NF" key="NF">
            Norfolk Island
          </option>
          <option value="MP" key="MP">
            Northern Mariana Islands
          </option>
          <option value="NO" key="NO">
            Norway
          </option>
          <option value="OM" key="OM">
            Oman
          </option>
          <option value="PK" key="PK">
            Pakistan
          </option>
          <option value="PW" key="PW">
            Palau
          </option>
          <option value="PS" key="PS">
            Palestinian Territory, Occupied
          </option>
          <option value="PA" key="PA">
            Panama
          </option>
          <option value="PG" key="PG">
            Papua New Guinea
          </option>
          <option value="PY" key="PY">
            Paraguay
          </option>
          <option value="PE" key="PE">
            Peru
          </option>
          <option value="PH" key="PH">
            Philippines
          </option>
          <option value="PN" key="PN">
            Pitcairn
          </option>
          <option value="PL" key="PL">
            Poland
          </option>
          <option value="PT" key="PT">
            Portugal
          </option>
          <option value="PR" key="PR">
            Puerto Rico
          </option>
          <option value="QA" key="QA">
            Qatar
          </option>
          <option value="RE" key="RE">
            Réunion
          </option>
          <option value="RO" key="RO">
            Romania
          </option>
          <option value="RU" key="RU">
            Russian Federation
          </option>
          <option value="RW" key="RW">
            Rwanda
          </option>
          <option value="BL" key="BL">
            Saint Barthélemy
          </option>
          <option value="SH" key="SH">
            Saint Helena, Ascension and Tristan da Cunha
          </option>
          <option value="KN" key="KN">
            Saint Kitts and Nevis
          </option>
          <option value="LC" key="LC">
            Saint Lucia
          </option>
          <option value="MF" key="MF">
            Saint Martin (French part)
          </option>
          <option value="PM" key="PM">
            Saint Pierre and Miquelon
          </option>
          <option value="VC" key="VC">
            Saint Vincent and the Grenadines
          </option>
          <option value="WS" key="WS">
            Samoa
          </option>
          <option value="SM" key="SM">
            San Marino
          </option>
          <option value="ST" key="ST">
            Sao Tome and Principe
          </option>
          <option value="SA" key="SA">
            Saudi Arabia
          </option>
          <option value="SN" key="SN">
            Senegal
          </option>
          <option value="RS" key="RS">
            Serbia
          </option>
          <option value="SC" key="SC">
            Seychelles
          </option>
          <option value="SL" key="SL">
            Sierra Leone
          </option>
          <option value="SG" key="SG">
            Singapore
          </option>
          <option value="SX" key="SX">
            Sint Maarten (Dutch part)
          </option>
          <option value="SK" key="SK">
            Slovakia
          </option>
          <option value="SI" key="SI">
            Slovenia
          </option>
          <option value="SB" key="SB">
            Solomon Islands
          </option>
          <option value="SO" key="SO">
            Somalia
          </option>
          <option value="ZA" key="ZA">
            South Africa
          </option>
          <option value="GS" key="GS">
            South Georgia and the South Sandwich Islands
          </option>
          <option value="SS" key="SS">
            South Sudan
          </option>
          <option value="ES" key="ES">
            Spain
          </option>
          <option value="LK" key="LK">
            Sri Lanka
          </option>
          <option value="SD" key="SD">
            Sudan
          </option>
          <option value="SR" key="SR">
            Suriname
          </option>
          <option value="SJ" key="SJ">
            Svalbard and Jan Mayen
          </option>
          <option value="SZ" key="SZ">
            Swaziland
          </option>
          <option value="SE" key="SE">
            Sweden
          </option>
          <option value="CH" key="CH">
            Switzerland
          </option>
          <option value="SY" key="SY">
            Syrian Arab Republic
          </option>
          <option value="TW" key="TW">
            Taiwan, Province of China
          </option>
          <option value="TJ" key="TJ">
            Tajikistan
          </option>
          <option value="TZ" key="TZ">
            Tanzania, United Republic of
          </option>
          <option value="TH" key="TH">
            Thailand
          </option>
          <option value="TL" key="TL">
            Timor-Leste
          </option>
          <option value="TG" key="TG">
            Togo
          </option>
          <option value="TK" key="TK">
            Tokelau
          </option>
          <option value="TO" key="TO">
            Tonga
          </option>
          <option value="TT" key="TT">
            Trinidad and Tobago
          </option>
          <option value="TN" key="TN">
            Tunisia
          </option>
          <option value="TR" key="TR">
            Turkey
          </option>
          <option value="TM" key="TM">
            Turkmenistan
          </option>
          <option value="TC" key="TC">
            Turks and Caicos Islands
          </option>
          <option value="TV" key="TV">
            Tuvalu
          </option>
          <option value="UG" key="UG">
            Uganda
          </option>
          <option value="UA" key="UA">
            Ukraine
          </option>
          <option value="AE" key="AE">
            United Arab Emirates
          </option>
          <option value="GB" key="GB">
            United Kingdom
          </option>
          <option value="US" key="US">
            United States
          </option>
          <option value="UM" key="UM">
            United States Minor Outlying Islands
          </option>
          <option value="UY" key="UY">
            Uruguay
          </option>
          <option value="UZ" key="UZ">
            Uzbekistan
          </option>
          <option value="VU" key="VU">
            Vanuatu
          </option>
          <option value="VE" key="VE">
            Venezuela, Bolivarian Republic of
          </option>
          <option value="VN" key="VN">
            Viet Nam
          </option>
          <option value="VG" key="VG">
            Virgin Islands, British
          </option>
          <option value="VI" key="VI">
            Virgin Islands, U.S.
          </option>
          <option value="WF" key="WF">
            Wallis and Futuna
          </option>
          <option value="EH" key="EH">
            Western Sahara
          </option>
          <option value="YE" key="YE">
            Yemen
          </option>
          <option value="ZM" key="ZM">
            Zambia
          </option>
          <option value="ZW" key="ZW">
            Zimbabwe
          </option>
        </select>
        <div className="input-error">
          <div>
            <img src={WarningSignIcon} alt="Validation warning icon" />
          </div>
          {this.props.validation.errors['country']}
        </div>
      </div>
    )
  }
}
