import React from 'react'
import { AccountPublicKey } from '../components/AccountPublicKey'
import { ApiClient } from '../api-client/interface/ApiClient'

type Props = {
  apiClient: ApiClient
}

export class AccountPublicKeyContainer extends React.Component<Props> {
  render() {
    return (
      <div className="card-wrap">
        <h1>SSH Public Key</h1>
        <AccountPublicKey onShowPublicKey={this.handleShowPublicKey} />
      </div>
    )
  }

  private handleShowPublicKey = async () => {
    return await this.props.apiClient.getAccountSSHPublicKey()
  }
}
