import React from 'react'
import { message, Modal, notification } from 'antd'
import { Validate, Validation } from '../helpers/validation/Validation'

export type OnDeleteAccountParams = {
  confirmPassword: string
}

type Props = {
  onDeleteAccount: (params: OnDeleteAccountParams) => Promise<void>
}

type State = {
  dialogVisible: boolean
  confirmPassword: string
  deletingAccountInProgress: boolean
  validation: Validation
}

export class DeleteAccount extends React.PureComponent<Props, State> {
  public state: State = {
    dialogVisible: false,
    confirmPassword: '',
    deletingAccountInProgress: false,
    validation: {
      valid: true,
      errors: {},
    },
  }
  public render() {
    return (
      <div className="card-wrap">
        <h1 id="delete_account">Delete your account</h1>
        <div className="card delete">
          <div className="card__section col padding-adjustment">
            <span>This action is permanent and cannot be undone!</span>
            <p>Are you sure?</p>
          </div>
          <div className="card__section">
            <div className="card-footer">
              <button
                className="btn btn--red dialog-remove"
                onClick={this.handleDeleteButtonClick}
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <Modal
          title="Delete account"
          visible={this.state.dialogVisible}
          onOk={this.handleDeleteAccountButtonClick}
          confirmLoading={this.state.deletingAccountInProgress}
          onCancel={this.handleCancelButtonClick}
          okText="Yes, Delete"
          cancelText="Cancel"
        >
          <div
            className="dialog__text dialog__text--type-delete-account"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.handleDeleteAccountButtonClick()
              }
            }}
          >
            You are about to <b>permanently</b> delete your account. This action
            cannot be undone!
          </div>
          <form name="removeAccount">
            <div className="dialog__text">
              <div className="input-wrap add-account">
                <div className="mui-input">
                  <div className="group">
                    <input
                      type="password"
                      id="accountPassword"
                      required
                      value={this.state.confirmPassword}
                      onChange={this.handleConfirmPasswordChange}
                    />
                    <span className="bar"></span>
                    <label>Please enter your current password to confirm</label>
                  </div>
                  <span className="error">
                    {this.state.validation.errors['confirmPassword'] || ''}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    )
  }

  private handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      confirmPassword: event.target.value,
    })
  }

  private handleDeleteButtonClick = () => {
    this.setState({
      dialogVisible: true,
    })
  }

  private handleCancelButtonClick = () => {
    this.setState({
      dialogVisible: false,
    })
  }

  private handleDeleteAccountButtonClick = async () => {
    const validation = Validate({
      confirmPassword: this.state.confirmPassword,
    })

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill in your password')
      return
    }

    this.setState({
      deletingAccountInProgress: true,
    })

    try {
      await this.props.onDeleteAccount({
        confirmPassword: this.state.confirmPassword,
      })
    } catch (ex) {
      notification.error({
        message: 'Error processing request',
        description: String(ex),
      })
    } finally {
      this.setState({
        deletingAccountInProgress: false,
      })
    }
  }
}
