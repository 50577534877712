import React from 'react'
import { Button, message, Modal, notification } from 'antd'
import { parse as parseQs } from 'querystring'
import {
  ContainerTableData,
  EditContainerProps,
} from '../components/Containers'
import { CreateContainerModel } from '../components/CreateContainerModal'
import { tableDataTransform } from '../helpers/container-stacks/TableDataTransform'
import { ApiClient, ApiClientEvent } from '../api-client/interface/ApiClient'
import {
  ContainerStackList,
  ContainerDomain,
  CreateContainer,
  CreateContainerDomain,
} from '../api-client/interface/Connection'
import { Validate, Validation } from '../helpers/validation/Validation'
import { NoContentBox } from '../components/NoContentBox'
import { ContainerCards } from '../components/ContainerCards'
import { NO_CONTENT } from '../helpers/image-imports/NoContent'
import { UpgradePlanDialog } from '../components/UpgradePlanDialog'

type Props = {
  apiClient: ApiClient
  onCreateContainer: () => void
  onSelectedStackChange: () => void
}

type State = {
  loadingContainers: boolean
  containers: ContainerTableData[]
  createDialogVisible: boolean
  stacks: ContainerStackList
  newContainerName: string
  selectedStack: string
  newContainerAlwaysOn: boolean
  loadingStacks: boolean
  domains: ContainerDomain[]
  validation: Validation
  containerLimitDialogVisible: boolean
  accountNotVerifiedDialogVisible: boolean
  alwaysOnLimitDialogVisible: boolean
  currPage: number
  pageSize: number
}

export class ContainersContainer extends React.Component<Props, State> {
  public state: State = {
    loadingContainers: false,
    containers: [],
    createDialogVisible: false,
    stacks: {
      predefined: [],
      custom: [],
      team: [],
    },
    newContainerName: '',
    selectedStack: '',
    newContainerAlwaysOn: false,
    loadingStacks: false,
    domains: [],
    validation: {
      valid: true,
      errors: {},
    },
    containerLimitDialogVisible: false,
    accountNotVerifiedDialogVisible: false,
    currPage: 1,
    alwaysOnLimitDialogVisible: false,
    pageSize: 10,
  }

  private refreshInterval?: number

  componentDidMount() {
    this.getContainerList()
    this.getDomainList()
    this.getCustomStacks()

    this.refreshInterval = window.setInterval(() => {
      if (this.state.containers.length) {
        this.getContainerList()
      }
    }, 1000 * 60)

    this.props.apiClient.on(ApiClientEvent.ContainerStateChanged, event => {
      this.updateContainerState(event.containerId, event.state)

      if (event.state === 103 && document.hasFocus()) {
        this.redirectToContainer(event.containerId)
      }
    })

    const createContainer = parseQs(window.location.search.slice(1))['create']
    if (createContainer === 'true') {
      this.setState({
        createDialogVisible: true,
      })
    }

    // Use browser online event instead of constant polling API
    window.addEventListener('online', this.windowOnlineHandler)
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
    window.removeEventListener('online', this.windowOnlineHandler)
  }

  render() {
    return (
      <>
        <NoContentBox
          // TODO: Image
          imageUrl={NO_CONTENT['containers']}
          title="Looks like you don't have any Containers."
          description="It takes just a few seconds to create one and start coding!"
          buttonText="Create container"
          buttonAction={() => {
            this.getCustomStacks()
            this.setState({
              createDialogVisible: true,
            })
          }}
          visible={
            this.state.containers.length === 0 && !this.state.loadingContainers
          }
          buttonId="create-container--button"
        />
        <div
          style={{
            display: this.state.containers.length ? 'block' : 'none',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => {
                this.getCustomStacks()
                this.setState({
                  createDialogVisible: true,
                })
              }}
              style={{
                marginBottom: '20px',
              }}
              type="primary"
            >
              New container
            </Button>
            <Button
              onClick={this.refreshContainerList}
              style={{
                marginBottom: '20px',
              }}
              loading={this.state.loadingContainers}
            >
              Refresh
            </Button>
          </div>
          <ContainerCards
            loadingContainers={this.state.loadingContainers}
            containers={this.state.containers}
            domains={this.state.domains}
            currPage={this.state.currPage}
            pageSize={this.state.pageSize}
            onEditContainer={this.handleEditContainer}
            onDestroyContainer={this.handleDestroyContainer}
            onRestartContainer={this.handleRestartContainer}
            onStartContainer={this.handleStartContainer}
            onStopContainer={this.handleStopContainer}
            onReloadContainers={this.getContainerList}
            onContainerInfo={this.handleContainerInfo}
            onSetAlwaysOn={this.handleSetAlwaysOn}
            onAddDomain={this.handleAddDomain}
            onRemoveDomain={this.handleRemoveDomain}
            setContainerLoadingState={this.setContainerLoadingState}
            onCurrPageChange={currPage => {
              this.setState({
                currPage,
              })
            }}
            onPageSizeChange={pageSize => {
              this.setState({
                pageSize,
              })
            }}
            refreshDomains={() => this.getDomainList()}
          />
          <CreateContainerModel
            visible={this.state.createDialogVisible}
            newContainerName={this.state.newContainerName}
            stacks={tableDataTransform(this.state.stacks, true)}
            alwaysOn={this.state.newContainerAlwaysOn}
            loadingStacks={this.state.loadingStacks}
            selectedStack={
              this.state.stacks.predefined.length && !this.state.selectedStack
                ? 'Stack:' +
                  this.state.stacks.predefined[0].id +
                  'Distro:' +
                  this.state.stacks.predefined[0].distros[0].id
                : this.state.selectedStack
            }
            validation={this.state.validation}
            onChangeNewContainerName={this.handleNewContainerNameChange}
            onCancelCreate={() => {
              this.setState({
                createDialogVisible: false,
              })
            }}
            onSelectedStackChange={selectedStack => {
              this.props.onSelectedStackChange()
              this.setState({ selectedStack })
            }}
            onConfirmCreate={this.handleConfirmCreate}
            onAlwaysOnChange={alwaysOn => {
              this.setState({
                newContainerAlwaysOn: alwaysOn,
              })
            }}
            onModalClose={
              this.state.accountNotVerifiedDialogVisible
                ? () => {}
                : this.props.onCreateContainer
            }
          />
          <UpgradePlanDialog
            title="Container limit reached!"
            visible={this.state.containerLimitDialogVisible}
            description={`You have reached your maximum number of active containers. Please upgrade your plan if you want to create more.`}
            onCancel={() => {
              this.setState({
                containerLimitDialogVisible: false,
              })
            }}
          />
          <UpgradePlanDialog
            title="Always-On limit reached!"
            visible={this.state.alwaysOnLimitDialogVisible}
            description={`You have reached the maximum number of Always-On Options. Please upgrade your plan.`}
            onCancel={() => {
              this.setState({
                alwaysOnLimitDialogVisible: false,
              })
            }}
          />
          <Modal
            title="Account not verified!"
            visible={this.state.accountNotVerifiedDialogVisible}
            onOk={() => {
              this.setState({
                accountNotVerifiedDialogVisible: false,
              })
            }}
            onCancel={this.handleResendVerification}
            cancelText="Resend"
          >
            <div>
              You can't use the Container feature until you verify your account.
              <br />
              Please check your email or we can resend the link.
            </div>
          </Modal>
        </div>
      </>
    )
  }

  private windowOnlineHandler = () => {
    this.getContainerList()
  }

  private refreshContainerList = async () => {
    this.setState({
      loadingContainers: true,
    })

    await this.getContainerList()

    this.setState({
      loadingContainers: false,
    })
  }

  private getContainerList = async (changePageIfNecessary?: boolean) => {
    try {
      const containers = await this.props.apiClient.getContainerList()

      this.setState({
        containers: containers.map(container => {
          return {
            ...container,
            loading: this.mapStateToLoadingStatus(container.state),
            key: container.id.toString(),
          }
        }),
        currPage: changePageIfNecessary
          ? parseInt(
              Math.ceil(containers.length / this.state.pageSize).toString()
            )
          : this.state.currPage,
      })

      if (!containers.length && !this.state.createDialogVisible) {
        this.setState({
          newContainerName: 'My first container',
        })
      }
    } catch (ex) {
      notification.error({
        message: 'Error getting container list',
        description: String(ex),
      })
    }
  }

  private handleSetAlwaysOn = async (
    containerId: number,
    alwaysOn: boolean
  ) => {
    try {
      await this.props.apiClient.setContainerAlwaysOn(containerId, alwaysOn)
      this.getContainerList()
    } catch (e) {
      if (e.includes('reached the maximum')) {
        this.setState({
          alwaysOnLimitDialogVisible: true,
        })
      }
    }
  }

  private handleContainerInfo = (containerId: number) => {
    return this.props.apiClient.getContainerInfo(containerId)
  }

  private mapStateToLoadingStatus(state: number) {
    return state !== 10 && state !== 20 && state !== 80
  }

  private async updateContainerState(containerId: number, state: number) {
    if (state) {
      this.setState({
        containers: this.state.containers.map(container => {
          if (container.id !== containerId) {
            return container
          }
          return {
            ...container,
            loading: this.mapStateToLoadingStatus(state),
            state,
          }
        }),
      })
      //pending creation or destroyed
      if (state === 101 || state === 90) {
        await this.getContainerList(true)
      }
    } else {
      await this.getContainerList()
    }
  }

  private handleDestroyContainer = async (containerId: number) => {
    await this.props.apiClient.removeConnection(containerId)
  }

  private handleEditContainer = async (props: EditContainerProps) => {
    if (!props.name || props.name === '') {
      throw new Error('Name cannot be empty')
    }

    await this.props.apiClient.renameConnection(props.id, props.name)
  }

  private handleRestartContainer = async (containerId: number) => {
    await this.props.apiClient.restartContainer(containerId)
  }

  private handleStartContainer = async (containerId: number) => {
    await this.props.apiClient.startContainer(containerId)
  }

  private handleStopContainer = async (containerId: number) => {
    await this.props.apiClient.stopContainer(containerId)
  }

  private async getCustomStacks() {
    this.setState({
      loadingStacks: true,
    })

    try {
      this.setState({
        stacks: await this.props.apiClient.getContainerStacks(),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while fetching stacks.',
      })
    } finally {
      this.setState({
        loadingStacks: false,
      })
    }
  }

  private async getDomainList() {
    try {
      this.setState({
        domains: await this.props.apiClient.getDomainList(),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while fetching domains.',
      })
    }
  }

  private handleNewContainerNameChange = (newName: string) => {
    this.setState({
      newContainerName: newName,
    })
  }

  private handleConfirmCreate = async (stackId: string, distroId: string) => {
    const validation = Validate({
      newContainerName: this.state.newContainerName,
    })

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill out the container name.')
      return
    }

    try {
      const container: CreateContainer = {
        type: 'devbox',
        name: this.state.newContainerName,
        alwaysOn: this.state.newContainerAlwaysOn,
        stack: stackId,
        distro: this.state.stacks.predefined.find(cs => cs.id === stackId)
          ? distroId
          : '',
      }

      await this.props.apiClient.createContainer(container)

      this.setState({
        createDialogVisible: false,
        newContainerName: '',
      })
    } catch (e) {
      if (e.notVerified) {
        this.setState({
          createDialogVisible: false,
          accountNotVerifiedDialogVisible: true,
        })
      } else if (e.upgrade) {
        this.setState({
          createDialogVisible: false,
          containerLimitDialogVisible: true,
        })
      } else {
        notification.error({
          message: 'An error occurred while creating your container',
          description: String(e),
        })
      }
    }
  }

  private async redirectToContainer(containerId: number) {
    const containerSlug = await this.props.apiClient.getContainerSlug(
      containerId
    )

    if (!containerSlug) {
      setTimeout(() => this.redirectToContainer(containerId), 1000)
      return
    }

    window.open(
      `${process.env.REACT_APP_IDE_URL}/${containerSlug.toLowerCase()}`
    )
  }

  private handleAddDomain = async (addDomain: CreateContainerDomain) => {
    await this.props.apiClient.addDomain(addDomain)
    this.getContainerList()
  }

  private handleRemoveDomain = async (domainId: string) => {
    await this.props.apiClient.removeDomain(domainId)
    this.getContainerList()
  }

  private setContainerLoadingState = (
    containerId: number,
    loading: boolean
  ) => {
    this.setState({
      containers: this.state.containers.map(container => {
        if (container.id !== containerId) {
          return container
        }
        return {
          ...container,
          loading,
        }
      }),
    })
  }

  private handleResendVerification = async () => {
    try {
      await this.props.apiClient.resendVerificationEmail()
      this.setState({
        accountNotVerifiedDialogVisible: false,
      })
    } catch (e) {
      notification.error({
        message: 'Could not send the email.',
        description: 'Please try again later.',
      })
    }
  }

  private openContainerIDE = async (containerId: number) => {
    try {
      const container = (await this.props.apiClient.getContainerList()).find(
        c => c.id === containerId
      )

      if (!container) {
        return
      }

      window.open(
        String(
          container.slug
            ? `${process.env.REACT_APP_IDE_URL}/${container.slug.toLowerCase()}`
            : process.env.REACT_APP_EDITOR_URL
        ),
        '__blank'
      )
    } catch (e) {
      notification.error({
        message: 'Failed to get container info.',
      })
    }
  }
}
