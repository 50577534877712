import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  useRecurly,
  UseRecurlyInstance,
} from '@recurly/react-recurly'
import React from 'react'

export function RecurlyCardElementsWrapper(props: {
  setRecurly: (recurly: UseRecurlyInstance) => void
}) {
  const { setRecurly } = props

  const recurly = useRecurly()
  setRecurly(recurly)

  return (
    <>
      <div className="ant-col ant-col-xs-24 ant-col-sm-12 billing-information-field ant-col">
        <label>Card number</label>
        <CardNumberElement />
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-6 billing-information-field ant-col">
        <label>Expiration month</label>
        <CardMonthElement />
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-6 billing-information-field ant-col">
        <label>Expiration year</label>
        <CardYearElement />
      </div>
      <div className="ant-col ant-col-24"></div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-6 billing-information-field ant-col">
        <label>CVV</label>
        <CardCvvElement />
      </div>
    </>
  )
}
