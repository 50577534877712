import {
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  Modal,
  notification,
  Pagination,
  Spin,
  Switch,
  Table,
} from 'antd'
import Column from 'antd/lib/table/Column'
import DOMPurify from 'dompurify'
import marked from 'marked'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  ContainerConnection,
  ContainerDomain,
  CONTAINER_CONNECTION_STATE,
  CreateContainerDomain,
} from '../api-client/interface/Connection'
import { containerStateColor } from '../helpers/containers/ContainerStateColor'
import { Validate, Validation } from '../helpers/validation/Validation'
import { ContainerTableData, EditContainerProps } from './Containers'
import more from '../styles/img/more.svg'
import questionIcon from '../styles/img/questionmark.svg'

type Props = {
  loadingContainers: boolean
  containers: ContainerTableData[]
  domains: ContainerDomain[]
  currPage: number
  pageSize: number
  onEditContainer: (props: EditContainerProps) => Promise<void>
  onStartContainer: (containerId: number) => Promise<void>
  onStopContainer: (containerId: number) => Promise<void>
  onRestartContainer: (containerId: number) => Promise<void>
  onDestroyContainer: (containerId: number) => Promise<void>
  onContainerInfo: (containerId: number) => Promise<string>
  onReloadContainers: () => Promise<void>
  onSetAlwaysOn: (containerId: number, alwaysOn: boolean) => Promise<void>
  onAddDomain: (addDomain: CreateContainerDomain) => Promise<void>
  onRemoveDomain: (domainId: string) => Promise<void>
  setContainerLoadingState: (containerId: number, loading: boolean) => void
  onCurrPageChange: (nextPage: number) => void
  onPageSizeChange: (pageSize: number) => void
  refreshDomains: () => Promise<void>
}

type State = {
  editDialogVisible: boolean
  destroyDialogVisible: boolean
  destroyContainer: ContainerTableData | false
  confirmDestroyContainerName: string
  processing: boolean
  editDialogSaving: boolean
  editDialogFields: EditContainerProps
  containerInfo?: string
  infoDialogVisible: boolean
  addDomainContainer: ContainerTableData | false
  addDomainDialogVisible: boolean
  addDomainLoading: boolean
  addDomain: CreateContainerDomain
  removeDomain: {
    id: string
    domain: string
  }
  removeDomainDialogVisible: boolean
  removeDomainLoading: boolean
  validation: Validation
  selectedContainerForDomains?: ContainerTableData
}

export class ContainerCards extends React.Component<Props, State> {
  public state: State = {
    editDialogVisible: false,
    destroyDialogVisible: false,
    destroyContainer: false,
    confirmDestroyContainerName: '',
    processing: false,
    editDialogSaving: false,
    editDialogFields: {
      id: -1,
      name: '',
      stack: '',
      distro: '',
      alwaysOn: false,
    },
    infoDialogVisible: false,
    addDomainContainer: false,
    addDomainDialogVisible: false,
    addDomainLoading: false,
    addDomain: {
      domain: '',
      containerId: '-1',
      internalPort: '3000',
      externalPort: '443',
    },
    removeDomain: {
      id: '',
      domain: '',
    },
    removeDomainDialogVisible: false,
    removeDomainLoading: false,
    validation: {
      valid: true,
      errors: {},
    },
  }

  render() {
    return (
      <>
        <div className="container-cards--wrapper">
          {this.props.containers
            .slice(
              (this.props.currPage - 1) * this.props.pageSize,
              this.props.currPage * this.props.pageSize
            )
            .map(container => {
              return (
                <Card
                  title={container.name}
                  extra={this.renderActions(container)}
                  key={container.id}
                >
                  <div className="container-card--content">
                    <div>
                      <strong>State:</strong>
                      <div
                        style={{
                          color: containerStateColor(container),
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {container.state % 10 !== 0 ? (
                          <Spin
                            size="small"
                            style={{
                              paddingTop: '3px',
                              marginRight: '5px',
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {CONTAINER_CONNECTION_STATE[container.state]}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          data-balloon-pos="up"
                          data-balloon="Regular containers will be stopped when you sign out. Containers with the applied “Always-On” option will keep running even if you’re not working."
                          data-balloon-length="large"
                          style={{
                            display: 'grid',
                            placeItems: 'center',
                            height: '15px',
                            width: '15px',
                            marginRight: '5px',
                          }}
                        >
                          <img
                            src={questionIcon}
                            alt="Question icon"
                            style={{
                              height: '100%',
                              width: '100%',
                            }}
                          ></img>
                        </div>
                        <strong>Always-On:</strong>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Switch
                          disabled={
                            container.state !== 20 && container.state !== 10
                          }
                          checked={container.alwaysOn === 1}
                          onChange={checked => {
                            this.props.onSetAlwaysOn(container.id, checked)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-card--footer">
                    <div>{this.renderStateActionButton(container)}</div>
                    <div
                      className="open-ide--wrapper"
                      {...(container.state !== 10 && container.id > 2427937
                        ? {
                            'data-balloon-pos': 'up',
                            'data-balloon':
                              'You need to start the container to open the IDE.',
                          }
                        : {})}
                      {...(container.id <= 2427937
                        ? {
                            'data-balloon':
                              'This container uses legacy software, and as such does not support the new IDE.\nPlease use the legacy IDE to access it.\nYou can access it via the Open button in the header.',
                            'data-balloon-pos': 'up',
                          }
                        : {})}
                      style={{
                        textAlign:
                          container.id <= 2427937 ? 'initial' : 'center',
                      }}
                    >
                      <Button
                        disabled={
                          container.state !== 10 && container.id > 2427937
                        }
                        id="open-ide--button"
                      >
                        <a
                          href={String(
                            container.slug && container.id > 2427937
                              ? `${
                                  process.env.REACT_APP_IDE_URL
                                }/${container.slug.toLowerCase()}`
                              : process.env.REACT_APP_EDITOR_URL
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {container.id <= 2427937 &&
                          process.env.REACT_APP_API_BASE_URL !== undefined &&
                          !String(process.env.REACT_APP_API_BASE_URL).includes(
                            'web2014'
                          )
                            ? 'Use legacy IDE'
                            : 'Open IDE'}
                        </a>
                      </Button>
                    </div>
                  </div>
                </Card>
              )
            })}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            total={this.props.containers.length}
            showTotal={total => `${total} containers`}
            pageSize={this.props.pageSize}
            showSizeChanger
            onShowSizeChange={(curr, size) => this.props.onPageSizeChange(size)}
            onChange={this.props.onCurrPageChange}
            current={this.props.currPage}
          />
        </div>
        <Modal
          title="Destroy container"
          visible={this.state.destroyDialogVisible}
          onOk={this.handleConfirmDestroyContainerButtonClick}
          onCancel={this.handleCancelDestroyButtonClick}
          okText="Yes, Destroy"
          cancelText="Cancel"
          okButtonProps={{
            disabled: !(
              this.state.destroyContainer &&
              this.state.destroyContainer.name ===
                this.state.confirmDestroyContainerName
            ),
          }}
        >
          <div
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.handleConfirmDestroyContainerButtonClick()
              }
            }}
          >
            You are about to destroy{' '}
            <strong>
              {this.state.destroyContainer
                ? this.state.destroyContainer.name
                : ''}
            </strong>
            . This action is not reversible.
            <div className="input-wrap" style={{ marginTop: '20px' }}>
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={this.state.confirmDestroyContainerName}
                    onChange={this.handleConfirmDestroyContainerName}
                  />
                  <span className="bar"></span>
                  <label>Please enter the container name to confirm.</label>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.editDialogVisible}
          onOk={this.handleConfirmEditContainerButtonClick}
          onCancel={this.handleCancelEditContainerButtonClick}
          confirmLoading={this.state.editDialogSaving}
        >
          <div
            className="input-wrap"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.handleConfirmEditContainerButtonClick()
              }
            }}
          >
            <div className="mui-input">
              <div className="group">
                <input
                  type="text"
                  value={this.state.editDialogFields.name || ''}
                  onChange={e => {
                    this.setState({
                      editDialogFields: {
                        ...this.state.editDialogFields,
                        name: e.target.value,
                      },
                    })
                  }}
                />
                <span className="bar"></span>
                <label>Container name</label>
              </div>
              <span className="error">
                {this.state.validation.errors['newContainerName'] || ''}
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          title="Container info"
          visible={this.state.infoDialogVisible}
          onCancel={this.handleContainerInfoCancelClick}
          cancelText="Close"
          okButtonProps={{
            style: { display: 'none' },
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: this.state.containerInfo || '' }}
          ></div>
        </Modal>
        <Modal
          title={`Add domain for: ${
            this.state.addDomainContainer
              ? this.state.addDomainContainer.name
              : ''
          }`}
          visible={this.state.addDomainDialogVisible}
          onCancel={() => {
            this.setState({
              addDomainDialogVisible: false,
              addDomainContainer: false,
              validation: {
                valid: true,
                errors: {},
              },
              addDomain: {
                domain: '',
                internalPort: '3000',
                externalPort: '443',
                containerId: '-1',
              },
            })
          }}
          onOk={this.handleAddDomain}
          confirmLoading={this.state.addDomainLoading}
          cancelButtonProps={{ disabled: this.state.addDomainLoading }}
          okButtonProps={{ disabled: this.state.addDomainLoading }}
        >
          <div>
            <div
              className="input-wrap"
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.handleAddDomain()
                }
              }}
            >
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    onChange={e => {
                      this.setState({
                        addDomain: {
                          ...this.state.addDomain,
                          domain: e.target.value,
                        },
                      })
                    }}
                    value={this.state.addDomain.domain}
                  />
                  <span className="bar"></span>
                  <label>Domain</label>
                </div>
                <span className="error">
                  {this.state.validation.errors['domain'] || ''}
                </span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                className="input-wrap"
                style={{
                  width: '45%',
                }}
              >
                <div className="mui-input">
                  <div className="group">
                    <input
                      type="number"
                      onChange={e => {
                        this.setState({
                          addDomain: {
                            ...this.state.addDomain,
                            internalPort: e.target.value.toString(),
                          },
                        })
                      }}
                      value={parseInt(this.state.addDomain.internalPort)}
                      max="9999"
                    />
                    <span className="bar"></span>
                    <label>Internal port</label>
                  </div>
                  <span className="error">
                    {this.state.validation.errors['internalPort'] || ''}
                  </span>
                </div>
              </div>
              <div
                className="input-wrap"
                style={{
                  width: '45%',
                }}
              >
                <div className="mui-input">
                  <div className="group">
                    <input
                      type="number"
                      onChange={e => {
                        this.setState({
                          addDomain: {
                            ...this.state.addDomain,
                            externalPort: e.target.value.toString(),
                          },
                        })
                      }}
                      value={parseInt(this.state.addDomain.externalPort)}
                      max="9999"
                    />
                    <span className="bar"></span>
                    <label>External port</label>
                  </div>
                  <span className="error">
                    {this.state.validation.errors['externalPort'] || ''}
                  </span>
                </div>
              </div>
            </div>
            <div>
              If you set the external port to 443, you will get a free SSL
              Certificate
            </div>
          </div>
        </Modal>
        <Modal
          title={`Are you sure you want to delete ${this.state.removeDomain.domain}?`}
          visible={this.state.removeDomainDialogVisible}
          onCancel={() => this.setState({ removeDomainDialogVisible: false })}
          onOk={() => this.handleRemoveDomain()}
          okButtonProps={{
            loading: this.state.removeDomainLoading,
          }}
        />
        <Modal
          visible={this.state.selectedContainerForDomains !== undefined}
          onCancel={() => {
            this.setState({
              selectedContainerForDomains: undefined,
            })
          }}
          cancelText="Close"
          okButtonProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {this.state.selectedContainerForDomains ? (
            <>
              <h3 style={{ margin: 0, padding: 0 }}>
                <strong>Container:</strong>{' '}
                {this.state.selectedContainerForDomains.name}
              </h3>
              <h4 style={{ opacity: 0.8, marginBottom: '20px' }}>
                Not sure how to add your custom domain? Follow{' '}
                <a
                  href="https://docs.codeanywhere.com/dashboard/containers/add-custom-domain"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'rgb(127, 63, 152)' }}
                >
                  these
                </a>{' '}
                easy steps.
              </h4>
            </>
          ) : null}
          <div>
            <h3 style={{ padding: 0 }}>
              <strong>Custom domains:</strong>
            </h3>
          </div>
          {this.state.selectedContainerForDomains ? (
            this.renderContainerDomains(this.state.selectedContainerForDomains)
          ) : (
            <></>
          )}
        </Modal>
      </>
    )
  }

  private handleContainerStateActionButtonClick = async (
    container: ContainerConnection
  ) => {
    try {
      this.props.setContainerLoadingState(container.id, true)

      switch (container.state) {
        case 20:
        case 80:
          await this.props.onStartContainer(container.id)
          break
        case 10:
          await this.props.onStopContainer(container.id)
          break
        default:
          break
      }
    } catch (ex) {
      notification.error({
        message: 'Error perfoming container action',
        description: String(ex),
      })
      this.props.setContainerLoadingState(container.id, false)
    }
  }

  private renderStateActionButton(container: ContainerTableData) {
    const stateAction = this.getContainerStateAction(container)

    if (!stateAction.action) {
      return <></>
    }

    const StateActionButton = (
      <Button
        onClick={() => this.handleContainerStateActionButtonClick(container)}
        loading={container.loading}
        disabled={container.loading}
        type="primary"
      >
        {stateAction.label}
      </Button>
    )
    return <span>{StateActionButton}</span>
  }

  private getContainerStateAction = (
    container: ContainerTableData
  ): { action: string; label: string; loading?: boolean } => {
    switch (container.state) {
      case 10:
        return {
          action: 'stop',
          label: 'Stop',
        }
      case 11:
      case 12:
      case 13:
        return {
          action: 'starting',
          label: 'Starting',
        }
      case 20:
      case 80:
        return {
          action: 'start',
          label: 'Start',
        }
      case 21:
      case 22:
      case 23:
        return {
          action: 'stopping',
          label: 'Stopping',
        }
      default:
        return {
          action: '',
          label: '',
        }
    }
  }

  private renderActions = (container: ContainerTableData) => {
    const moreMenu = (() => {
      switch (container.state) {
        case 0:
        case 90:
        case 91:
        case 92:
        case 93:
        case 94:
        case 30:
        case 31:
        case 32:
        case 33:
        case 40:
        case 41:
        case 42:
        case 43:
        case 70:
        case 71:
        case 72:
        case 73:
        case 81:
        case 82:
        case 83:
          return (
            <>
              <Menu>
                <Menu.Item>
                  <button
                    onClick={e => {
                      e.preventDefault()
                      this.handleContainerInfoButtonClick(container.id)
                    }}
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    Info
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={e => {
                      e.preventDefault()
                      this.handleDestroyContainerButtonClick(container)
                    }}
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    Destroy
                  </button>
                </Menu.Item>
              </Menu>
            </>
          )
        default:
          return (
            <Menu>
              <Menu.Item>
                <button
                  onClick={e => {
                    e.preventDefault()
                    this.handleContainerInfoButtonClick(container.id)
                  }}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  Info
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={e => {
                    e.preventDefault()
                    this.handleEditContainerButtonClick({
                      id: container.id,
                      name: container.name,
                      alwaysOn: container.alwaysOn === 1,
                    })
                  }}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  Edit
                </button>
              </Menu.Item>
              <Menu.Item>
                <div
                  onClick={() => {
                    this.setState({
                      selectedContainerForDomains: container,
                    })
                  }}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  Domains
                </div>
              </Menu.Item>
              <Menu.Item>
                <Link
                  style={{ width: '100%', textAlign: 'left' }}
                  to={`/custom-stacks?fromContainer=${container.id}`}
                >
                  Create custom stack
                </Link>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={e => {
                    e.preventDefault()
                    this.handleRestartContainerButtonClick(container.id)
                  }}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  {container.state === 10 ? 'Restart' : 'Force Restart'}
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={e => {
                    e.preventDefault()
                    this.handleDestroyContainerButtonClick(container)
                  }}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  Destroy
                </button>
              </Menu.Item>
            </Menu>
          )
      }
    })()

    return (
      <div>
        <Dropdown overlay={moreMenu} placement="bottomRight">
          <img
            src={more}
            alt="More icon"
            style={{
              width: '20px',
              cursor: 'pointer',
            }}
          />
        </Dropdown>
      </div>
    )
  }

  private handleContainerInfoCancelClick = () => {
    this.setState({
      containerInfo: undefined,
      infoDialogVisible: false,
    })
  }

  private handleContainerInfoButtonClick = async (containerId: number) => {
    try {
      const info = await this.props.onContainerInfo(containerId)

      const md = marked.parse(info)
      const sanitized = DOMPurify.sanitize(md)

      this.setState({
        containerInfo: sanitized,
        infoDialogVisible: true,
      })
    } catch (e) {
      notification.error({
        message: 'Could not get container info.',
      })
    }
  }

  private handleConfirmEditContainerButtonClick = async () => {
    const validation = Validate({
      newContainerName: this.state.editDialogFields.name,
    })

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill out the container name')
      return
    }

    this.setState({
      editDialogSaving: true,
    })

    try {
      if (!this.state.editDialogVisible) {
        throw new Error('Container not selected!')
      }
      await this.props.onEditContainer({
        ...this.state.editDialogFields,
      })
    } catch (ex) {
      notification.error({
        message: 'Error processing request',
        description: String(ex),
      })
    } finally {
      this.setState({
        editDialogSaving: false,
        editDialogVisible: false,
      })
    }
  }

  private handleEditContainerButtonClick = (props: EditContainerProps) => {
    this.setState({
      editDialogFields: props,
      editDialogVisible: true,
    })
  }

  private handleCancelEditContainerButtonClick = () => {
    this.setState({
      editDialogVisible: false,
    })
  }

  private handleRestartContainerButtonClick = async (containerId: number) => {
    try {
      this.props.setContainerLoadingState(containerId, true)
      await this.props.onRestartContainer(containerId)
    } catch (ex) {
      notification.error({
        message: 'Error perfoming container action',
        description: String(ex),
      })
      this.props.setContainerLoadingState(containerId, false)
    }
  }

  private handleDestroyContainerButtonClick = async (
    container: ContainerTableData
  ) => {
    this.setState({
      destroyContainer: container,
      destroyDialogVisible: true,
    })
  }

  private handleRemoveDomain = async () => {
    this.setState({
      removeDomainLoading: true,
    })

    try {
      await this.props.onRemoveDomain(this.state.removeDomain.id)
      this.setState({
        removeDomainDialogVisible: false,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while removing the domain.',
      })
    } finally {
      this.setState({
        removeDomainLoading: false,
      })
    }
  }

  private handleAddDomain = async () => {
    if (!this.state.addDomainContainer) {
      message.error('Container not selected.')
      return
    }

    const validation = Validate(
      {
        ...this.state.addDomain,
      },
      {
        domain: new RegExp(
          '^(?!://)([a-zA-Z0-9-_]+.)*[a-zA-Z0-9][a-zA-Z0-9-_]+.[a-zA-Z]{2,11}?$'
        ),
        externalPort: new RegExp(
          '80|443|[2-9][0-9][0-9][0-9]|1[0-9][2-9][4-9]'
        ),
        internalPort: new RegExp(
          '80|443|[2-9][0-9][0-9][0-9]|1[0-9][2-9][4-9]'
        ),
      },
      {
        domain: 'Not a valid domain',
        externalPort: 'Must be 80, 443 or between 1024 and 9999',
        internalPort: 'Must be 80, 443 or between 1024 and 9999',
      }
    )

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Not a valid domain entry')
      return
    }

    try {
      this.setState({ addDomainLoading: true })

      await this.props.onAddDomain({
        ...this.state.addDomain,
        containerId: this.state.addDomainContainer.id.toString(),
      })
      this.setState({
        addDomainDialogVisible: false,
      })
    } catch {
      notification.error({
        message: 'An error occurred while adding the domain.',
      })
    } finally {
      this.setState({ addDomainLoading: false })
    }
  }

  private handleConfirmDestroyContainerName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      confirmDestroyContainerName: event.target.value,
    })
  }

  private handleConfirmDestroyContainerButtonClick = async () => {
    if (!this.state.destroyContainer) {
      notification.error({
        message: 'Error perfoming container action',
        description: 'Container not selected',
      })
    } else if (
      this.state.confirmDestroyContainerName !==
      this.state.destroyContainer.name
    ) {
      notification.error({
        message: 'Error perfoming container action',
        description: 'Invalid container name',
      })
    } else {
      this.setState({
        destroyDialogVisible: false,
      })
      try {
        this.props.setContainerLoadingState(
          this.state.destroyContainer.id,
          true
        )
        await this.props.onDestroyContainer(this.state.destroyContainer.id)
      } catch (ex) {
        notification.error({
          message: 'Error perfoming container action',
          description: String(ex),
        })
        this.props.setContainerLoadingState(
          this.state.destroyContainer.id,
          false
        )
      } finally {
        this.setState({
          destroyContainer: false,
          confirmDestroyContainerName: '',
          destroyDialogVisible: false,
        })
      }
    }
  }

  private handleCancelDestroyButtonClick = () => {
    this.setState({
      destroyContainer: false,
      confirmDestroyContainerName: '',
      destroyDialogVisible: false,
    })
  }

  private renderContainerDomains = (container: ContainerTableData) => {
    const domainData = this.props.domains
      .filter(domain => domain.containerId === container.id)
      .map(domain => {
        return {
          ...domain,
          key: domain.id.toString(),
        }
      })

    return (
      <Table
        dataSource={domainData}
        pagination={false}
        footer={() => {
          return (
            <Button
              onClick={() => {
                this.props.refreshDomains()
                this.setState({
                  addDomainContainer: container,
                  addDomainDialogVisible: true,
                })
              }}
            >
              Add domain
            </Button>
          )
        }}
      >
        <Column
          title="Domain"
          render={(domain: ContainerDomain) => (
            <a
              href={`http://${domain.domain}:${domain.externalPort}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {domain.domain}
            </a>
          )}
        />
        <Column title="Internal port" dataIndex="internalPort" />
        <Column title="External port" dataIndex="externalPort" />
        <Column
          title="Created at"
          dataIndex="createdAt"
          render={createdAt => {
            return <span>{moment(createdAt).format('MMM Do YYYY')}</span>
          }}
        />
        <Column
          title=""
          key="Action"
          render={record => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <button
                  onClick={() =>
                    this.setState({
                      removeDomainDialogVisible: true,
                      removeDomain: {
                        id: record.key,
                        domain: record.domain,
                      },
                    })
                  }
                >
                  <svg
                    height="15px"
                    width="15px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21.9 21.9"
                  >
                    <path
                      fill="#7f3f98"
                      d="M14.1 11.3c-.2-.2-.2-.5 0-.7l7.5-7.5c.2-.2.3-.5.3-.7s-.1-.5-.3-.7L20.2.3c-.2-.2-.5-.3-.7-.3-.3 0-.5.1-.7.3l-7.5 7.5c-.2.2-.5.2-.7 0L3.1.3C2.9.1 2.6 0 2.4 0s-.5.1-.7.3L.3 1.7c-.2.2-.3.5-.3.7s.1.5.3.7l7.5 7.5c.2.2.2.5 0 .7L.3 18.8c-.2.2-.3.5-.3.7s.1.5.3.7l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3l7.5-7.5c.2-.2.5-.2.7 0l7.5 7.5c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.2-.2.3-.5.3-.7s-.1-.5-.3-.7l-7.5-7.5z"
                    ></path>
                  </svg>
                </button>
              </div>
            )
          }}
        />
      </Table>
    )
  }
}
