import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  AddonName,
  ADDON_TITLES,
  ChildAccount,
  SubscriptionAddons,
} from '../api-client/interface/Subscription'

export type AddonTableRow = {
  key: number
  name: string
  quantity: number
  assigned: number
}

type Props = {
  apiClient: ApiClient
  assigned: SubscriptionAddons
  multipleAccounts: ChildAccount[]
  addons: SubscriptionAddons
  loading: boolean
  columns: ColumnProps<AddonTableRow>[]
}

export class SubscriptionAddonsContainer extends React.PureComponent<Props> {
  render() {
    return (
      <>
        <div className="card-wrap">
          <h1>Addons</h1>
          <div className="card">
            <div
              className="card__section row"
              style={{
                padding: '0',
              }}
            >
              <div
                style={{
                  width: '100%',
                }}
              >
                <Table
                  pagination={false}
                  dataSource={[
                    {
                      key: 0,
                      name: ADDON_TITLES[AddonName.AdditionalContainer],
                      quantity: this.props.addons.additionalcontainer,
                      assigned: this.props.assigned.additionalcontainer,
                    },
                    {
                      key: 1,
                      name: ADDON_TITLES[AddonName.DevboxAlwaysOn],
                      quantity: this.props.addons.devboxalwayson,
                      assigned: this.props.assigned.devboxalwayson,
                    },
                    {
                      key: 2,
                      name: ADDON_TITLES[AddonName.Extra15Remote],
                      quantity: this.props.addons.extra15remote,
                      assigned: this.props.assigned.extra15remote,
                    },
                    {
                      key: 3,
                      name: ADDON_TITLES[AddonName.Extra15Domains],
                      quantity: this.props.addons.extra15domains,
                      assigned: this.props.assigned.extra15domains,
                    },
                    {
                      key: 4,
                      name: ADDON_TITLES[AddonName.MultipleAccounts],
                      quantity: this.props.addons.multipleaccounts,
                      assigned: this.props.assigned.multipleaccounts,
                    },
                  ]}
                  columns={this.props.columns}
                  loading={this.props.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
