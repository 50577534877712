import { Modal, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import {
  CreateCustomStack,
  CONTAINER_CONNECTION_STATE,
} from '../api-client/interface/Connection'
import { ContainerTableData } from './Containers'
import { Validation } from '../helpers/validation/Validation'

type Props = {
  visible: boolean
  onCancel: () => void
  onConfirmCreate: () => Promise<void>
  newCustomStack: CreateCustomStack
  onCustomStackChange: (newCustomStack: CreateCustomStack) => void
  containerList: ContainerTableData[]
  selectedContainerKey: string
  onSelectedContainerChange: (newKey: string) => void
  validation: Validation
}

export class CreateStackModal extends React.Component<Props> {
  render() {
    return (
      <Modal
        title="Create custom stack"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={this.props.onConfirmCreate}
      >
        <div
          className="input-wrap"
          style={{ marginBottom: '50px' }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              this.props.onConfirmCreate()
            }
          }}
        >
          <div className="mui-input">
            <div className="group">
              <input
                type="text"
                value={this.props.newCustomStack.name}
                required
                onChange={e => {
                  this.props.onCustomStackChange({
                    ...this.props.newCustomStack,
                    name: e.target.value,
                  })
                }}
              />
              <span className="bar"></span>
              <label>Name</label>
            </div>
            <span className="error">
              {this.props.validation.errors['name'] || ''}
            </span>
          </div>
        </div>
        <div className="input-wrap">
          <div className="mui-input">
            <div className="group">
              <textarea
                value={this.props.newCustomStack.description}
                required
                onChange={e => {
                  this.props.onCustomStackChange({
                    ...this.props.newCustomStack,
                    description: e.target.value,
                  })
                }}
              />
              <span className="bar"></span>
              <label>Description</label>
            </div>
            <span className="error">
              {this.props.validation.errors['description'] || ''}
            </span>
          </div>
        </div>
        <div
          style={{
            marginBottom: '10px',
          }}
        >
          Select container
        </div>
        <div
          onKeyDown={e => {
            if (e.key === 'Enter') {
              this.props.onConfirmCreate()
            }
          }}
        >
          <Table
            dataSource={this.props.containerList}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: [this.props.selectedContainerKey],
              onChange: selectedKeys =>
                this.props.onSelectedContainerChange(
                  (selectedKeys as string[])[0]
                ),
              getCheckboxProps: record => {
                return {
                  disabled: record.state !== 20 && record.state !== 10,
                }
              },
            }}
            onRow={record => {
              return {
                onClick: () => {
                  if (record.state === 20 || record.state === 10) {
                    this.props.onSelectedContainerChange(record.key)
                  }
                },
              }
            }}
            pagination={false}
            scroll={{ y: 250 }}
          >
            <Column title="Name" dataIndex="name" />
            <Column
              title="State"
              key="state"
              dataIndex="state"
              render={(state: number) => {
                return <span>{CONTAINER_CONNECTION_STATE[state]}</span>
              }}
            />
          </Table>
        </div>
      </Modal>
    )
  }
}
