import React from 'react'
import { Button, message, notification } from 'antd'

type Props = {
  onShowPublicKey: () => Promise<string>
}

type State = {
  loading: boolean
  visible: boolean
  publicKey: string
}

export class AccountPublicKey extends React.PureComponent<Props, State> {
  public state: State = {
    loading: false,
    visible: false,
    publicKey: '',
  }
  render() {
    return (
      <div className="card">
        <div className="card__section row">
          <div
            style={{
              paddingRight: '30px',
              width: '100%',
            }}
          >
            Use this key to authorize Codeanywhere to access your resources.
          </div>
          <div>
            <Button
              onClick={this.handleShowKeyButtonClick}
              type="primary"
              loading={this.state.loading}
            >
              {this.state.visible ? 'Hide' : 'Show'}
            </Button>
          </div>
        </div>
        {this.state.visible ? (
          <div
            style={{
              overflowWrap: 'anywhere',
              padding: '0 20px 20px 20px',
            }}
          >
            <div
              style={{
                marginBottom: '20px',
              }}
            >
              {this.state.publicKey}
            </div>
            <Button
              onClick={() => {
                if (!navigator || !navigator.clipboard) {
                  message.error('Clipboard not supported for this browser.')
                  return
                }
                navigator.clipboard.writeText(this.state.publicKey)
                message.success('Copied')
              }}
            >
              Copy
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }

  private handleShowKeyButtonClick = async () => {
    if (this.state.publicKey) {
      this.setState({
        visible: !this.state.visible,
      })
      return
    }
    this.setState({
      loading: true,
    })
    try {
      this.setState({
        publicKey: await this.props.onShowPublicKey(),
        visible: true,
      })
    } catch (ex) {
      notification.error({
        message: 'Error getting ssh public key',
        description: String(ex),
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  }
}
