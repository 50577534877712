import { Table, Button, Modal, notification, message } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  AddonName,
  ChildAccount,
  SubscriptionAddons,
} from '../api-client/interface/Subscription'
import { NoContentBox } from '../components/NoContentBox'
import { UpgradePlanDialog } from '../components/UpgradePlanDialog'
import { NO_CONTENT } from '../helpers/image-imports/NoContent'
import { Validate, Validation } from '../helpers/validation/Validation'

type TableRow = ChildAccount & { key: string }

type Props = {
  apiClient: ApiClient
}

type State = {
  hasPurchasedTeamlicenses: boolean
  multipleAccounts: TableRow[]
  addDialogVisible: boolean
  newAccountEmail: string
  validation: Validation
  assigned: {
    domains: number
    accounts: number
    alwaysOn: number
    connections: number
  }
  addons: SubscriptionAddons
  selectedAddon: string
  upgradePlanDialogVisible: boolean
}

export class ManagedAccountsContainer extends React.Component<Props, State> {
  public state: State = {
    hasPurchasedTeamlicenses: false,
    multipleAccounts: [],
    newAccountEmail: '',
    addDialogVisible: false,
    validation: {
      valid: true,
      errors: {},
    },
    assigned: {
      domains: 0,
      accounts: 0,
      alwaysOn: 0,
      connections: 0,
    },
    addons: {
      multipleaccounts: 0,
      devboxalwayson: 0,
      extra15remote: 0,
      extra15domains: 0,
      additionalcontainer: 0,
    },
    selectedAddon: '',
    upgradePlanDialogVisible: false,
  }

  componentDidMount() {
    this.getSubscriptionAddons()
    this.getMultipleAccountList()
  }

  render() {
    return (
      <>
        <div
          className="card-wrap"
          style={{
            display: this.state.multipleAccounts.length > 0 ? 'block' : 'none',
          }}
        >
          <h1>Manage accounts</h1>
          <div className="card">
            <div
              className="card__section row"
              style={{
                flexWrap: 'wrap',
              }}
            >
              <div style={{ width: '100%' }}>
                <Table
                  dataSource={this.state.multipleAccounts}
                  footer={() => {
                    return (
                      <div>
                        <Button
                          onClick={() => {
                            this.setState({
                              addDialogVisible: true,
                            })
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    )
                  }}
                  pagination={false}
                  locale={{ emptyText: 'No accounts' }}
                >
                  <Column
                    dataIndex="pending_confirmation"
                    width="5"
                    render={(pc: number) => {
                      return (
                        <div
                          style={{
                            backgroundColor: pc === 1 ? 'red' : 'green',
                            height: '8px',
                            width: '8px',
                            borderRadius: '100px',
                          }}
                        ></div>
                      )
                    }}
                  />
                  <Column title="Email" dataIndex="email" />
                  <Column
                    align="center"
                    title="Additional containers"
                    render={(account: TableRow) =>
                      this.renderAddonCounter(
                        account,
                        AddonName.AdditionalContainer
                      )
                    }
                  />
                  <Column
                    align="center"
                    title="Always-On"
                    render={(account: TableRow) =>
                      this.renderAddonCounter(account, AddonName.DevboxAlwaysOn)
                    }
                  />
                  <Column
                    align="center"
                    title="Additional connections"
                    render={(account: TableRow) =>
                      this.renderAddonCounter(account, AddonName.Extra15Remote)
                    }
                  />
                  <Column
                    align="center"
                    title="Additional domains"
                    render={(account: TableRow) =>
                      this.renderAddonCounter(account, AddonName.Extra15Domains)
                    }
                  />
                  <Column
                    dataIndex="child"
                    render={(id: number) => {
                      return (
                        <div className="manage-accounts__delete">
                          <button
                            className="btn-icon"
                            data-balloon-pos="up"
                            data-balloon="Delete"
                            onClick={() => {
                              this.handleRemoveAccount(id.toString())
                            }}
                          >
                            <svg
                              height="100%"
                              viewBox="0 0 24 24"
                              width="100%"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                            </svg>
                          </button>
                        </div>
                      )
                    }}
                    width="20"
                  />
                </Table>
              </div>
            </div>
            <Modal
              title="Add account"
              visible={this.state.addDialogVisible}
              onCancel={() => {
                this.setState({
                  addDialogVisible: false,
                  validation: {
                    errors: {},
                    valid: true,
                  },
                })
              }}
              onOk={this.handleAddAccount}
            >
              <div
                className="input-wrap"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    this.handleAddAccount()
                  }
                }}
              >
                <div className="mui-input">
                  <div className="group">
                    <input
                      type="email"
                      value={this.state.newAccountEmail}
                      onChange={e => {
                        this.setState({
                          newAccountEmail: e.target.value,
                        })
                      }}
                    />
                    <span className="bar"></span>
                    <label>E-mail</label>
                  </div>
                  <span className="error">
                    {this.state.validation.errors['email'] || ''}
                  </span>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="card-wrap">
          <div className="card">
            <NoContentBox
              // TODO: Image
              imageUrl={NO_CONTENT['containers']}
              title="Looks like you didn't purchase any team licenses in your plan."
              description="Team licenses can be purchased with the Standard and Premium plans. Owning team licenses allows you to invite your students and colleagues, share custom stacks and more."
              buttonText="Upgrade plan"
              buttonLink="/checkout"
              visible={!this.state.hasPurchasedTeamlicenses}
            />
            <NoContentBox
              // TODO: Image
              imageUrl={NO_CONTENT['containers']}
              title="You didn't add any team members to your account."
              description="Adding a team member is as simple as entering their email."
              buttonText="Add team member"
              buttonAction={() => {
                this.setState({
                  addDialogVisible: true,
                })
              }}
              visible={
                this.state.hasPurchasedTeamlicenses &&
                this.state.multipleAccounts.length === 0
              }
            />
          </div>
        </div>
        <UpgradePlanDialog
          title="Addon limit reached!"
          visible={this.state.upgradePlanDialogVisible}
          description={`You have assigned all of your ${this.state.selectedAddon} addons. Please upgrade your plan if you want to assign more.`}
          onCancel={() => {
            this.setState({
              upgradePlanDialogVisible: false,
            })
          }}
        />
      </>
    )
  }

  private async getMultipleAccountList() {
    try {
      const accounts = (
        await this.props.apiClient.getMultipleAccounts()
      ).filter(acc => acc.deactivated !== 1)

      let assigned = {
        domains: 0,
        connections: 0,
        alwaysOn: 0,
        accounts: accounts.length,
      }

      accounts.forEach(account => {
        if (account.addons) {
          account.addons.forEach(addon => {
            switch (addon.addon_code) {
              case AddonName.DevboxAlwaysOn:
                assigned.alwaysOn += addon.quantity
                break
              case AddonName.Extra15Remote:
                assigned.connections += addon.quantity
                break
              case AddonName.Extra15Domains:
                assigned.domains += addon.quantity
                break
            }
          })
        }
      })

      this.setState({
        multipleAccounts: accounts.map(acc => {
          return {
            ...acc,
            key: acc.child.toString(),
          }
        }),
        hasPurchasedTeamlicenses: true,
        assigned,
      })
    } catch (e) {
      this.setState({
        hasPurchasedTeamlicenses: false,
      })
    }
  }
  private async getSubscriptionAddons() {
    try {
      const subscription = await this.props.apiClient.getAccountSubscription()

      if (subscription) {
        if (subscription.subscription_add_ons.subscription_add_on.length) {
          let addons: { [x: string]: number } = {
            devboxalwayson: 0,
            extra15domains: 0,
            extra15remote: 0,
            multipleaccounts: 0,
          }

          subscription.subscription_add_ons.subscription_add_on.forEach(
            addon => {
              addons[addon.add_on_code] = parseInt(addon.quantity)
            }
          )

          this.setState({
            addons: addons as SubscriptionAddons,
          })
        }
      }
    } catch (e) {
      notification.error({
        message: 'Error getting subscription addon',
      })
    }
  }

  private renderAddonCounter(account: TableRow, addonName: AddonName) {
    if (account.pending_confirmation === 1) {
      return <></>
    }

    const addon = account.addons
      ? account.addons.filter(addon => {
          return addon.addon_code === addonName
        })
      : []

    const quantity = addon.length ? addon[0].quantity : 0

    return (
      <div className="manage-accounts__col">
        <div className="counter">
          <div
            className="counter__top"
            id="up"
            onClick={() => {
              const { key, ...child } = { ...account }

              this.handleSetChildAddon(child, addonName, quantity + 1)
            }}
          >
            <svg
              height="100%"
              viewBox="0 0 24 24"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5v14l11-7z"></path>
            </svg>
          </div>
          <div className="counter__mid" id="mid">
            {quantity}
          </div>
          <div
            className="counter__bot"
            id="down"
            onClick={() => {
              if (quantity === 0) {
                return
              }

              const { key, ...child } = { ...account }

              this.handleSetChildAddon(child, addonName, quantity - 1)
            }}
          >
            <svg
              height="100%"
              viewBox="0 0 24 24"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5v14l11-7z"></path>
            </svg>
          </div>
        </div>
      </div>
    )
  }

  private handleSetChildAddon = async (
    child: ChildAccount,
    addonName: AddonName,
    addonQuantity: number
  ) => {
    try {
      let addonNameReadable = ''

      switch (addonName) {
        case AddonName.AdditionalContainer:
          addonNameReadable = 'Additional Containers'
          break
        case AddonName.DevboxAlwaysOn:
          addonNameReadable = 'Always-On'
          break
        case AddonName.Extra15Domains:
          addonNameReadable = 'Additional Domains'
          break
        case AddonName.Extra15Remote:
          addonNameReadable = 'Additional Connections'
          break
      }

      this.setState({
        selectedAddon: addonNameReadable,
      })

      let newAddon = child.addons
        ? child.addons.filter(addon => addon.addon_code === addonName)
        : []

      if (newAddon.length) {
        newAddon[0].quantity = addonQuantity
      } else {
        newAddon.push({
          addon_code: addonName,
          quantity: addonQuantity,
        })
      }

      if (!child.addons) {
        child.addons = newAddon
      } else if (
        !child.addons.filter(addon => addon.addon_code === addonName).length
      ) {
        child.addons.push(newAddon[0])
      } else {
        child.addons = child.addons.map(addon => {
          if (addon.addon_code !== addonName) {
            return addon
          } else {
            return newAddon[0]
          }
        })
      }

      await this.props.apiClient.setChildAddons(child)
    } catch (e) {
      if (e.exceeded) {
        this.setState({
          upgradePlanDialogVisible: true,
        })
      } else {
        notification.error({
          message: 'An error occurred while setting child addons',
        })
      }
    } finally {
      this.getMultipleAccountList()
      this.getSubscriptionAddons()
    }
  }

  private handleRemoveAccount = async (id: string) => {
    try {
      await this.props.apiClient.closeChildAccount(id)
      this.getMultipleAccountList()
    } catch (e) {
      notification.error({
        message: 'An error occurred while removing the account',
      })
    }
  }

  private handleAddAccount = async () => {
    const validation = Validate(
      {
        email: this.state.newAccountEmail,
      },
      {
        email: new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      },
      {
        email: 'Not a valid email',
      }
    )

    this.setState({
      validation,
      selectedAddon: 'Team Account',
    })

    if (!validation.valid) {
      message.error('Please fill in the account email')
      return
    }

    try {
      await this.props.apiClient.addChildAccount(this.state.newAccountEmail)
      this.getMultipleAccountList()
      this.setState({
        addDialogVisible: false,
        newAccountEmail: '',
      })
    } catch (e) {
      if (e.exceeded) {
        this.setState({
          upgradePlanDialogVisible: true,
        })
      } else {
        notification.error({
          message: 'An error occured while adding the account',
        })
      }
    }
  }
}
