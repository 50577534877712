import * as React from 'react'
import WarningSignIcon from '../styles/img/warning-sign.svg'
import SuccessInfo from '../styles/img/success-info.svg'
import CouponClose from '../styles/img/coupon-close.svg'

type Props = {
  valid: boolean
  couponCode: string
  loading: boolean
  onCouponCodeChange: (couponCode: string) => void
  onApplyClick: () => void
  onCancelClick: () => void
}

type State = {
  didApplyOnce: boolean
}

export class CheckoutCoupon extends React.Component<Props, State> {
  public state: State = {
    didApplyOnce: false,
  }

  render() {
    return this.props.valid ? (
      <div className="applied-coupon">
        <div className={'coupon-success'}>
          <img src={SuccessInfo} alt="Coupon success icon" /> Coupon code will
          be applied on checkout.
        </div>
        <button className={'coupon-badge'} onClick={this.props.onCancelClick}>
          {this.props.couponCode}{' '}
          <img src={CouponClose} alt="Coupon close icon" />
        </button>
      </div>
    ) : (
      <div
        className={`billing-information-field ant-col coupon ${
          !this.props.valid && this.state.didApplyOnce && !this.props.loading
            ? ' error'
            : ''
        }`}
      >
        <label>Enter coupon code</label>
        <div className={'coupon-row'}>
          <input
            type="text"
            value={this.props.couponCode}
            data-recurly="coupon"
            onChange={e => this.props.onCouponCodeChange(e.target.value)}
          />
          <button
            onClick={() => {
              this.setState({
                didApplyOnce: true,
              })
              this.props.onApplyClick()
            }}
            className={`checkout-button${
              !this.props.couponCode || this.props.loading ? ' inactive' : ''
            } coupon-button ${this.props.loading ? 'loading' : ''}`}
          >
            <span>Apply</span>
            <span className={'loader'}></span>
          </button>
        </div>
        <div className="input-error">
          <div>
            <img src={WarningSignIcon} alt="Validation warning icon" />
          </div>
          Coupon code not valid!
        </div>
      </div>
    )
  }
}
