import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { ContainerStacksContainer } from '../containers/ContainerStacksContainer'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  setNavigationTitle: (title: string) => void
  userId: number
  email: string
}

export class CustomStacksPage extends React.Component<Props> {
  componentDidMount() {
    this.props.setNavigationTitle('Custom stacks')

    TagManager.dataLayer({
      dataLayer: {
        event: 'openCustomStacksScreen',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return (
      <div className="main container-stacks">
        <div
          className="stacks-list--wrapper"
          style={{ backgroundColor: '#fff' }}
        >
          <ContainerStacksContainer apiClient={this.props.apiClient} />
        </div>
      </div>
    )
  }
}
