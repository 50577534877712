import React from 'react'
import { Button, message, Modal, notification } from 'antd'
import { Validate, Validation } from '../helpers/validation/Validation'

export type OnChangePasswordParams = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

type Props = {
  onChangePassword: (params: OnChangePasswordParams) => Promise<void>
  onSetInitialPassword: (password: string) => Promise<void>
  hasInitialPassword: boolean
}

type State = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
  updating: boolean
  validation: Validation
  initialPasswordDialogVisible: boolean
  initialPassword: string
  confirmInitialPassword: string
  initialPasswordLoading: boolean
}

export class ChangePassword extends React.PureComponent<Props, State> {
  public state: State = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    updating: false,
    validation: {
      valid: true,
      errors: {},
    },
    initialPasswordDialogVisible: false,
    initialPassword: '',
    confirmInitialPassword: '',
    initialPasswordLoading: false,
  }

  render() {
    return (
      <>
        <div className="card-wrap">
          <h1 id="change_password">Change password</h1>
          <div
            className="card password"
            style={{
              display: this.props.hasInitialPassword ? 'none' : 'block',
            }}
          >
            <div className="card__section col padding-adjustment">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    initialPasswordDialogVisible: true,
                  })
                }}
                style={{
                  width: '50%',
                  margin: '0 auto',
                }}
              >
                Set initial password
              </Button>
            </div>
          </div>
          <div
            className="card password"
            style={{
              display: this.props.hasInitialPassword ? 'block' : 'none',
            }}
          >
            <div className="card__section col padding-adjustment">
              <form name="changePasswordForm">
                <div className="input-wrap old-pass">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="oldPassword"
                        type="password"
                        onChange={this.handleOldPasswordChange}
                        value={this.state.oldPassword}
                      />
                      <span className="bar"></span>
                      <label>Old password</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['oldPassword'] || ''}
                    </span>
                  </div>
                </div>
                <div className="input-wrap new-pass">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="newPassword"
                        type="password"
                        onChange={this.handleNewPasswordChange}
                        value={this.state.newPassword}
                      />
                      <span className="bar"></span>
                      <label>New password</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['newPassword'] || ''}
                    </span>
                  </div>
                </div>
                <div className="input-wrap repeat-pass">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="confirmPassword"
                        type="password"
                        onChange={this.handleConfirmNewPasswordChange}
                        value={this.state.confirmNewPassword}
                      />
                      <span className="bar"></span>
                      <label>Confirm new password</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['confirmNewPassword'] || ''}
                    </span>
                  </div>
                </div>
                <div className="form__error" id="changePasswordError"></div>
              </form>
            </div>
            <div className="card__section">
              <div className="card-footer">
                <button
                  className="btn btn--purple"
                  id="changePassword"
                  onClick={this.handleFormSubmit}
                >
                  {this.state.updating ? 'Updating...' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Set intial password"
          visible={this.state.initialPasswordDialogVisible}
          onCancel={() => {
            this.setState({
              initialPassword: '',
              confirmInitialPassword: '',
              initialPasswordDialogVisible: false,
              validation: {
                errors: {},
                valid: true,
              },
            })
          }}
          okText="Save"
          onOk={this.handleConfirmInitialPassword}
          confirmLoading={this.state.initialPasswordLoading}
        >
          <div
            className="input-wrap"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.handleConfirmInitialPassword()
              }
            }}
          >
            <div className="mui-input">
              <div className="group">
                <input
                  type="password"
                  value={this.state.initialPassword}
                  onChange={e => {
                    this.setState({
                      initialPassword: e.target.value,
                    })
                  }}
                />
                <label>Codeanywhere password</label>
              </div>
              <span className="error">
                {this.state.validation.errors['initialPassword'] || ''}
              </span>
            </div>
          </div>
          <div className="input-wrap">
            <div className="mui-input">
              <div className="group">
                <input
                  type="password"
                  value={this.state.confirmInitialPassword}
                  onChange={e => {
                    this.setState({
                      confirmInitialPassword: e.target.value,
                    })
                  }}
                />
                <label>Confirm password</label>
              </div>
              <span className="error">
                {this.state.validation.errors['confirmInitialPassword'] || ''}
              </span>
            </div>
          </div>
        </Modal>
      </>
    )
  }

  private handleOldPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      oldPassword: event.target.value,
    })
  }

  private handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      newPassword: event.target.value,
    })
  }

  private handleConfirmNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      confirmNewPassword: event.target.value,
    })
  }

  private handleFormSubmit = async () => {
    const validation = Validate(
      {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
      },
      {
        newPassword: new RegExp('^(?=.*\\d)(?=.*[a-zA-Z]).{8,}$'),
      },
      {
        newPassword:
          'Minimum of 8 characters, at least one number and one letter',
      }
    )

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill out the required fields')
      return
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      message.error(`Passwords don't match!`)
      return
    }

    this.setState({
      updating: true,
    })
    try {
      await this.props.onChangePassword({
        newPassword: this.state.newPassword,
        oldPassword: this.state.oldPassword,
        confirmPassword: this.state.confirmNewPassword,
      })
      this.setState({
        confirmNewPassword: '',
        newPassword: '',
        oldPassword: '',
      })
      notification.success({
        message: 'Password updated',
      })
    } catch (ex) {
      notification.error({
        message: 'Error processing request',
        description: String(ex),
      })
    } finally {
      this.setState({
        updating: false,
      })
    }
  }

  private handleConfirmInitialPassword = async () => {
    if (this.state.initialPassword !== this.state.confirmInitialPassword) {
      message.error("Passwords don't match!")
      return
    }

    const validation = Validate(
      {
        initialPassword: this.state.initialPassword,
        confirmInitialPassword: this.state.confirmInitialPassword,
      },
      {
        initialPassword: new RegExp('^(?=.*\\d)(?=.*[a-zA-Z]).{8,}$'),
      },
      {
        initialPassword:
          'Minimum of 8 characters, at least one number and one letter',
      }
    )

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill out the required fields.')
      return
    }

    this.setState({
      initialPasswordLoading: true,
    })

    try {
      await this.props.onSetInitialPassword(this.state.initialPassword)
      this.setState({
        initialPasswordDialogVisible: false,
      })
      notification.success({
        message: 'Password has been set',
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while setting your password',
      })
    } finally {
      this.setState({
        initialPasswordLoading: false,
      })
    }
  }
}
