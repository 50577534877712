import React from 'react'
import './styles/App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AccountPage } from './pages/AccountPage'
import { BillingPage } from './pages/BillingPage'
import { ApiClient, ApiClientEvent } from './api-client/interface/ApiClient'
import { ProfileContainer } from './containers/ProfileContainer'
import { SidebarNavigation } from './components/SidebarNavigation'
import { ConnectionsPage } from './pages/ConnectionsPage'
import { ContainersPage } from './pages/ContainersPage'
import { SharesPage } from './pages/SharesPage'
import { ReferFriendPage } from './pages/ReferFriendPage'
import { RecurlyClient } from './api-client/interface/RecurlyClient'
import { CustomStacksPage } from './pages/CustomStacksPage'
import { ManagedAccountsPage } from './pages/ManagedAccountsPage'
import { Button, Modal, notification, Spin } from 'antd'
import { CheckoutPage } from './pages/CheckoutPage'
import Tour from 'reactour'
import moment from 'moment'
import { ONBOARDING_STEPS } from './api-client/interface/OnboardingSteps'
import * as Sentry from '@sentry/react'
import { ShareApiClient } from './api-client/adapters/ShareApiClient'
import { CancelMembershipPage } from './pages/CancelMembershipPage'
import { AccountDetails } from './api-client/interface/AccountDetails'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'
import { OldEditorBanner } from './components/OldEditorBanner'
import { PRICING_PLANS_NEW } from './api-client/interface/PricingPlan'

type Props = {
  apiClient: ApiClient
  recurlyClient: RecurlyClient
  shareApiClient: ShareApiClient
}

type State = {
  expanded: boolean
  isChildAccount: boolean
  validatingToken: boolean
  didCloseBanner: boolean
  onboardingStep: number
  onboardingVisible: boolean
  didCancelOnboarding: boolean
  shouldDisplayOnboarding: boolean
  firstLogin: boolean
  subscriptionExpiredModalVisible: boolean
  onFreeTrial: boolean
  currentNavigationTab: string
  userId: number
  email: string
  hasOldPlan: boolean
}

export class App extends React.Component<Props, State> {
  public state: State = {
    expanded: false,
    isChildAccount: false,
    validatingToken: true,
    didCloseBanner: false,
    onboardingStep: 0,
    onboardingVisible: false,
    didCancelOnboarding: false,
    firstLogin: false,
    subscriptionExpiredModalVisible: false,
    onFreeTrial: false,
    shouldDisplayOnboarding: false,
    currentNavigationTab: 'Containers',
    userId: -1,
    email: '',
    hasOldPlan: false,
  }

  componentDidMount() {
    this.validateToken()

    this.props.shareApiClient.on(ApiClientEvent.InvitedToShare, event => {
      notification.info({
        message: `${event.shareOwnerUsername} invited you to collaborate in their container`,
        btn: (
          <Button type="primary">
            <a href={event.shareLink} target="_blank" rel="noopener noreferrer">
              Open container
            </a>
          </Button>
        ),
      })
    })
  }

  render() {
    if (this.state.validatingToken) {
      return (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            right: '50%',
          }}
        >
          <div className={'spinner-screen'}>
            <Spin size="large" />
          </div>
        </div>
      )
    }

    return (
      <Router>
        <div id="dashboard" className="dashboard">
          <div
            id="header"
            style={{
              display: window.location.pathname.includes('checkout')
                ? 'none'
                : 'block',
            }}
          >
            <div className="mobile-header">
              <div
                className="mobile-header__btn mobile-nav-open"
                onClick={() => {
                  this.setState({ expanded: !this.state.expanded })
                }}
              >
                <svg
                  height="24"
                  viewBox="0 0 32 32"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"></path>
                </svg>
              </div>
              <div className="mobile-header__title">
                {this.state.currentNavigationTab}
              </div>

              <div className="mobile-header__group">
                <ProfileContainer apiClient={this.props.apiClient} />
              </div>
            </div>

            <div className="container-jumbo">
              <OldEditorBanner
                visible={
                  this.state.hasOldPlan &&
                  this.state.userId < 1750000 &&
                  !this.state.didCloseBanner
                }
                handleCloseBanner={this.handleCloseBanner}
              />
              <div className="header">
                <div className="header__dashboard">
                  <a href="/">Dashboard</a>
                </div>
                <ProfileContainer apiClient={this.props.apiClient} />
              </div>
            </div>
          </div>

          <Tour
            steps={ONBOARDING_STEPS}
            isOpen={this.state.onboardingVisible}
            onRequestClose={() =>
              this.setState({
                onboardingVisible: false,
                didCancelOnboarding: true,
              })
            }
            goToStep={this.state.onboardingStep}
            prevButton={<></>}
            nextButton={this.state.onboardingStep === 0 && <></>}
            closeWithMask={false}
            disableFocusLock={true}
            disableInteraction={false}
            disableDotsNavigation={true}
            disableKeyboardNavigation={['right', 'left']}
            getCurrentStep={currStep => {
              if (currStep === 3) {
                this.setState({
                  onboardingVisible: false,
                })
              }
            }}
            lastStepNextButton={<Button type="primary">Finish</Button>}
            accentColor="rgba(127, 63, 152, 1)"
          />

          <div
            id="body"
            className={`${
              window.location.pathname.includes('checkout') ? 'checkout' : ''
            }`}
          >
            <div
              id="sidebar"
              style={{
                display:
                  window.location.pathname.includes('checkout') ||
                  window.location.pathname.includes('cancel-membership')
                    ? 'none'
                    : 'block',
              }}
            >
              <SidebarNavigation
                expanded={this.state.expanded}
                onCloseClick={() => {
                  this.setState({ expanded: false })
                }}
                isChildAccount={this.state.isChildAccount}
                onNavigationClick={async () => {
                  setTimeout(() => this.checkSubscription(), 1000)
                }}
              />
            </div>
            {this.state.subscriptionExpiredModalVisible ? (
              <Modal
                visible={true}
                title={
                  this.state.onFreeTrial
                    ? 'Trial expired!'
                    : 'Subscription expired!'
                }
                cancelButtonProps={{
                  style: {
                    display: 'none',
                  },
                }}
                okText="Upgrade"
                onOk={() => {
                  const checkout = window.open('/checkout', '_blank')

                  if (checkout) {
                    checkout.onunload = () => {
                      this.checkSubscription()
                    }
                  }
                }}
                getContainer="#main-container"
              >
                <div>
                  {this.state.onFreeTrial
                    ? 'Your trial has expired.'
                    : 'Your subscription has expired.'}
                  To continue using Codeanywhere, upgrade to one of our
                  subscription plans.
                </div>
              </Modal>
            ) : (
              <></>
            )}
            <div
              id="main-container"
              {...(window.location.pathname.includes('checkout') ||
              window.location.pathname.includes('cancel')
                ? {
                    style: {
                      width: '100%',
                    },
                  }
                : {})}
            >
              <Switch>
                <Route
                  path="/billing*"
                  exact
                  render={() => (
                    <BillingPage
                      apiClient={this.props.apiClient}
                      recurlyClient={this.props.recurlyClient}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
                <Route
                  path="/managed-accounts"
                  exact
                  render={() => (
                    <ManagedAccountsPage
                      apiClient={this.props.apiClient}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
                <Route
                  path="/connections*"
                  exact
                  render={() => (
                    <ConnectionsPage
                      apiClient={this.props.apiClient}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
                <Route
                  path="/custom-stacks*"
                  exact
                  render={() => (
                    <CustomStacksPage
                      apiClient={this.props.apiClient}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
                <Route
                  path="/refer-a-friend"
                  exact
                  render={() => (
                    <ReferFriendPage
                      apiClient={this.props.apiClient}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
                <Route
                  path="/account"
                  exact
                  render={() => (
                    <AccountPage
                      apiClient={this.props.apiClient}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
                <Route
                  path="/cancel-membership"
                  exact
                  render={() => (
                    <CancelMembershipPage
                      apiClient={this.props.apiClient}
                      setNavigationTitle={this.setNavigationTitle}
                    />
                  )}
                />
                <Route
                  path="/checkout"
                  exact
                  render={() => (
                    <CheckoutPage
                      apiClient={this.props.apiClient}
                      recurlyClient={this.props.recurlyClient}
                    />
                  )}
                />
                <Route
                  path="/shared-with-me"
                  exact
                  render={() => (
                    <SharesPage
                      apiClient={this.props.apiClient}
                      shareApiClient={this.props.shareApiClient}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
                <Route
                  path=""
                  exact
                  render={() => (
                    <ContainersPage
                      apiClient={this.props.apiClient}
                      onCreateContainer={() => {
                        if (!this.state.firstLogin) {
                          return
                        }

                        this.setState({
                          onboardingVisible: !this.state.didCancelOnboarding,
                          firstLogin: false,
                        })
                      }}
                      onSelectedStackChange={() => {
                        this.setState({
                          onboardingStep: 3,
                        })
                      }}
                      setNavigationTitle={this.setNavigationTitle}
                      userId={this.state.userId}
                      email={this.state.email}
                    />
                  )}
                />
              </Switch>
              <footer
                className="footer"
                style={{
                  display: window.location.pathname.includes('checkout')
                    ? 'none'
                    : 'block',
                }}
              >
                <ul>
                  <li>
                    <a
                      href="https://docs.codeanywhere.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.codeanywhere.com/general/getting-started/faq"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://codeanywhere.com/contact"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://codeanywhere.tapfiliate.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Become an affiliate
                    </a>
                  </li>
                </ul>
              </footer>
            </div>
          </div>
        </div>
      </Router>
    )
  }

  private async validateToken() {
    try {
      await this.props.apiClient.validateToken()

      // if (await this.isBeta()) {
      //   this.setState({
      //     validatingToken: false,
      //   })

      //   this.getIsChild()
      // } else {
      //   window.location.href = `${String(process.env.REACT_APP_API_CREDENTIALS_URL)}/dashboard`
      // }

      const accountDetails = await this.checkIfOAuthUser()

      if (accountDetails) {
        this.setState({
          validatingToken: false,
        })

        this.setupTour(accountDetails)
        this.getIsChild(accountDetails)

        //TODO: hah
        try {
          await this.props.shareApiClient.setupSocket()
        } catch (e) {
          notification.error({
            message: 'Could not connect to share notification socket.',
          })
        }
      }
    } catch (e) {
      window.location.href = `${String(
        process.env.REACT_APP_LOGIN_URL
      )}?callback_uri=${window.location.href}`
    }
  }

  private async checkIfOAuthUser() {
    try {
      const accountDetails = await this.props.apiClient.getAccountDetails()
      const registeredRecently =
        moment().diff(accountDetails.reg_date, 'minute') < 10

      const isOAuthUser =
        document.referrer.includes('accounts.google') ||
        document.referrer.includes('github') ||
        document.referrer.includes('bitbucket') ||
        document.referrer.includes('facebook')

      if (isOAuthUser && registeredRecently) {
        this.triggerOAuthSignupEvents(
          accountDetails.user_id,
          accountDetails.email
        )
        window.location.href = '/checkout'
      } else {
        this.setState({
          userId: accountDetails.user_id,
          email: accountDetails.email,
          hasOldPlan: this.userHasOldPlan(accountDetails.plancode),
        })

        return accountDetails
      }
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your account info',
      })
    }
  }

  private triggerOAuthSignupEvents(userId: number, email: string) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'accountCreated',
        userId,
        emailHash: String(sha256(email)),
      },
    })
    //@ts-ignore
    tap('customer', user_id)
  }

  private async setupTour(accountDetails: AccountDetails) {
    const createContainerButton = document.querySelector(
      '#create-container--button'
    )

    if (!createContainerButton) {
      setTimeout(() => this.setupTour(accountDetails), 100)
      return
    }

    const shouldDisplayOnboarding =
      moment(accountDetails.reg_date).isSame(moment.now(), 'day') &&
      accountDetails.usage.devboxes === 0

    this.setState({
      shouldDisplayOnboarding,
    })

    if (shouldDisplayOnboarding) {
      createContainerButton.scrollIntoView()
      createContainerButton.addEventListener(
        'click',
        this.handleCreateContainerButtonClick
      )

      setTimeout(() => {
        this.setState({
          onboardingVisible: true,
          firstLogin: true,
        })

        this.setState({
          onboardingStep: window.location.search.includes('create=true')
            ? 1
            : 0,
        })
      }, 1000)
    }
  }

  private async getIsChild(accountDetails: AccountDetails) {
    try {
      Sentry.setUser({
        id: accountDetails.user_id.toString(),
        email: accountDetails.email,
        username: accountDetails.username,
        firstName: accountDetails.firstname,
        lastName: accountDetails.lastname,
      })

      this.setState(
        {
          isChildAccount: accountDetails.child_account,
        },
        () => {
          if (!accountDetails.child_account) {
            this.checkSubscription(true)
          }
        }
      )
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your account info',
      })
    }
  }

  private async checkSubscription(triggerLoginEvent?: boolean) {
    try {
      const accountDetails = await this.props.apiClient.getAccountDetails()
      const subscription = await this.props.apiClient.getAccountSubscription(
        true
      )

      const onFreeTrial = accountDetails.trial

      if (triggerLoginEvent) {
        this.triggerLoginEvent(accountDetails)
      }

      const dontShowModal =
        window.location.href.includes('account') ||
        window.location.href.includes('billing') ||
        window.location.href.includes('checkout')

      const subscriptionExpiredModalVisible =
        !dontShowModal && !onFreeTrial && subscription === null

      this.setState({
        onFreeTrial,
        subscriptionExpiredModalVisible,
      })
    } catch (e) {
      //silently fail
      if (this.state.isChildAccount) {
        return
      }
      notification.error({
        message: 'An error occurred while getting your account info.',
      })
    }
  }

  private triggerLoginEvent(accountDetails: AccountDetails) {
    if (
      document.referrer === 'https://codeanywhere.com/' ||
      document.referrer === 'https://accounts.google.com/' ||
      document.referrer === 'https://github.com/' ||
      document.referrer === 'https://bitbucket.org/' ||
      document.referrer === 'https://www.facebook.com/'
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'successfulLogin',
          userId: accountDetails.user_id,
          emailHash: String(sha256(accountDetails.email)),
        },
      })
    }
  }

  private handleCloseBanner = () => {
    this.setState({
      didCloseBanner: true,
    })

    const body = document.getElementById('body')

    if (body) {
      body.style.paddingTop = '78px'
    }
  }

  private handleCreateContainerButtonClick = () => {
    const containerNameInput = document.querySelector(
      '#new-container-name--input'
    ) as HTMLInputElement | null

    if (!containerNameInput) {
      setTimeout(() => this.handleCreateContainerButtonClick(), 100)
      return
    }

    setTimeout(() => {
      this.setState({
        onboardingStep: 1,
      })
    }, 500)

    containerNameInput.addEventListener('focusout', e => {
      if (containerNameInput.value) {
        this.setState(() => {
          this.setState({
            onboardingStep: 2,
          })
        })
      }
    })
  }

  private setNavigationTitle = (title: string) => {
    this.setState({
      currentNavigationTab: title,
    })
  }

  private async isBeta() {
    try {
      const accountDetails = await this.props.apiClient.getAccountDetails()

      if (!accountDetails.beta) {
        return false
      }
    } catch (e) {
      // Silently fail
    }

    return true
  }

  private userHasOldPlan = (plancode: string) => {
    return !PRICING_PLANS_NEW.some(p => plancode.includes(p.code))
  }
}
