import { AccountDetails } from './AccountDetails'
import { PricingPlan } from './PricingPlan'
import { AccountConnection } from './AccountConnection'
import { AuthProvider } from './AuthProvider'
import { BillingInfo } from './BillingInfo'
import { Invoice } from './Invoice'
import {
  Connection,
  SSHConnection,
  FTPConnection,
  FTPSConnection,
  ContainerConnection,
  CreateContainer,
  ContainerStackList,
  CreateCustomStack,
  ContainerDomain,
  CreateContainerDomain,
  CancelSubscriptionFeedback,
  ConnectionType,
} from './Connection'
import { ChildAccount, Subscription, SubscriptionAddons } from './Subscription'
import { CreditsPercentage } from '../../pages/CancelMembershipPage'

export interface ReferralWidgetData {
  has_unused_referee_credit: boolean
  referees: number[] | null
  account_credit_in_cents: number
  referral_code: string
}

export enum ApiClientEvent {
  ContainerStateChanged = 'ContainerStateChanged',
  LegacyContainerStateChanged = 'LegacyContainerStateChanged',
  InvitedToShare = 'InvitedToShare',
}

export type ApiClientEvents = {
  [ApiClientEvent.ContainerStateChanged]: {
    containerId: number
    state: number
  }
  [ApiClientEvent.InvitedToShare]: {
    shareId: string
    shareOwnerUsername: string
    shareLink: string
  }
}

export interface ApiClient {
  authProvider: AuthProvider

  validateToken(): Promise<void>

  getAccountDetails(): Promise<AccountDetails>
  updateAccount(params: {
    firstname: string
    lastname: string
    username: string
    company: string
  }): Promise<void>
  deleteAccount(confirmPassword: string): Promise<void>
  updateAccountPassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<void>
  changeEmail(newEmail: string, password: string): Promise<void>
  hasInitialPassword(): Promise<boolean>
  setInitialPassword(password: string): Promise<void>

  getConnectedAccounts(): Promise<AccountConnection[]>
  removeConnectedAccount(provider: string, authId: string): Promise<void>

  sendResetPasswordLink(): Promise<void>
  has2FAEnabled(): Promise<boolean>
  disable2FA(): Promise<void>
  get2FAQRCode(): Promise<{
    code: string
    qrFullPath: string
  }>
  enable2FA(code: string): Promise<void>
  resendVerificationEmail(): Promise<void>
  getAccountSSHPublicKey(): Promise<string>

  getConnectionList(): Promise<(ConnectionType | Connection)[]>
  getConnection(id: number): Promise<ConnectionType>
  removeConnection(connectionId: number): Promise<void>
  renameConnection(connectionId: number, newName: string): Promise<void>
  addConnection(
    connection: SSHConnection | FTPConnection | FTPSConnection
  ): Promise<void>
  editConnection(
    connection: SSHConnection | FTPConnection | FTPSConnection
  ): Promise<void>

  on<K extends keyof ApiClientEvents>(
    s: K,
    listener: (v: ApiClientEvents[K]) => void
  ): this

  getContainerList(): Promise<ContainerConnection[]>
  startContainer(containerId: number): Promise<void>
  stopContainer(containerId: number): Promise<void>
  restartContainer(containerId: number): Promise<void>
  getContainerInfo(containerId: number): Promise<string>
  setContainerAlwaysOn(containerId: number, alwaysOn: boolean): Promise<void>
  createContainer(container: CreateContainer): Promise<number>
  getContainerSlug(containerId: number): Promise<string | null>

  getContainerStacks(): Promise<ContainerStackList>
  createCustomStack(stack: CreateCustomStack): Promise<void>
  removeCustomStack(stackId: string): Promise<void>

  getPricingPlans(): Promise<PricingPlan[]>
  createSubscription(
    plan: string,
    addons: SubscriptionAddons,
    coupon: string
  ): Promise<void>
  reactivateSubscription(planUuid: string): Promise<void>
  updateSubscription(
    plan: string,
    addons: SubscriptionAddons,
    type: string
  ): Promise<void>
  getBillingInfo(): Promise<BillingInfo>
  getAccountSubscription(
    forceRefreshCache?: boolean
  ): Promise<Subscription | null>
  getAllAccountSubscriptions(): Promise<Subscription[]>
  getMultipleAccounts(): Promise<ChildAccount[]>
  addChildAccount(email: string): Promise<void>
  closeChildAccount(childId: string): Promise<void>
  setChildAddons(child: ChildAccount): Promise<void>

  getInvoices(): Promise<Invoice[]>
  downloadInvoicePdf(invoiceId: string): Promise<void>
  updateBillingInfo(tokenId: string): Promise<void>

  getReferralWidgetData(): Promise<ReferralWidgetData>

  getDomainList(): Promise<ContainerDomain[]>
  addDomain(domain: CreateContainerDomain): Promise<void>
  removeDomain(domainId: string): Promise<void>

  cancelSubscription(
    feedback: CancelSubscriptionFeedback,
    planUuid: string
  ): Promise<void>

  submitSupportTicket(feedback: CancelSubscriptionFeedback): Promise<void>
  submitCancellationReasonsAndSuggestions(
    userId: number,
    email: string,
    planCode: string,
    activatedAt: string,
    canceledAt: string,
    reasons: string,
    comment: string,
    rivalEditor: string,
    suggestions: string
  ): Promise<void>
  createCancelCredit(creditPercentage: CreditsPercentage): Promise<void>
  cancelCreditUsed(): Promise<boolean>

  getAccountBalanceInCents(): Promise<number>

  createAffiliateCustomerCoupon(): Promise<string>
}
