import * as React from 'react'

type Props = {
  containerTimeUsage: number
  containerTimeLimit: number
}

export class ContainerTime extends React.PureComponent<Props> {
  render() {
    return (
      <div className="upgrade-row ">
        <div className="upgrade-row__top">
          <div
            className={`resource__progress ${
              this.props.containerTimeUsage / this.props.containerTimeLimit <
              0.33
                ? 'green'
                : this.props.containerTimeUsage /
                    this.props.containerTimeLimit >
                  0.66
                ? 'red'
                : 'yellow'
            }`}
            style={{
              width: `${
                (this.props.containerTimeUsage /
                  this.props.containerTimeLimit) *
                100
              }%`,
              height: '5px',
            }}
          ></div>
        </div>
        <div className="upgrade-row__bot">
          <div className="upgrade-row__bot--left">
            {Math.round(this.props.containerTimeUsage / 60)}/
            {this.props.containerTimeLimit / 60} min
          </div>
          <div className="upgrade-row__bot--right">Container Time</div>
        </div>
      </div>
    )
  }
}
