import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { ShareApiClient } from '../api-client/adapters/ShareApiClient'
import { SharesContainer } from '../containers/SharesContainer'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  shareApiClient: ShareApiClient
  setNavigationTitle: (title: string) => void
  userId: number
  email: string
}

export class SharesPage extends React.Component<Props> {
  componentDidMount() {
    this.props.setNavigationTitle('Shared with me')

    TagManager.dataLayer({
      dataLayer: {
        event: 'openSharesScreen',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return (
      <div className="main containers">
        <div
          className="container-list--wrapper"
          style={{ padding: '30px', backgroundColor: '#fff' }}
        >
          <SharesContainer
            apiClient={this.props.apiClient}
            shareApiClient={this.props.shareApiClient}
          />
        </div>
      </div>
    )
  }
}
