import { Modal } from 'antd'
import React from 'react'
import { Distro } from '../api-client/interface/Connection'
import { ContainerStackType } from '../helpers/container-stacks/TableDataTransform'
import { Validation } from '../helpers/validation/Validation'
import { ContainerStacks, PredefinedFilter } from './ContainerStacks'

type TableData = Distro & {
  key: string
  tags: string[]
  stackType: ContainerStackType
  stackName: string
  stackId: string
}

type Props = {
  stacks: TableData[]
  visible: boolean
  newContainerName: string
  loadingStacks: boolean
  selectedStack: string
  alwaysOn: boolean
  validation: Validation
  onCancelCreate: () => void
  onChangeNewContainerName: (newName: string) => void
  onConfirmCreate: (stackId: string, distroId: string) => Promise<void>
  onSelectedStackChange: (selectedStack: string) => void
  onAlwaysOnChange: (alwaysOn: boolean) => void
  onModalClose: () => void
}

type State = {
  confirmLoading: boolean
  didCancel: boolean
}

export class CreateContainerModel extends React.Component<Props, State> {
  public state: State = {
    confirmLoading: false,
    didCancel: false,
  }

  render() {
    return (
      <Modal
        confirmLoading={this.state.confirmLoading}
        visible={this.props.visible}
        title="New container"
        style={{ minWidth: '60%', maxWidth: '80%' }}
        onCancel={() => {
          this.setState({
            didCancel: true,
          })
          this.props.onCancelCreate()
        }}
        onOk={this.handleConfirmCreate}
        okButtonProps={{
          id: 'confirm-create-container--button',
        }}
        okText="Create"
        afterClose={() => {
          if (!this.state.didCancel) {
            this.props.onModalClose()
          }
        }}
      >
        <div
          className="mui-input"
          style={{
            width: '30%',
          }}
          id="new-container-name--input-wrapper"
        >
          <div className="group">
            <input
              type="text"
              value={this.props.newContainerName}
              onFocus={event => event.target.select()}
              required
              onChange={e =>
                this.props.onChangeNewContainerName(e.target.value)
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.handleConfirmCreate()
                }
              }}
              id="new-container-name--input"
            />
            <span className="bar"></span>
            <label>Name</label>
          </div>
          <span className="error">
            {this.props.validation.errors['newContainerName'] || ''}
          </span>
        </div>
        <ContainerStacks
          stacks={this.props.stacks}
          selectedStack={this.props.selectedStack}
          onSelectedStackChange={this.props.onSelectedStackChange}
          alwaysOn={this.props.alwaysOn}
          onAlwaysOnChange={this.props.onAlwaysOnChange}
          defaultPredefinedFilter={PredefinedFilter.All}
          showFiltersDropdown={true}
          scrollY={240}
          scrollX={240}
          loadingStacks={this.props.loadingStacks}
          selectId="container-stacks--select"
          handleConfirmCreate={this.handleConfirmCreate}
        />
      </Modal>
    )
  }

  private handleConfirmCreate = async () => {
    this.setState({
      confirmLoading: true,
    })

    const selectedRow = this.props.stacks.filter(
      stack => stack.key === this.props.selectedStack
    )

    try {
      //silently fail if no selected stacks
      if (selectedRow.length) {
        await this.props.onConfirmCreate(
          selectedRow[0].stackId,
          selectedRow[0].id
        )
      }
    } finally {
      this.setState({
        confirmLoading: false,
      })
    }
  }
}
