import { Modal, notification, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import {
  ConnectionType,
  FTPConnection,
  FTPSConnection,
  SSHConnection,
} from '../api-client/interface/Connection'
import { CONNECTION_TYPE } from '../helpers/image-imports/ConnectionType'

type TableData = ConnectionType & {
  key: string
}

type Props = {
  connections: TableData[]
  loadingConnections: boolean
  onRemoveConnection: (connectionId: number) => Promise<void>
  onEditConnectionClick: (
    connection: SSHConnection | FTPConnection | FTPSConnection
  ) => void
  onEditContainerClick: (containerId: number, containerName: string) => void
  onRefreshConnections: () => Promise<void>
}

type State = {
  editDialogSaving: boolean
  connectionIdToDelete: number
  connectionNameToDelete: string
  confirmDeleteDialogVisible: boolean
}

export class Connections extends React.Component<Props, State> {
  public state: State = {
    editDialogSaving: false,
    connectionIdToDelete: -1,
    connectionNameToDelete: '',
    confirmDeleteDialogVisible: false,
  }

  render() {
    return (
      <>
        <Table
          className="connection-list"
          dataSource={this.props.connections}
          pagination={
            this.props.connections.length < 20
              ? false
              : {
                  defaultPageSize: 30,
                  showSizeChanger: true,
                }
          }
          style={{
            backgroundColor: '#fff',
          }}
          rowClassName={() => 'connection-list--row'}
          loading={this.props.loadingConnections}
        >
          <Column
            title=""
            dataIndex="type"
            key="type"
            width="10%"
            ellipsis={true}
            render={(type: string) => {
              if (!CONNECTION_TYPE[type]) {
                return <span>{type}</span>
              }

              return (
                <img
                  src={CONNECTION_TYPE[type]}
                  alt="Connection type"
                  style={{ width: '20px', height: '20px' }}
                />
              )
            }}
          />
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Actions"
            render={(conn: ConnectionType) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <button
                  className="btn-icon"
                  data-balloon-pos="up"
                  data-balloon="Edit"
                  onClick={() => {
                    if (conn.type === 'ssh' || conn.type.includes('ftp')) {
                      switch (conn.type) {
                        case 'ssh':
                          this.props.onEditConnectionClick(
                            conn as SSHConnection
                          )
                          break
                        case 'ftp':
                          this.props.onEditConnectionClick(
                            conn as FTPConnection
                          )
                          break
                        case 'ftps':
                          this.props.onEditConnectionClick(
                            conn as FTPSConnection
                          )
                          break
                      }
                    } else {
                      this.props.onEditContainerClick(conn.id, conn.name)
                    }
                  }}
                  style={{ marginRight: '10px' }}
                >
                  <svg
                    height="20px"
                    viewBox="0 0 24 24"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                  </svg>
                </button>
                <button
                  onClick={() => {
                    this.setState({
                      connectionIdToDelete: conn.id,
                      connectionNameToDelete: conn.name,
                      confirmDeleteDialogVisible: true,
                    })
                  }}
                >
                  <svg
                    height="15px"
                    width="15px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21.9 21.9"
                  >
                    <path
                      fill="#7f3f98"
                      d="M14.1 11.3c-.2-.2-.2-.5 0-.7l7.5-7.5c.2-.2.3-.5.3-.7s-.1-.5-.3-.7L20.2.3c-.2-.2-.5-.3-.7-.3-.3 0-.5.1-.7.3l-7.5 7.5c-.2.2-.5.2-.7 0L3.1.3C2.9.1 2.6 0 2.4 0s-.5.1-.7.3L.3 1.7c-.2.2-.3.5-.3.7s.1.5.3.7l7.5 7.5c.2.2.2.5 0 .7L.3 18.8c-.2.2-.3.5-.3.7s.1.5.3.7l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3l7.5-7.5c.2-.2.5-.2.7 0l7.5 7.5c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.2-.2.3-.5.3-.7s-.1-.5-.3-.7l-7.5-7.5z"
                    ></path>
                  </svg>
                </button>
              </div>
            )}
          />
        </Table>
        <Modal
          visible={this.state.confirmDeleteDialogVisible}
          title="Are you sure you want to remove this connection?"
          onCancel={() => {
            this.setState({
              confirmDeleteDialogVisible: false,
              connectionIdToDelete: -1,
              connectionNameToDelete: '',
            })
          }}
          okText="Yes"
          onOk={this.handleConfirmDeleteConnection}
        >
          <div>
            You are permenantly removing {this.state.connectionNameToDelete}{' '}
            from your account.
          </div>
        </Modal>
      </>
    )
  }

  private handleConfirmDeleteConnection = async () => {
    try {
      await this.props.onRemoveConnection(this.state.connectionIdToDelete)
      this.setState({
        confirmDeleteDialogVisible: false,
        connectionNameToDelete: '',
        connectionIdToDelete: -1,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while removing the connection.',
        description: e.toString(),
      })
    }
  }
}
