import * as React from 'react'
import { Button } from 'antd'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  userId: number
  email: string

  username: string
  firstName: string
  biyearlyMinMonthPrice: number
  subscriptionExpirationDate: string
  displayStep: boolean
  goToNextStep: () => void
}

export class CancelTwoYearStep extends React.Component<Props> {
  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowTwoYearEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <h1 className="cancellation__title">
          {this.props.firstName !== ''
            ? this.props.firstName
            : this.props.username}
          , switch to a 2-year plan and save more?
        </h1>
        <div className="cancellation__plan">
          <h2 className="cancellation__plan-title">
            Get all the benefits of Codeanywhere for less
          </h2>
          <h3 className="cancellation__plan-subtitle">
            Enjoy all the benefits of Codeanywhere for as low as $
            {this.props.biyearlyMinMonthPrice.toFixed(2)} per month for two
            years and save more
          </h3>
        </div>
        <div className="cancellation__actions">
          <Button
            id="cancel-membership-twoyear--button"
            className="cancellation__action cancellation__action--type-cancel"
            onClick={() => {
              document.querySelector('body')?.scrollTo(0, 0)
              this.props.goToNextStep()
            }}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-twoyear--button"
            type="primary"
            className="cancellation__action cancellation__action--type-success"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'cancelFlowSwitchTwoYear',
                  userId: this.props.userId,
                  emailHash: String(sha256(this.props.email)),
                },
              })
              window.location.replace(
                '/checkout?plancode=standard_buy&period=biyearly'
              )
            }}
          >
            Switch to 2-year plan
          </Button>
        </div>
      </>
    ) : null
  }
}
