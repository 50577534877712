import React from 'react'
import { Modal, Button } from 'antd'
import { Link } from 'react-router-dom'

type Props = {
  visible: boolean
  title: string
  description: string
  onCancel: () => void
}

export class UpgradePlanDialog extends React.PureComponent<Props> {
  render() {
    return (
      <Modal
        title={this.props.title}
        footer={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={this.props.onCancel}>Cancel</Button>
            <Link to="/checkout" target="_blank">
              <Button type="primary">Upgrade</Button>
            </Link>
          </div>
        }
        visible={this.props.visible}
      >
        <div>{this.props.description}</div>
      </Modal>
    )
  }
}
