import { AddonName } from '../../api-client/interface/Subscription'
import multipleaccounts from '../../styles/img/addons/multipleaccounts.svg'

export const ADDONS: { [x in AddonName]: string } = {
  multipleaccounts,
  // TODO: Get icons
  extra15domains: multipleaccounts,
  extra15remote: multipleaccounts,
  devboxalwayson: multipleaccounts,
  additionalcontainer: multipleaccounts,
}
