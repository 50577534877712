import { ContainerTableData } from '../../components/Containers'

export const containerStateColor = (container: ContainerTableData) => {
  switch (container.state) {
    case 10:
      return 'green'
    case 20:
      return 'red'
    case 11:
    case 12:
    case 13:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
      return 'rgb(204,204,0)'
    default:
      // CA Color
      return 'rgba(127, 63, 152, 1)'
  }
}
