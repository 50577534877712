import { ReactourStep } from 'reactour'
import * as React from 'react'

export const ONBOARDING_STEPS: ReactourStep[] = [
  {
    selector: '#create-container--button',
    content: (
      <div className="onboarding-step--wrapper">
        <div className="onboarding-step--title">
          <strong>Create your first container</strong>
        </div>
        <div className="onboarding-step--description">
          Container is your private virtual development environment that you can
          fully customize.Each one comes with its own dedicated amount of memory
          and disk space.
        </div>
      </div>
    ),
  },
  {
    selector: '#new-container-name--input-wrapper',
    content: (
      <div className="onboarding-step--wrapper">
        <div className="onboarding-step--title">
          <strong>Name your first container </strong>
        </div>
        <div className="onboarding-step--description">
          A container can have multiple projects, folders, Git repositories,
          etc.
        </div>
      </div>
    ),
    stepInteraction: true,
  },
  {
    selector: '#container-stacks--table-wrapper',
    content: (
      <div className="onboarding-step--wrapper">
        <div className="onboarding-step--title">
          <strong>Choose your containers development stack </strong>
        </div>
        <div className="onboarding-step--description">
          Select one of the pre - defined development stacks for a quick start,
          or create a blank container and install all the tools manually.
        </div>
      </div>
    ),
  },
  {
    selector: '#open-ide--button',
    content: (
      <div className="onboarding-step--wrapper">
        <div className="onboarding-step--title">
          <strong>Thats it! </strong>
        </div>
        <div className="onboarding-step--description">
          The IDE will open automatically as soon as it is ready. Happy coding!
        </div>
      </div>
    ),
  },
]
