import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Coupon, RecurlyClient } from '../api-client/interface/RecurlyClient'
import {
  CheckoutStep1Container,
  PlanPeriod,
} from '../containers/CheckoutStep1Container'
import { CheckoutStep2Container } from '../containers/CheckoutStep2Container'
import {
  BillingInfoDto,
  CheckoutStep3Container,
  PaymentMethod,
} from '../containers/CheckoutStep3Container'
import CACheckoutLogo from '../styles/img/codeanywhere_logo.svg'
import {
  AddonName,
  Subscription,
  SubscriptionAction,
  SubscriptionAddons,
} from '../api-client/interface/Subscription'
import { Validate, Validation } from '../helpers/validation/Validation'
import { UseRecurlyInstance } from '@recurly/react-recurly'
import {
  ComparePlansNew,
  PricingPlan,
  PricingPlanCode,
  PRICING_PLANS_NEW,
} from '../api-client/interface/PricingPlan'
import { message, notification, Spin } from 'antd'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'
import { parse as parseQs } from 'querystring'
import { ProfileContainer } from '../containers/ProfileContainer'
import CloseIcon from '../styles/img/close.svg'
import { PayWithAmazon } from '../scripts/pay-with-amazon.min.js'
import moment from 'moment'
import { ApplePayInstance } from 'recurly__recurly-js'
import { parse as parseCookie } from 'cookie'

type Props = {
  apiClient: ApiClient
  recurlyClient: RecurlyClient
}

enum CheckoutStep {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
}

type State = {
  checkoutStep: CheckoutStep
  planPeriod: PlanPeriod
  pricingPlans: PricingPlan[]
  selectedPlan: PricingPlan
  addons: SubscriptionAddons
  paymentMethod: PaymentMethod
  action: SubscriptionAction
  modalVisible: boolean
  firstClickMonthly: boolean
  hasBillingInformation: boolean
  subscription: Subscription | null
  loadingSubscription: boolean
  onFirstSubscription: boolean
  refundPeriodExpired: boolean
  creditInCents: number
  creatingSubscription: boolean
  userId: number
  email: string

  coupon: Coupon | null
  couponCode: string
  couponActive: boolean
  couponLoading: boolean

  // Billing info - CARD
  billingInfo: BillingInfoDto
  validation: Validation
  additionalFieldsActive: boolean

  teamAddonVisible: boolean

  displayAmazonPayButton: boolean
  displayAmazonPayNow: boolean
  disableAmazonPayNow: boolean

  loadingPaymentConfirm: boolean

  amazonPayToken: string | null

  applePayReady: boolean
}

export class CheckoutPage extends React.Component<Props, State> {
  public state: State = {
    checkoutStep: CheckoutStep.STEP1,
    planPeriod: PlanPeriod.BIYEARLY,
    pricingPlans: PRICING_PLANS_NEW,
    selectedPlan: PRICING_PLANS_NEW.find(p => p.code.includes('standard'))!,
    addons: {
      additionalcontainer: 0,
      devboxalwayson: 0,
      extra15remote: 0,
      extra15domains: 0,
      multipleaccounts: 0,
    },
    creatingSubscription: false,
    userId: -1,
    email: '',
    paymentMethod: PaymentMethod.CARD,
    billingInfo: {
      firstName: '',
      lastName: '',
      vatNumber: '',
      country: '',
      company: '',
      postalCode: '',
      address: '',
      city: '',
    },
    validation: {
      valid: true,
      errors: {},
    },
    additionalFieldsActive: false,
    action: 'create',
    modalVisible: false,
    firstClickMonthly: false,
    hasBillingInformation: false,
    subscription: null,
    loadingSubscription: true,
    onFirstSubscription: false,
    refundPeriodExpired: true,
    coupon: null,
    couponCode: '',
    couponActive: false,
    couponLoading: false,
    creditInCents: 0,
    teamAddonVisible: false,
    displayAmazonPayButton: true,
    displayAmazonPayNow: false,
    disableAmazonPayNow: true,
    loadingPaymentConfirm: false,
    amazonPayToken: '',
    applePayReady: false,
  }

  private form: React.RefObject<HTMLFormElement>
  private recurly?: UseRecurlyInstance
  private payWithAmazon: typeof PayWithAmazon
  private checkoutTotal: string = ''
  private applePay: ApplePayInstance | null = null

  constructor(props: Props, context: any) {
    super(props, context)

    this.form = React.createRef()
  }

  componentDidMount() {
    this.getAccountDetails()
    this.getBillingInfo()
    this.getAccountSubscription()
    this.getAccountCredits()
    this.generateCouponForAffiliateCustomers()

    const query = parseQs(window.location.search.slice(1))

    if (query['coupon']) {
      this.handleCouponApply(query['coupon'] as string)
    }

    if (query['action'] && query['action'] !== 'undefined') {
      this.setState({
        action: query['action'] as SubscriptionAction,
      })
    }

    if (
      query['plancode'] &&
      query['plancode'] !== 'undefined' &&
      query['period'] &&
      query['period'] !== 'undefined'
    ) {
      const selectedPlan = this.state.pricingPlans.find(
        p => p.code === query['plancode']
      )

      if (selectedPlan) {
        this.setState({
          selectedPlan,
          planPeriod:
            query['period'] === 'monthly'
              ? PlanPeriod.MONTHLY
              : query['period'] === 'yearly'
              ? PlanPeriod.YEARLY
              : PlanPeriod.BIYEARLY,
        })
      }
    }

    this.createAmazonPayWidget()
  }

  render() {
    const planPeriodText =
      this.state.planPeriod === PlanPeriod.MONTHLY
        ? '1-month'
        : this.state.planPeriod === PlanPeriod.YEARLY
        ? '1-year'
        : '2-year'

    if (this.state.loadingSubscription) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={'spinner-screen'}>
            <Spin size="large" />
          </div>
        </div>
      )
    }

    return (
      <div className="checkout-page">
        <div className="container">
          <div className="checkout__header">
            <div className="checkout-header__logo">
              <img
                src={CACheckoutLogo}
                alt="Checkout logo"
                onClick={() => (window.location.href = '/')}
                style={{ cursor: 'pointer' }}
              />
              <span>Checkout</span>
            </div>
            <div className="checkout-header__login">
              <ProfileContainer apiClient={this.props.apiClient} />
            </div>
          </div>
          <ul className="checkout__breadcrumb">
            <li
              className="checkout__breadcrumb-item"
              onClick={() => this.onBreadcrumbClick(CheckoutStep.STEP1)}
              data-active={this.state.checkoutStep === CheckoutStep.STEP1}
            >
              1. Choose plan
            </li>
            <li
              className="checkout__breadcrumb-item"
              onClick={() => this.onBreadcrumbClick(CheckoutStep.STEP2)}
              data-active={this.state.checkoutStep === CheckoutStep.STEP2}
            >
              2. Addons
            </li>
            <li
              className="checkout__breadcrumb-item"
              onClick={() => this.onBreadcrumbClick(CheckoutStep.STEP3)}
              data-active={this.state.checkoutStep === CheckoutStep.STEP3}
            >
              3. Payment
            </li>
          </ul>
          <div
            className="checkout-modal-backdrop"
            style={{
              display: this.state.modalVisible ? 'block' : 'none',
            }}
          >
            <div className="checkout-modal">
              <div className="checkout-modal__body">
                <div className="checkout-modal__title">
                  Why not try the{' '}
                  {this.state.planPeriod === PlanPeriod.BIYEARLY
                    ? '2-year '
                    : 'yearly '}
                  plan?
                </div>
                <div className="checkout-modal__description">
                  Users who use the{' '}
                  {this.state.planPeriod === PlanPeriod.BIYEARLY
                    ? '2-year plan save 40% '
                    : 'yearly plan save 20% '}{' '}
                  and get more out of Codeanywhere.
                </div>
                <div className="ant-row">
                  <div className="ant-col ant-col-24">
                    <button
                      type="submit"
                      className="checkout-button"
                      onClick={() => this.setState({ modalVisible: false })}
                    >
                      Stay on{' '}
                      {this.state.planPeriod === PlanPeriod.BIYEARLY
                        ? '2-year'
                        : 'yearly'}{' '}
                      plan and save
                    </button>
                  </div>
                  <div className="ant-col ant-col-24">
                    <button
                      type="submit"
                      className="checkout-button ghost"
                      onClick={() =>
                        this.setState({
                          modalVisible: false,
                          planPeriod: PlanPeriod.MONTHLY,
                        })
                      }
                    >
                      Go monthly
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="checkout-modal__footer"
                style={{
                  display:
                    !this.state.loadingSubscription &&
                    this.state.onFirstSubscription
                      ? 'block'
                      : 'none',
                }}
              >
                <div>
                  <b>★★★</b> First 7 days <b>★★★</b>
                </div>
                <div>Money Back Guarantee</div>
              </div>
              <button
                className="checkout-modal__close"
                onClick={() => this.setState({ modalVisible: false })}
              >
                <img src={CloseIcon} alt="Close icon" />
              </button>
            </div>
          </div>
          <div className="checkout--steps-wrapper">
            <div
              className="checkout--step step-1"
              style={{
                display:
                  this.state.checkoutStep === CheckoutStep.STEP1
                    ? 'block'
                    : 'none',
              }}
            >
              <CheckoutStep1Container
                planPeriod={this.state.planPeriod}
                pricingPlans={this.state.pricingPlans}
                selectedPlan={this.state.selectedPlan}
                teamAccounts={this.state.addons.multipleaccounts}
                onTeamAccountsChange={multipleaccounts =>
                  this.setState({
                    addons: { ...this.state.addons, multipleaccounts },
                  })
                }
                onActivePlanChange={activePlanCode => {
                  const activePlan = this.state.pricingPlans.find(
                    pp => pp.code === activePlanCode
                  )

                  if (activePlan) {
                    this.setState({ selectedPlan: activePlan })

                    if (activePlan.code.includes('basic')) {
                      this.setState({
                        addons: {
                          ...this.state.addons,
                          additionalcontainer: 0,
                          devboxalwayson: 0,
                          multipleaccounts: 0,
                        },
                      })
                    } else if (activePlan.code.includes('premium')) {
                      this.setState({
                        addons: {
                          ...this.state.addons,
                          extra15remote: 0,
                        },
                      })
                    }
                  }
                }}
                onPlanPeriodChange={planPeriod => {
                  if (
                    !this.state.firstClickMonthly &&
                    planPeriod === PlanPeriod.MONTHLY
                  ) {
                    this.setState({
                      firstClickMonthly: true,
                      modalVisible: true,
                    })
                  } else {
                    this.setState({ planPeriod })
                  }
                }}
                goToAddons={this.goToAddons}
                coupon={this.state.coupon}
                couponActive={this.state.couponActive}
                couponCode={this.state.couponCode}
                couponLoading={this.state.couponLoading}
                onCouponActiveChange={this.handleCouponActiveChange}
                onCouponApply={this.handleCouponApply}
                onCouponCodeChange={couponCode => this.setState({ couponCode })}
                subscription={this.state.subscription}
                loadingSubscription={this.state.loadingSubscription}
                onFirstSubscription={this.state.onFirstSubscription}
              />
            </div>
            <div
              className="checkout--step step-2"
              style={{
                display:
                  this.state.checkoutStep === CheckoutStep.STEP2
                    ? 'block'
                    : 'none',
              }}
            >
              <CheckoutStep2Container
                addons={this.state.addons}
                selectedPlan={this.state.selectedPlan}
                planPeriod={this.state.planPeriod}
                onAddonNumberChange={this.handleAddonNumberChange}
                onContinueToPaymentClick={this.handleContinueToPayment}
                coupon={this.state.coupon}
                couponActive={this.state.couponActive}
                couponCode={this.state.couponCode}
                couponLoading={this.state.couponLoading}
                onCouponActiveChange={this.handleCouponActiveChange}
                onCouponApply={this.handleCouponApply}
                onCouponCodeChange={couponCode => this.setState({ couponCode })}
                onUpdateToStandardClick={() => {
                  this.setState({
                    checkoutStep: CheckoutStep.STEP1,
                    selectedPlan: this.state.pricingPlans.find(p =>
                      p.code.includes('standard')
                    )!,
                  })
                }}
                subscription={this.state.subscription}
                loadingSubscription={this.state.loadingSubscription}
              />
            </div>
            <div
              className="checkout--step step-3"
              style={{
                display:
                  this.state.checkoutStep === CheckoutStep.STEP3
                    ? 'block'
                    : 'none',
              }}
            >
              <CheckoutStep3Container
                paymentMethod={this.state.paymentMethod}
                planPeriod={this.state.planPeriod}
                selectedPlan={this.state.selectedPlan}
                addons={this.state.addons}
                hasBillingInformation={this.state.hasBillingInformation}
                onAddonNumberChange={this.handleAddonNumberChange}
                onPaymentMethodChange={paymentMethod => {
                  if (paymentMethod === PaymentMethod.APPLE_PAY) {
                    this.setupApplePay()
                  }

                  this.setState({ paymentMethod })
                }}
                billingInfo={this.state.billingInfo}
                validation={this.state.validation}
                form={this.form}
                additionalFieldsActive={this.state.additionalFieldsActive}
                setRecurly={recurly => (this.recurly = recurly)}
                onBillingInfoChange={billingInfo =>
                  this.setState({ billingInfo })
                }
                onAdditionalFieldsChange={additionalFieldsActive =>
                  this.setState({ additionalFieldsActive })
                }
                onConfirm={this.handleConfirmSubscription}
                getAmazonPayToken={this.getAmazonPayToken}
                coupon={this.state.coupon}
                couponActive={this.state.couponActive}
                couponCode={this.state.couponCode}
                couponLoading={this.state.couponLoading}
                onCouponActiveChange={this.handleCouponActiveChange}
                onCouponApply={this.handleCouponApply}
                onCouponCodeChange={couponCode => this.setState({ couponCode })}
                creditInCents={this.state.creditInCents}
                subscription={this.state.subscription}
                loadingSubscription={this.state.loadingSubscription}
                refundPeriodExpired={this.state.refundPeriodExpired}
                onFirstSubscription={this.state.onFirstSubscription}
                creatingSubscription={this.state.creatingSubscription}
                displayAmazonPayButton={this.state.displayAmazonPayButton}
                displayAmazonPayNow={this.state.displayAmazonPayNow}
                disableAmazonPayNow={this.state.disableAmazonPayNow}
                loadingPaymentConfirm={this.state.loadingPaymentConfirm}
                applePayReady={true}
                onTotalChange={async total => {
                  this.checkoutTotal = total

                  if (this.state.paymentMethod !== PaymentMethod.APPLE_PAY) {
                    return
                  }

                  this.setupApplePay()
                }}
              />
              <div className="ant-row">
                <div className="ant-col ant-col-24">
                  <div
                    onClick={() =>
                      this.setState({ checkoutStep: CheckoutStep.STEP1 })
                    }
                  >
                    {'<'} Back to plans
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ant-row">
            <div className="ant-col ant-col-24">
              <div className="checkout__footer">
                <div className="billing-description">
                  {this.state.loadingSubscription ? (
                    <></>
                  ) : this.state.subscription ? (
                    <>
                      *After your current term expires, your subscription will
                      be automatically renewed for an additional{' '}
                      {planPeriodText} term and you will be charged at the
                      <a
                        href="https://codeanywhere.com/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        then-applicable renewal price.
                      </a>{' '}
                      The price is subject to change, but we will always send
                      you a notification email prior to changing.{' '}
                      <a
                        href="https://codeanywhere.com/tos"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                      {'. '}
                      Price may differ slightly based on execution time.
                    </>
                  ) : (
                    <>
                      *The price is valid for the first term of your
                      subscription. After the first term, your subscription will
                      be automatically renewed for an additional{' '}
                      {planPeriodText} term and you will be charged at the
                      <a
                        href="https://codeanywhere.com/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        then-applicable renewal price.
                      </a>{' '}
                      The price is subject to change, but we will always send
                      you a notification email prior to changing.{' '}
                      <a
                        href="https://codeanywhere.com/tos"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </>
                  )}
                </div>
                <div className="billing-description checkout-footer__bottom">
                  <span>Copyright © 2021 Codeanywhere.com</span>
                  <a href="mailto:support@codeanywhere.com">
                    support@codeanywhere.com
                  </a>
                  <a
                    href="https://codeanywhere.com/tos"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private async getAccountDetails() {
    try {
      const accountDetails = await this.props.apiClient.getAccountDetails()

      TagManager.dataLayer({
        dataLayer: {
          event: 'openCheckoutScreen',
          userId: accountDetails.user_id,
          emailHash: String(sha256(this.state.email)),
        },
      })

      this.setState({
        billingInfo: {
          ...this.state.billingInfo,
          firstName: accountDetails.firstname,
          lastName: accountDetails.lastname,
        },
        userId: accountDetails.user_id,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your account info',
      })
    }
  }

  private async getBillingInfo() {
    try {
      const billingInfo = await this.props.apiClient.getBillingInfo()

      if (billingInfo.address) {
        this.setState({
          billingInfo: {
            ...this.state.billingInfo,
            postalCode: billingInfo.address.postalCode || '',
            city: billingInfo.address.city || '',
            country: billingInfo.address.country || '',
            vatNumber: billingInfo.vatNumber || '',
            company: billingInfo.company || '',
            firstSix: billingInfo.first_six,
            lastFour: billingInfo.last_four,
            month: billingInfo.month,
            year: billingInfo.year,
            paypalAgreement: billingInfo.paypalAgreement,
            amazonPayAgreement: billingInfo.amazonPayAgreement,
            type: billingInfo.type,
          },
          hasBillingInformation: true,
          paymentMethod: PaymentMethod.SAVED,
        })
      }
    } catch (e) {
      // Silently fail
      this.setState({
        hasBillingInformation: false,
        paymentMethod: PaymentMethod.CARD,
      })
    }
  }

  private async generateCouponForAffiliateCustomers() {
    const cookie = parseCookie(document.cookie)

    //abort if user is not an affiliate customer
    if (!cookie.tap_vid) {
      return
    }

    this.setState({
      couponLoading: true,
    })

    try {
      const couponCode =
        await this.props.apiClient.createAffiliateCustomerCoupon()

      this.setState({
        couponCode,
        coupon: await this.props.recurlyClient.validateCoupon(
          couponCode,
          `v21a_${this.state.planPeriod}_${this.state.selectedPlan.code}`
        ),
        couponActive: true,
      })
    } catch (ex) {
      //silently fail
    } finally {
      this.setState({
        couponLoading: false,
      })
    }
  }

  private goToAddons = () => {
    this.setState({
      checkoutStep: CheckoutStep.STEP2,
    })
  }

  private handleContinueToPayment = () => {
    this.setState({
      checkoutStep: CheckoutStep.STEP3,
    })
  }

  private handleAddonNumberChange = (addonName: AddonName, newVal: number) => {
    let addons = this.state.addons
    addons[addonName] = isNaN(newVal) ? 0 : newVal

    this.setState({
      addons,
    })
  }

  private onBreadcrumbClick = (checkoutStep: CheckoutStep) => {
    this.setState({
      checkoutStep,
    })
  }

  private handleConfirmSubscription = async (total: string) => {
    this.setState({
      creatingSubscription: true,
    })

    if (!this.recurly) {
      this.setState({
        creatingSubscription: false,
      })
      return
    }

    let planName =
      this.state.planPeriod === PlanPeriod.MONTHLY
        ? 'Monthly '
        : this.state.planPeriod === PlanPeriod.YEARLY
        ? 'Yearly '
        : '2-year '
    planName += this.state.selectedPlan ? this.state.selectedPlan.name : ''
    planName += ' Plan'

    if (this.state.paymentMethod === PaymentMethod.CARD) {
      if (!(await this.updateCreditCardBillingInfo())) {
        return
      }
    } else if (this.state.paymentMethod !== PaymentMethod.SAVED) {
      const token = await (async () => {
        switch (this.state.paymentMethod) {
          case PaymentMethod.PAY_PAL:
            return await this.getPayPalToken(total, planName)
          case PaymentMethod.APPLE_PAY:
            return await this.getApplePayToken(total, planName)
          case PaymentMethod.AMAZON_PAY:
            return this.state.amazonPayToken
          case PaymentMethod.VENMO:
            return await this.getVenmoToken(total, planName)
          default:
            return null
        }
      })()

      //cancel payment flow
      if (!token) {
        return
      }

      try {
        await this.props.apiClient.updateBillingInfo(token)
        this.setState({
          validation: {
            errors: {},
            valid: true,
          },
        })
        this.getBillingInfo()
      } catch (e) {
        notification.error({
          message: e,
        })
        this.setState({
          creatingSubscription: false,
        })
        return
      }
    }

    await this.createOrUpdateSubscription(total)
  }

  private createOrUpdateSubscription = async (total: string) => {
    try {
      let planCode = this.state.planPeriod.toString() + '_'

      planCode += this.state.selectedPlan ? this.state.selectedPlan.code : ''

      let action = this.state.action

      if (this.state.subscription) {
        if (this.state.subscription.plan_code.includes(planCode)) {
          action = 'updateaddons'
        } else if (
          ComparePlansNew(
            this.state.subscription.plan_code,
            planCode as PricingPlanCode
          ) === 1
        ) {
          action = 'downgrade'
        } else {
          action = 'upgrade'
        }
      }

      const planPeriodText =
        this.state.planPeriod === PlanPeriod.MONTHLY
          ? 'monthly'
          : this.state.planPeriod === PlanPeriod.YEARLY
          ? 'yearly'
          : 'biyearly'

      if (action === 'create') {
        await this.props.apiClient.createSubscription(
          planCode,
          this.state.addons,
          this.state.couponCode
        )

        TagManager.dataLayer({
          dataLayer: {
            event: 'subscriptionCreated',
            conversionValue: parseFloat(total),
            selectedPlan: `${
              this.state.selectedPlan
                ? this.state.selectedPlan.name
                : 'ERROR - Plan not selected'
            }-${planPeriodText}`,
            userId: this.state.userId,
            emailHash: String(sha256(this.state.email)),
          },
        })
      } else {
        await this.props.apiClient.updateSubscription(
          planCode,
          this.state.addons,
          action
        )

        TagManager.dataLayer({
          dataLayer: {
            event: 'subscriptionUpdated',
            conversionValue: parseFloat(total),
            selectedPlan: `${
              this.state.selectedPlan
                ? this.state.selectedPlan.name
                : 'ERROR - Plan not selected'
            }-${planPeriodText}`,
            userId: this.state.userId,
            emailHash: String(sha256(this.state.email)),
          },
        })
      }

      notification.success({
        message: 'Your subscription has been updated successfully.',
      })

      setTimeout(() => window.location.replace('/'), 1000 * 2)
    } catch (e) {
      notification.error({
        message:
          'An error occurred while creating your subscription. Please contact our support.',
      })
    } finally {
      this.setState({
        creatingSubscription: false,
      })
    }
  }

  private updateCreditCardBillingInfo = async () => {
    let { company, vatNumber, address, city, country, ...billingInfo } = {
      ...this.state.billingInfo,
    }

    const validation = Validate({ ...billingInfo })

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill out your billing information')
      this.setState({
        creatingSubscription: false,
      })
      return false
    }

    this.setState({
      loadingPaymentConfirm: true,
    })

    try {
      await new Promise<void>((resolve, reject) => {
        if (!this.recurly) {
          reject('Payment error')
          return
        }

        this.recurly.token(this.form.current!, async (err, token) => {
          if (err) {
            reject('Make sure your credit card info is correct')
            return
          } else {
            try {
              await this.props.apiClient.updateBillingInfo(token.id)
              this.setState({
                validation: {
                  errors: {},
                  valid: true,
                },
              })
              this.getBillingInfo()
              resolve()
            } catch (e) {
              reject(e)
            }
          }
        })
      })
    } catch (ex) {
      notification.error({
        message: 'Error processing request',
        description: String(ex),
      })
      this.setState({
        creatingSubscription: false,
      })
      return false
    } finally {
      this.setState({
        loadingPaymentConfirm: false,
      })
    }

    return true
  }

  private getPayPalToken = async (total: string, planName: string) => {
    if (!this.recurly) {
      this.setState({
        creatingSubscription: false,
      })
      return null
    }

    const payPal = this.recurly.PayPal({
      display: {
        displayName: planName,
      },
    })

    const promise = new Promise<string>((resolve, reject) => {
      payPal.on('token', (token: { id: string }) => {
        resolve(token.id)
      })

      payPal.on('error', error => {
        reject(error)
      })

      payPal.on('cancel', () => {
        resolve('CANCEL')
      })
    })

    payPal.start()
    this.setState({
      loadingPaymentConfirm: true,
    })

    try {
      const tokenOrCancel = await promise

      if (tokenOrCancel === 'CANCEL') {
        notification.info({
          message:
            'It appears you have canceled the payment through PayPal. Please try again or select a different payment method.',
        })
        this.setState({
          creatingSubscription: false,
        })

        return null
      }
      return tokenOrCancel
    } catch (e) {
      const errorDescription = e.cause.message
        ? e.cause.message
        : 'Something went wrong!'

      notification.error({
        message: 'PayPal error',
        description: errorDescription,
      })
      this.setState({
        creatingSubscription: false,
      })
      return null
    } finally {
      this.setState({
        loadingPaymentConfirm: false,
      })
    }
  }

  private getApplePayToken = async (total: string, planName: string) => {
    if (!this.recurly || !this.applePay) {
      this.setState({
        creatingSubscription: false,
      })
      return null
    }

    this.applePay.begin()
    this.setState({
      loadingPaymentConfirm: true,
    })

    const promise = new Promise<string>((resolve, reject) => {
      if (!this.applePay) {
        return null
      }

      this.applePay.on('token', (token: { id: string }) => {
        resolve(token.id)
      })

      this.applePay.on('error', error => {
        reject(error)
      })

      this.applePay.on('cancel', cancel => {
        resolve('CANCEL')
      })
    })

    try {
      const tokenOrCancel = await promise

      if (tokenOrCancel === 'CANCEL') {
        notification.info({
          message:
            'It appears you have canceled the payment through Apple Pay. Please try again or select a different payment method.',
        })
        this.setState({
          creatingSubscription: false,
        })
        return null
      }
      return tokenOrCancel
    } catch (e) {
      const errorDescription = e.cause.message
        ? e.cause.message
        : 'Something went wrong!'

      notification.error({
        message: 'Apple Pay error',
        description: errorDescription,
      })
      this.setState({
        creatingSubscription: false,
      })
      return null
    } finally {
      this.setState({
        loadingPaymentConfirm: false,
      })
    }
  }

  private getAmazonPayToken = async () => {
    const promise = new Promise<string>((resolve, reject) => {
      this.payWithAmazon.on('login', () => {
        this.setState({
          displayAmazonPayButton: false,
          displayAmazonPayNow: true,
        })
      })

      this.payWithAmazon.on(
        'change',
        (token: { consent: boolean; id: string; error: string }) => {
          if (token.id) {
            resolve(token.id)
          }
          if (token.consent) {
            this.setState({
              disableAmazonPayNow: false,
            })
          } else {
            this.setState({
              disableAmazonPayNow: true,
            })
          }
        }
      )
    })

    try {
      const amazonPayToken = await promise
      this.setState({
        amazonPayToken,
      })
    } catch (e) {
      notification.error({
        message: 'Amazon Pay error',
        description: e.cause.message,
      })
      this.setState({
        creatingSubscription: false,
        amazonPayToken: null,
      })
    }
  }

  private getVenmoToken = async (total: string, planName: string) => {
    if (!this.recurly) {
      this.setState({
        creatingSubscription: false,
      })
      return null
    }

    // @ts-ignore
    const venmo = window.recurly.Venmo({
      braintree: {
        clientAuthorization: process.env.REACT_APP_BRAINTREE_CLIENT_KEY,
      },
    })

    const promise = new Promise<string>((resolve, reject) => {
      venmo.on('token', (token: { id: string }) => {
        resolve(token.id)
      })

      venmo.on('error', (error: any) => {
        reject(error)
      })

      venmo.on('cancel', () => {
        resolve('CANCEL')
      })
    })

    await new Promise<void>(resolve => {
      venmo.on('ready', () => {
        resolve()
      })
    })
    venmo.start()

    this.setState({
      loadingPaymentConfirm: true,
    })

    try {
      const tokenOrCancel = await promise

      if (tokenOrCancel === 'CANCEL') {
        notification.info({
          message:
            'It appears you have canceled the payment through Venmo. Please try again or select a different payment method.',
        })
        this.setState({
          creatingSubscription: false,
        })
        return null
      }
      return tokenOrCancel
    } catch (e) {
      const errorDescription = e.cause.message
        ? e.cause.message
        : 'Something went wrong!'

      notification.error({
        message: 'Venmo error',
        description: errorDescription,
      })
      this.setState({
        creatingSubscription: false,
      })
      return null
    } finally {
      this.setState({
        loadingPaymentConfirm: false,
      })
    }
  }

  private async getAccountSubscription() {
    try {
      const subscription = await this.props.apiClient.getAccountSubscription(
        true
      )
      const onFirstSubscription =
        (await this.props.apiClient.getAllAccountSubscriptions()).length === 1

      let refundPeriodExpired = true
      if (subscription !== null) {
        const refundPeriodExpiration = moment(subscription.activated_at).add(
          7,
          'day'
        )

        refundPeriodExpired =
          moment().diff(refundPeriodExpiration, 'minute') > 0
      }

      const addons: SubscriptionAddons = {
        additionalcontainer: 0,
        multipleaccounts: 0,
        extra15domains: 0,
        extra15remote: 0,
        devboxalwayson: 0,
      }

      let planPeriod = PlanPeriod.BIYEARLY
      let selectedPlan = PRICING_PLANS_NEW.find(p =>
        p.code.includes('standard')
      )!

      if (subscription) {
        subscription.subscription_add_ons.subscription_add_on.forEach(addon => {
          addons[addon.add_on_code as AddonName] = parseInt(addon.quantity)

          planPeriod = subscription.plan_code.includes('_m_')
            ? PlanPeriod.MONTHLY
            : subscription.plan_code.includes('_y_')
            ? PlanPeriod.YEARLY
            : PlanPeriod.BIYEARLY
          selectedPlan = subscription.plan_code.includes('_premium_')
            ? PRICING_PLANS_NEW.find(p => p.code.includes('premium'))!
            : subscription.plan_code.includes('_basic_')
            ? PRICING_PLANS_NEW.find(p => p.code.includes('basic'))!
            : PRICING_PLANS_NEW.find(p => p.code.includes('standard'))!
        })
      }

      //preselect user plan period if there are no plan query parameters
      const query = parseQs(window.location.search.slice(1))
      if (!query['plancode'] && !query['period']) {
        this.setState({
          subscription,
          loadingSubscription: false,
          refundPeriodExpired,
          action: subscription ? 'update' : 'create',
          addons,
          planPeriod,
          selectedPlan,
          onFirstSubscription,
        })
      } else {
        this.setState({
          subscription,
          loadingSubscription: false,
          refundPeriodExpired,
          action: subscription ? 'update' : 'create',
          addons,
          onFirstSubscription,
        })
      }
    } catch (ex) {
      notification.error({
        message: 'An error occurred while getting your subscription',
      })
    }
  }

  private handleCouponApply = async (couponCode?: string) => {
    this.setState({
      couponLoading: true,
    })

    if (couponCode !== undefined) {
      try {
        this.setState({
          couponCode,
          coupon: await this.props.recurlyClient.validateCoupon(
            couponCode,
            `v21a_${this.state.planPeriod}_${this.state.selectedPlan.code}`
          ),
        })
      } catch (ex) {
        notification.error({
          message: 'An error occured while validating the coupon',
        })
      } finally {
        this.setState({
          couponLoading: false,
        })
      }
    } else {
      try {
        this.setState({
          coupon: await this.props.recurlyClient.validateCoupon(
            this.state.couponCode,
            `v21a_${this.state.planPeriod}_${this.state.selectedPlan.code}`
          ),
        })
      } catch (ex) {
        notification.error({
          message: 'An error occured while validating the coupon',
        })
      } finally {
        this.setState({
          couponLoading: false,
        })
      }
    }
  }

  private handleCouponActiveChange = (couponActive: boolean) => {
    if (!couponActive) {
      this.setState({
        couponActive,
        coupon: null,
      })
    } else {
      this.setState({ couponActive })
    }
  }

  private async getAccountCredits() {
    try {
      const accountBalance =
        await this.props.apiClient.getAccountBalanceInCents()
      this.setState({
        creditInCents: accountBalance < 0 ? -accountBalance : 0,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your credit data',
      })
    }
  }

  private async setupApplePay() {
    this.setState({
      applePayReady: false,
    })

    if (!this.recurly) {
      return
    }

    let planName =
      this.state.planPeriod === PlanPeriod.MONTHLY
        ? 'Monthly '
        : this.state.planPeriod === PlanPeriod.YEARLY
        ? 'Yearly '
        : '2-year '
    planName += this.state.selectedPlan ? this.state.selectedPlan.name : ''
    planName += ' Plan'

    await new Promise<void>(resolve => {
      if (!this.recurly) {
        resolve()
        return
      }

      this.applePay = this.recurly.ApplePay({
        country: 'US',
        currency: 'USD',
        label: planName,
        total: this.checkoutTotal,
      })

      this.applePay.ready(() => {
        resolve()
      })
    })

    this.setState({
      applePayReady: true,
    })
  }

  private createAmazonPayWidget = () => {
    if (document.getElementById('pay-with-amazon') !== null) {
      this.payWithAmazon = new PayWithAmazon({
        sellerId: String(process.env.REACT_APP_AMAZON_PAY_SELLER_ID), //merchant id
        clientId: String(process.env.REACT_APP_AMAZON_PAY_CLIENT_ID),
        production: true,
        button: { id: 'pay-with-amazon', color: 'DarkGray' },
        wallet: { id: 'wallet', width: 400 },
        consent: { id: 'consent', width: 400 },
        // region: 'eu',
      })
    } else {
      setTimeout(this.createAmazonPayWidget, 500)
    }
  }
}
