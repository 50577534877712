import React from 'react'
import { Button, notification } from 'antd'
import { ApiClient, ApiClientEvent } from '../api-client/interface/ApiClient'
import { NoContentBox } from '../components/NoContentBox'
import { NO_CONTENT } from '../helpers/image-imports/NoContent'
import { ShareApiClient } from '../api-client/adapters/ShareApiClient'
import { ShareCards } from '../components/ShareCards'
import { Share } from '@codeanywhere/collaboration-service-api-client'

type Props = {
  apiClient: ApiClient
  shareApiClient: ShareApiClient
}

type State = {
  loadingShares: boolean
  shares: Share[]
  currPage: number
  pageSize: number
  loadingShareId: string
}

export class SharesContainer extends React.Component<Props, State> {
  public state: State = {
    loadingShares: false,
    shares: [],
    currPage: 1,
    pageSize: 10,
    loadingShareId: '',
  }

  componentDidMount() {
    this.refreshSharesList()

    this.props.shareApiClient.on(ApiClientEvent.InvitedToShare, () => {
      this.refreshSharesList()
    })
  }

  render() {
    return (
      <>
        <NoContentBox
          // TODO: Image
          imageUrl={NO_CONTENT['containers']}
          title="Looks like no users shared their Containers with you."
          description="Expand your network and start collaborating with other people!"
          visible={this.state.shares.length === 0 && !this.state.loadingShares}
          displayButton={false}
        />
        <div
          style={{
            display: this.state.shares.length ? 'block' : 'none',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={this.refreshSharesList}
              style={{
                marginBottom: '20px',
              }}
              loading={this.state.loadingShares}
            >
              Refresh
            </Button>
          </div>
          <ShareCards
            loadingShares={this.state.loadingShares}
            shares={this.state.shares}
            currPage={this.state.currPage}
            pageSize={this.state.pageSize}
            loadingShareId={this.state.loadingShareId}
            onCurrPageChange={currPage => {
              this.setState({
                currPage,
              })
            }}
            onPageSizeChange={pageSize => {
              this.setState({
                pageSize,
              })
            }}
            onLeaveShareClick={this.handleLeaveShare}
          />
        </div>
      </>
    )
  }

  private refreshSharesList = async () => {
    this.setState({
      loadingShares: true,
    })

    try {
      const fetchedShares = await this.props.shareApiClient.getUserShares()
      this.setState({
        shares: fetchedShares,
        currPage: parseInt(
          Math.ceil(fetchedShares.length / this.state.pageSize).toString()
        ),
      })
    } catch (ex) {
      notification.error({
        message: 'Error getting share list',
        description: String(ex),
      })
    } finally {
      this.setState({
        loadingShares: false,
      })
    }
  }

  private handleLeaveShare = async (shareId: string) => {
    this.setState({
      loadingShareId: shareId,
    })

    try {
      await this.props.shareApiClient.leaveShare(shareId)
      this.refreshSharesList()
    } catch (e) {
      notification.error({
        message: 'Error leaving share',
        description: e.toString(),
      })
    } finally {
      this.setState({
        loadingShareId: '',
      })
    }
  }
}
