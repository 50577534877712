import React from 'react'
import defaultUserImg from '../styles/img/user.svg'
import { ContainerTime } from './ContainerTime'

type Props = {
  firstname: string
  lastname: string
  email: string
  plan: string
  ram: number
  hdd: number
  imageUrl: string
  adminEmail: string
  containerTimeUsage?: number
  containerTimeLimit?: number
}

type State = {
  expanded: boolean
}

export class Profile extends React.PureComponent<Props, State> {
  public state: State = {
    expanded: false,
  }

  render() {
    return (
      <div
        className="profile-btn header__user"
        style={{
          backgroundImage: `url(${this.props.imageUrl || defaultUserImg})`,
          border: '2px solid #bbb0d0',
        }}
        onClick={() => {
          this.setState({ expanded: !this.state.expanded })
        }}
      >
        <div className={`profile ${this.state.expanded ? 'show' : ''}`}>
          <div className="profile-wrap">
            <div className="profile-top percentage-10">
              {this.props.adminEmail ? (
                <div
                  className="profile-top--admin-email"
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    backgroundColor: '#F2F0F6',
                    padding: '12px 0',
                    color: '#764593',
                  }}
                >
                  This account is managed by{' '}
                  <strong>{this.props.adminEmail}</strong>
                </div>
              ) : (
                <></>
              )}
              <div className="profile-padding"></div>
              <div className="profile-row">
                <div className="profile-details">
                  <div className="profile-details__username">
                    {this.props.firstname} {this.props.lastname}
                  </div>
                  <div className="profile-details__email">
                    {this.props.email}
                  </div>
                </div>
              </div>
              <div className="upgrade-wrap">
                <div className="profile-row">
                  <div className="upgrade-row">
                    <div className="subscription">
                      Subscription:
                      <span>{this.props.plan}</span>
                    </div>
                  </div>
                </div>
                <div className="profile-row">
                  <div className="upgrade-row">
                    <div className="upgrade-row__bot upgrade-row__bot--center">
                      <div className="upgrade-row__bot--left">{`${this.props.ram} MB`}</div>
                      <div className="upgrade-row__bot--right">
                        (RAM per container)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-row">
                  <div className="upgrade-row ">
                    <div className="upgrade-row__bot upgrade-row__bot--center">
                      <div className="upgrade-row__bot--left">{`${this.props.hdd} GB`}</div>
                      <div className="upgrade-row__bot--right">
                        (HDD per container)
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.containerTimeUsage !== undefined &&
                this.props.containerTimeLimit !== undefined &&
                this.props.plan.includes('Free') &&
                !this.props.plan.toLowerCase().includes('freelance') ? (
                  <div className="profile-row">
                    <ContainerTime
                      containerTimeUsage={this.props.containerTimeUsage}
                      containerTimeLimit={this.props.containerTimeLimit}
                    />
                  </div>
                ) : (
                  <></>
                )}

                <div
                  className="profile-row buttons-row"
                  style={{
                    justifyContent: 'space-between',
                    marginTop: '25px',
                  }}
                >
                  <a
                    className="btn btn--purple dialog-upgrade"
                    href="/checkout"
                    target="_blank"
                    ref="noopener noreferrer"
                  >
                    Upgrade
                  </a>
                  <a href="https://codeanywhere.com/signout">
                    <div className="txt-button">
                      <div className="btn btn--transparent1">Sign Out</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
