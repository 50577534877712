import React from 'react'
import { Table } from 'antd'
import { Invoice } from '../api-client/interface/Invoice'
import Column from 'antd/lib/table/Column'
import moment from 'moment'

type Props = {
  invoiceList: Invoice[]
  onDownloadPDF: (invoiceId: string) => Promise<void>
}

type State = {
  loading: boolean
}

type TableRow = Invoice & {
  key: string
}

export class Invoices extends React.PureComponent<Props, State> {
  render() {
    const tableData: TableRow[] = this.props.invoiceList.map(invoice => {
      return {
        ...invoice,
        key: invoice.id,
      }
    })

    return (
      <div className="card-wrap">
        <h1>Invoices</h1>
        <div className="card" style={{ padding: '0' }}>
          <Table
            dataSource={tableData}
            pagination={{
              defaultPageSize: 20,
              style: {
                marginRight: '10px',
              },
            }}
          >
            <Column
              title="INVOICE #"
              dataIndex="number"
              render={(number: string) => {
                return (
                  <span
                    onClick={() => this.props.onDownloadPDF(number)}
                    className="invoice--pdf"
                  >
                    {number}
                  </span>
                )
              }}
              align="center"
            />
            <Column
              title="STATUS"
              dataIndex="status"
              render={(status: string) => <span>{status}</span>}
              align="center"
            />
            <Column
              title="BILLED ON"
              dataIndex="created"
              render={(created: string) => {
                return <span>{moment(created).format('MMM Do YYYY')}</span>
              }}
              align="center"
            />
            <Column
              title="TOTAL"
              dataIndex="total"
              render={(total: string) => {
                return <span>${(parseInt(total) / 100).toFixed(2)}</span>
              }}
              align="center"
            />
          </Table>
        </div>
      </div>
    )
  }
}
