import { notification } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { RecurlyClient } from '../api-client/interface/RecurlyClient'
import {
  AddonName,
  ChildAccount,
  SubscriptionAddons,
} from '../api-client/interface/Subscription'
import { BillingInformationContainer } from '../containers/BillingInformationContainer'
import { InvoicesContainer } from '../containers/InvoicesContainer'
import {
  AddonTableRow,
  SubscriptionAddonsContainer,
} from '../containers/SubscriptionAddonsContainer'
import { SubscriptionInformationContainer } from '../containers/SubscriptionInformationContainer'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  recurlyClient: RecurlyClient
  setNavigationTitle: (title: string) => void
  userId: number
  email: string
}

type State = {
  loading: boolean
  childAccount: boolean
  adminEmail: string
  assigned: SubscriptionAddons
  multipleAccounts: ChildAccount[]
  addons: SubscriptionAddons
  addonsLoading: boolean
  addonColumns: ColumnProps<AddonTableRow>[]
}

export class BillingPage extends React.Component<Props, State> {
  public state: State = {
    loading: true,
    childAccount: false,
    adminEmail: '',
    assigned: {
      devboxalwayson: 0,
      additionalcontainer: 0,
      multipleaccounts: 0,
      extra15domains: 0,
      extra15remote: 0,
    },
    multipleAccounts: [],
    addons: {
      devboxalwayson: 0,
      additionalcontainer: 0,
      multipleaccounts: 0,
      extra15domains: 0,
      extra15remote: 0,
    },
    addonsLoading: false,
    addonColumns: [
      {
        title: 'Addon name',
        dataIndex: 'name',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'center',
      },
    ],
  }

  componentDidMount() {
    this.getIsChild()
    this.props.setNavigationTitle('Billing')

    TagManager.dataLayer({
      dataLayer: {
        event: 'openBillingScreen',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return (
      <div className="main billing show-flex">
        {this.renderBillingInfo()}
        <div
          className="billing-managed"
          style={{
            display:
              this.state.childAccount && !this.state.loading ? 'block' : 'none',
          }}
        >
          Your billing is managed by your team, please contact your team
          administrator {this.state.adminEmail}.
        </div>
      </div>
    )
  }

  private renderBillingInfo() {
    if (!this.state.loading && !this.state.childAccount) {
      return (
        <>
          <div className="main__col2">
            <SubscriptionInformationContainer
              apiClient={this.props.apiClient}
              onCreateSubscription={() => {
                this.getSubscriptionAddons()
                this.getMultipleAccountList()
              }}
              userId={this.props.userId}
              email={this.props.email}
            />
            <BillingInformationContainer apiClient={this.props.apiClient} />
            <InvoicesContainer apiClient={this.props.apiClient} />
          </div>
          <div
            className="main__col2"
            style={{
              display:
                this.state.childAccount || this.state.loading
                  ? 'none'
                  : 'block',
            }}
          >
            <SubscriptionAddonsContainer
              apiClient={this.props.apiClient}
              assigned={this.state.assigned}
              multipleAccounts={this.state.multipleAccounts}
              addons={this.state.addons}
              columns={this.state.addonColumns}
              loading={this.state.addonsLoading}
            />
          </div>
        </>
      )
    }

    return <></>
  }

  private async getIsChild() {
    try {
      const account = await this.props.apiClient.getAccountDetails()

      this.setState({
        childAccount: account.child_account,
        adminEmail: account.admin_email || '',
        loading: false,
      })

      if (!account.child_account) {
        this.getMultipleAccountList()
        this.getSubscriptionAddons()
      }
    } catch (e) {
      notification.error({
        message: 'Error getting your account info',
      })
    }
  }

  private async getMultipleAccountList() {
    try {
      const accounts = (
        await this.props.apiClient.getMultipleAccounts()
      ).filter(acc => acc.deactivated !== 1)

      let assigned: { [x in AddonName]: number } = {
        devboxalwayson: 0,
        additionalcontainer: 0,
        multipleaccounts: accounts.length,
        extra15domains: 0,
        extra15remote: 0,
      }

      accounts.forEach(account => {
        if (account.addons) {
          account.addons.forEach(addon => {
            assigned[addon.addon_code as AddonName] += addon.quantity
          })
        }
      })

      this.setState({
        multipleAccounts: accounts.map(acc => {
          return {
            ...acc,
            key: acc.child.toString(),
          }
        }),
        assigned,
      })
    } catch (e) {
      // Silenty fail because the request rejects if there are no accounts
    }
  }

  private async getSubscriptionAddons() {
    this.setState({
      addonsLoading: true,
    })

    try {
      const subscription = await this.props.apiClient.getAccountSubscription()

      if (subscription) {
        if (subscription.subscription_add_ons.subscription_add_on.length) {
          let addons: { [x in AddonName]: number } = {
            devboxalwayson: 0,
            extra15domains: 0,
            extra15remote: 0,
            multipleaccounts: 0,
            additionalcontainer: 0,
          }

          subscription.subscription_add_ons.subscription_add_on.forEach(
            addon => {
              addons[addon.add_on_code as AddonName] = parseInt(addon.quantity)
            }
          )

          const newColumns: ColumnProps<AddonTableRow>[] = [
            {
              title: 'Addon name',
              dataIndex: 'name',
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              align: 'center',
            },
          ]

          if (addons.multipleaccounts > 0) {
            newColumns.push(
              {
                title: 'Assigned',
                dataIndex: 'assigned',
                align: 'center',
              },
              {
                title: 'Unassigned',
                align: 'center',
                render: (item: AddonTableRow) => {
                  return <span>{item.quantity - item.assigned}</span>
                },
              }
            )
          }

          this.setState({
            addons,
            addonColumns: newColumns,
          })
        }
      }
    } catch (e) {
      notification.error({
        message: 'Error getting subscription addon',
      })
    } finally {
      this.setState({
        addonsLoading: false,
      })
    }
  }
}
