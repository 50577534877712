import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { ContainersContainer } from '../containers/ContainersContainer'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  onCreateContainer: () => void
  onSelectedStackChange: () => void
  setNavigationTitle: (title: string) => void
  userId: number
  email: string
}

export class ContainersPage extends React.Component<Props> {
  componentDidMount() {
    this.props.setNavigationTitle('Containers')

    TagManager.dataLayer({
      dataLayer: {
        event: 'openContainersScreen',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return (
      <div className="main containers">
        <div
          className="container-list--wrapper"
          style={{ padding: '30px', backgroundColor: '#fff' }}
        >
          <ContainersContainer
            apiClient={this.props.apiClient}
            onCreateContainer={this.props.onCreateContainer}
            onSelectedStackChange={this.props.onSelectedStackChange}
          />
        </div>
      </div>
    )
  }
}
