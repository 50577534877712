import * as React from 'react'
import { Button, Modal } from 'antd'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  userId: number
  email: string

  username: string
  firstName: string

  submitSupportTicket: () => Promise<void>
  displayStep: boolean
  goToNextStep: () => void

  loading: boolean
}

type State = {
  modalVisible: boolean
}

export class CancelSupportStep extends React.Component<Props, State> {
  public state: State = {
    modalVisible: false,
  }

  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowSupportEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <div className="cancellation__plan">
          <h1 className="cancellation__plan-title">
            {this.props.firstName !== ''
              ? this.props.firstName
              : this.props.username}
            , need more help?
          </h1>
          <h2 className="cancellation__plan-subtitle">
            Have a dedicated support agent reach out to you.
          </h2>
        </div>
        <div className="cancellation-ilustration-wrapper">
          <div className="cancel-support-agent cancel-img"></div>
        </div>
        <div className="cancellation__plan-spacing"></div>
        <div className="cancellation__actions">
          <Button
            id="cancel-membership-support--button"
            className="cancellation__action cancellation__action--type-cancel"
            onClick={() => {
              document.querySelector('body')?.scrollTo(0, 0)
              this.props.goToNextStep()
            }}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-support--button"
            type="primary"
            className="cancellation__action cancellation__action--type-success"
            onClick={() => this.setState({ modalVisible: true })}
          >
            Contact me
          </Button>
        </div>
        <Modal
          title="Support ticket submission"
          visible={this.state.modalVisible}
          onOk={() => {
            this.props.submitSupportTicket()
            TagManager.dataLayer({
              dataLayer: {
                event: 'cancelFlowContactSupport',
                userId: this.props.userId,
                emailHash: String(sha256(this.props.email)),
              },
            })
          }}
          onCancel={() => this.setState({ modalVisible: false })}
          width={650}
          okText="Submit"
          confirmLoading={this.props.loading}
        >
          <div className="cancellation-step__modal-content">
            <div>
              Thank you! A dedicated support agent will contact you as soon as
              possible.
            </div>
            <div className="cancel-support-runner cancel-img cancel-modal-img"></div>
          </div>
        </Modal>
      </>
    ) : null
  }
}
