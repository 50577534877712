import React from 'react'
import CloseIcon from '../styles/img/close.svg'

type Props = {
  visible: boolean
  handleCloseBanner: () => void
}

export class OldEditorBanner extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className="old-editor--banner"
        style={{
          display: this.props.visible ? 'flex' : 'none',
        }}
      >
        <div></div>
        <div>
          Looking for the legacy Codeanywhere IDE?
          <button
            className="old-editor--banner-btn old-editor-open"
            onClick={() => {
              window.open('https://codeanywhere.com/editor', '_blank')
            }}
          >
            Open
          </button>
        </div>
        <div>
          <button
            className="old-editor--banner-btn old-editor-close"
            onClick={this.props.handleCloseBanner}
          >
            <img src={CloseIcon} alt="Close icon" />
          </button>
        </div>
      </div>
    )
  }
}
