import React from 'react'
import { SubscriptionInformation } from '../components/SubscriptionInformation'
import { Modal, notification } from 'antd'
import { PricingPlan } from '../api-client/interface/PricingPlan'
import { ApiClient } from '../api-client/interface/ApiClient'
import { CancelSubscriptionFeedback } from '../api-client/interface/Connection'
import { Subscription } from '../api-client/interface/Subscription'
import moment from 'moment'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  onCreateSubscription: () => void
  userId: number
  email: string
}

enum VisibleDialog {
  None,
  SelectPlan,
  CreateSubscription,
  CancelSubscription,
  ReactivateSubscription,
}

type State = {
  visibleDialog: VisibleDialog
  reloadCounter: number
  selectedPlan?: PricingPlan
  cancelSubscriptionFeedback: CancelSubscriptionFeedback
  subscription: Subscription | null
  loadingSubscription: boolean
  plan: string
  planCode: string
  adminEmail: string
  childAccount: boolean
  onTrial: boolean
  trialExpiration: string
  containerRam: number
  containerHdd: number
  userId: number
  containerTimeUsage?: number
  containerTimeLimit?: number
}

export class SubscriptionInformationContainer extends React.Component<Props> {
  public state: State = {
    visibleDialog: VisibleDialog.None,
    reloadCounter: 1,
    cancelSubscriptionFeedback: {
      reasons: [],
      comment: '',
    },
    subscription: null,
    plan: '',
    planCode: '',
    childAccount: false,
    adminEmail: '',
    onTrial: false,
    trialExpiration: '',
    loadingSubscription: true,
    containerRam: 0,
    containerHdd: 0,
    userId: -1,
  }

  componentDidMount() {
    this.getAccountDetails()
    this.getAccountSubscription()
  }

  render() {
    return (
      <>
        <SubscriptionInformation
          plan={this.state.plan}
          subscription={this.state.subscription}
          onTrial={this.state.onTrial}
          trialExpiration={this.state.trialExpiration}
          containerHdd={this.state.containerHdd}
          containerRam={this.state.containerRam}
          containerTimeLimit={this.state.containerTimeLimit}
          containerTimeUsage={this.state.containerTimeUsage}
          adminEmail={this.state.adminEmail}
          childAccount={this.state.childAccount}
          loading={this.state.loadingSubscription}
          onUpgradeClick={this.handleUpgradeClick}
          onReactivateClick={this.handleReactivateClick}
        />
        <Modal
          title="Reactive subscription"
          visible={
            this.state.visibleDialog === VisibleDialog.ReactivateSubscription
          }
          onCancel={() => {
            this.setState({
              visibleDialog: VisibleDialog.None,
            })
          }}
          onOk={this.handleConfirmReactivate}
        >
          <div>
            You will be charged once your current period expires on{' '}
            {this.state.subscription
              ? moment(this.state.subscription.current_period_ends_at).format(
                  'MMM Do YYYY'
                )
              : ''}
          </div>
        </Modal>
      </>
    )
  }

  private async getAccountDetails() {
    try {
      const accountDetails = await this.props.apiClient.getAccountDetails()

      this.setState({
        containerTimeUsage: accountDetails.usage.containerTime,
        containerTimeLimit: accountDetails.limits.containerTime,
        containerRam: parseInt(accountDetails.limits['devbox-ram']),
        containerHdd: parseInt(accountDetails.limits['devbox-hdd']),
        childAccount: accountDetails.child_account,
        plan: accountDetails.planname,
        adminEmail: accountDetails.admin_email || '',
        userId: accountDetails.user_id,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your account info',
      })
    }
  }

  private async getAccountSubscription(forceRefresh?: boolean) {
    this.setState({
      loadingSubscription: true,
    })

    try {
      const account = await this.props.apiClient.getAccountDetails()

      if (account.child_account) {
        this.setState({
          loadingSubscription: false,
        })
        return
      }

      const subscription = await this.props.apiClient.getAccountSubscription(
        forceRefresh
      )

      const onTrial = account.trial

      const trialExpiration = moment(account.reg_date).add(7, 'day').toString()

      this.setState({
        subscription,
        plan: subscription ? subscription.plan_name : '',
        planCode: subscription ? subscription.plan_code : '',
        onTrial,
        trialExpiration: trialExpiration,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your subscription',
      })
    } finally {
      this.setState({
        loadingSubscription: false,
      })
    }
  }

  private handleReactivateClick = () => {
    this.setState({
      visibleDialog: VisibleDialog.ReactivateSubscription,
    })
  }

  private handleUpgradeClick = () => {
    const checkout = window.open('/checkout', '_blank')

    if (checkout) {
      checkout.onunload = () => {
        this.getAccountSubscription(true)
        this.props.onCreateSubscription()
      }
    }
  }

  private handleCancelSubscriptionClick = () => {
    this.setState({
      visibleDialog: VisibleDialog.CancelSubscription,
    })
  }

  private handleCloseDialog = () => {
    this.setState({
      visibleDialog: VisibleDialog.None,
      teamlicense: 0,
    })
  }

  private handleConfirmReactivate = async () => {
    try {
      const planId = this.state.subscription ? this.state.subscription.uuid : ''
      await this.props.apiClient.reactivateSubscription(planId)

      TagManager.dataLayer({
        dataLayer: {
          event: 'subscriptionReactivated',
          conversionValue: this.state.planCode
            ? this.state.planCode
            : 'ERROR - Not on a plan',
          userId: this.state.userId,
          emailHash: String(sha256(this.props.email)),
        },
      })

      this.setState({
        visibleDialog: VisibleDialog.None,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while reactivating your subscription',
      })
    }
  }
}
