import * as React from 'react'
import { ContainerConnection } from '../api-client/interface/Connection'
import { Button } from 'antd'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  userId: number
  email: string
  username: string
  firstName: string
  hasTwoYearPlan: boolean
  alwaysOnContainers: ContainerConnection[]
  displayStep: boolean
  goToNextStep: () => void
}

export class CancelWarningStep extends React.Component<Props> {
  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowWarningEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <h1 className="cancellation__title">
          {this.props.firstName !== ''
            ? this.props.firstName
            : this.props.username}
          , are you sure you want to end your membership?
        </h1>
        <div className="cancellation__bullets">
          <h2 className="cancellation__bullets-title">
            Save time by deploying a development environment in seconds
          </h2>
          <ul className="cancellation__bullets-list">
            <li className="cancellation__bullets-list-item">
              <span className="cancellation__bullets-list-icon"></span>
              Fully featured web-based code editor
            </li>
            <li className="cancellation__bullets-list-item">
              <span className="cancellation__bullets-list-icon"></span>
              Team up with your fellow developers and collaborate on your
              projects
            </li>
            <li className="cancellation__bullets-list-item">
              <span className="cancellation__bullets-list-icon"></span>
              Pre-installed language runtimes and tools for all popular
              programming languages
            </li>
            <li className="cancellation__bullets-list-item">
              <span className="cancellation__bullets-list-icon"></span>
              Keep all your data fully secure and hosted with us
            </li>
          </ul>
        </div>

        <div
          className="cancellation__bullets"
          style={{
            display: this.props.alwaysOnContainers.length ? 'block' : 'none',
          }}
        >
          <h2 className="cancellation__bullets-title">
            These Always-on containers will be shut down if you cancel your
            membership:
          </h2>
          <ul className="cancellation__bullets-list">
            {this.props.alwaysOnContainers.map(container => (
              <li
                key={container.id}
                className="cancellation__bullets-list-item"
              >
                <span>{container.name}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className=" cancellation__bullets cancellation__warning">
          <span className="cancellation__warning-icon">!</span>
          Warning: you will lose access to all benefits immediately
        </div>

        <div className="cancellation__actions">
          <Button
            id="cancel-membership-warning--button"
            className="cancellation__action cancellation__action--type-cancel"
            onClick={() => {
              document.querySelector('body')?.scrollTo(0, 0)
              this.props.goToNextStep()
            }}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-warning--button"
            type="primary"
            className="cancellation__action cancellation__action--type-success"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'cancelFlowKeep',
                  userId: this.props.userId,
                  emailHash: String(sha256(this.props.email)),
                },
              })
            }}
          >
            <a href="/" target="_self" rel="noopener noreferrer">
              Keep my membership
            </a>
          </Button>
        </div>
      </>
    ) : null
  }
}
