import { EventEmitter } from 'eventemitter3'
import { AuthProvider } from '../interface/AuthProvider'
import { ShareApi } from '@codeanywhere/collaboration-service-api-client'
import { ApiClientEvent } from '../interface/ApiClient'
import io, { Socket } from 'socket.io-client'
import { ShareNotification } from '../interface/ShareNotifications'
import { notification } from 'antd'

export class ShareApiClient extends EventEmitter {
  private shareApi: ShareApi
  public authProvider: AuthProvider

  private socket?: typeof Socket
  private readonly notificationSocketUrl: string

  constructor(params: {
    shareApi: ShareApi
    authProvider: AuthProvider
    notificationSocketUrl: string
  }) {
    super()
    this.shareApi = params.shareApi
    this.authProvider = params.authProvider
    this.notificationSocketUrl = params.notificationSocketUrl
  }

  public async setupSocket() {
    this.socket = io(this.notificationSocketUrl, {
      query: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    this.socket.on('notification', (data: unknown) => {
      if (
        Object.entries(data as any).find(
          entry =>
            entry[0] === 'event' && (entry[1] as string).includes('share')
        )
      ) {
        const notification = data as ShareNotification

        switch (notification.event) {
          case 'share:invited':
            if (notification.data) {
              this.emit(ApiClientEvent.InvitedToShare, {
                shareId: notification.data.shareId,
                shareLink: notification.data.shareLink,
                shareOwnerUsername: notification.data.shareOwnerUsername,
              })
            }
            break
          default:
            break
        }
      } else {
        // console.log('Unknown notification type', data)
      }
    })

    this.socket.on('error', (error: unknown) => {
      notification.error({
        message: 'Error connecting to notification socket',
      })
    })
  }

  public async getUserShares() {
    return (
      await this.shareApi.getShares({
        headers: {
          Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
        },
      })
    ).data
  }

  public async leaveShare(shareId: string) {
    await this.shareApi.leaveShare(shareId, {
      headers: {
        Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
      },
    })
  }
}
