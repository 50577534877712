import React from 'react'
import { message, notification } from 'antd'
import { UseRecurlyInstance } from '@recurly/react-recurly'
import { BillingInformation } from '../components/BillingInformation'
import { ApiClient } from '../api-client/interface/ApiClient'
import { BillingInfo } from '../api-client/interface/BillingInfo'
import { Validate, Validation } from '../helpers/validation/Validation'
import { BillingAgreementLabel } from '../components/BillingAgreementLabel'
import { BillingAgreementNotice } from '../components/BillingAgreementNotice'

type Props = {
  apiClient: ApiClient
}

type State = {
  billingInfo: BillingInfo
  expanded: boolean
  billingInfoEmpty: boolean
  validation: Validation
}

export class BillingInformationContainer extends React.Component<Props, State> {
  public state: State = {
    billingInfo: {
      firstName: '',
      lastName: '',
      company: '',
      vatNumber: '',
      address: {
        phone: '',
        street1: '',
        street2: '',
        city: '',
        region: '',
        postalCode: '',
        country: '',
      },
      type: '',
    },
    expanded: false,
    billingInfoEmpty: true,
    validation: {
      valid: true,
      errors: {},
    },
  }

  private form: React.RefObject<HTMLFormElement>

  constructor(props: Props, context: any) {
    super(props, context)
    this.form = React.createRef()
  }

  public componentDidMount() {
    this.getBillingInfo()
  }

  render() {
    return (
      <div className="card-wrap">
        <h1 id="billing_information">Billing Information</h1>
        <div className="card billing-information">
          <div className="card__section col">
            <div
              className={
                'card_section col' +
                (!this.state.expanded && this.state.billingInfoEmpty
                  ? ''
                  : ' hide')
              }
            >
              Please fill out your billing information
            </div>
            <div
              className={
                'billing-information__name' +
                (this.state.expanded || this.state.billingInfoEmpty
                  ? ' hide'
                  : '')
              }
            >
              <span>Name:</span>{' '}
              {this.state.billingInfo.firstName +
                ' ' +
                this.state.billingInfo.lastName}
            </div>

            {this.state.billingInfo.type !== 'credit_card' ? (
              <>
                <div
                  className={
                    'billing-information__name' +
                    (this.state.expanded || this.state.billingInfoEmpty
                      ? ' hide'
                      : '')
                  }
                >
                  <span>Payment method:</span>{' '}
                  {this.state.billingInfo.type === 'paypal'
                    ? 'PayPal'
                    : this.state.billingInfo.type === 'apple_pay'
                    ? 'Apple Pay'
                    : this.state.billingInfo.type === 'amazon'
                    ? 'Amazon Pay'
                    : 'Venmo'}
                </div>
                <div
                  className={
                    'billing-information__card' +
                    (this.state.expanded || this.state.billingInfoEmpty
                      ? ' hide'
                      : '')
                  }
                >
                  <BillingAgreementLabel {...this.state.billingInfo} />
                </div>
                <BillingAgreementNotice
                  {...this.state.billingInfo}
                  inCheckout={false}
                  expanded={this.state.expanded}
                  billingInfoEmpty={this.state.billingInfoEmpty}
                />
              </>
            ) : (
              <>
                <div
                  className={
                    'billing-information__name' +
                    (this.state.expanded || this.state.billingInfoEmpty
                      ? ' hide'
                      : '')
                  }
                >
                  <span>Billing address:</span> {this.getAddress()}
                </div>
                <div
                  className={
                    'billing-information__name' +
                    (this.state.expanded || this.state.billingInfoEmpty
                      ? ' hide'
                      : '')
                  }
                >
                  <span>Card:</span>{' '}
                  {`${this.state.billingInfo.first_six}******${this.state.billingInfo.last_four}`}
                </div>
                <div
                  className={
                    'billing-information__card' +
                    (this.state.expanded || this.state.billingInfoEmpty
                      ? ' hide'
                      : '')
                  }
                >
                  <span>Expiration date:</span>{' '}
                  {`${this.state.billingInfo.month}/${this.state.billingInfo.year}`}
                </div>
              </>
            )}
            <div
              className={
                'card__abs-bot row' + (this.state.expanded ? ' hide' : '')
              }
            >
              <button
                className="btn-icon"
                data-balloon-pos="up"
                data-balloon="Edit"
                id="billing"
                onClick={() => {
                  this.setState({
                    expanded: true,
                  })
                }}
              >
                <svg
                  height="100%"
                  viewBox="0 0 24 24"
                  width="100%"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                </svg>
              </button>
            </div>
            <div
              className={
                'billing-information__expand' +
                (this.state.expanded ? '' : ' hide')
              }
            >
              <BillingInformation
                billingInfo={this.state.billingInfo}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                form={this.form}
                onAddressInputChange={this.handleAddressInputChange}
                onBillingInfoInputChange={this.handleBillingInfoInputChange}
                onCountrySelectChange={this.handleCountrySelectChange}
                footerVisible={true}
                validation={this.state.validation}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  private handleBillingInfoInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const billingInfo: { [key: string]: string } = {}
    billingInfo[event.target.getAttribute('data-key')!] = event.target.value
    this.setState({
      billingInfo: {
        ...this.state.billingInfo,
        ...billingInfo,
      },
    })
  }

  private handleAddressInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const address: { [key: string]: string } = {}
    address[event.target.getAttribute('data-key')!] = event.target.value
    this.setState({
      billingInfo: {
        ...this.state.billingInfo,
        address: {
          ...this.state.billingInfo.address,
          ...address,
        },
      },
    })
  }

  private handleCountrySelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    this.setState({
      billingInfo: {
        ...this.state.billingInfo,
        address: {
          ...this.state.billingInfo.address,
          country: event.target.value,
        },
      },
    })
  }

  private async getBillingInfo() {
    try {
      this.setState({
        billingInfo: await this.props.apiClient.getBillingInfo(),
        billingInfoEmpty: false,
      })
    } catch (ex) {
      this.setState({
        billingInfoEmpty: true,
      })
    }
  }

  private handleSubmit = async (recurly: UseRecurlyInstance) => {
    let { address, ...billingInfo } = { ...this.state.billingInfo }

    const { company, vatNumber, ...billingInfoToValidate } = { ...billingInfo }

    const { ...addressToValidate } = { ...address }

    // Remove these fields from validation
    delete addressToValidate.street2
    delete addressToValidate.region
    delete addressToValidate.phone
    delete addressToValidate.street1
    delete addressToValidate.city

    const validation = Validate({
      ...billingInfoToValidate,
      ...addressToValidate,
    })

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill out the form')
      return
    }

    recurly.token(this.form.current!, async (err, token) => {
      if (err) {
        notification.error({
          message: 'Error processing request',
          description: 'Make sure your credit card info is correct',
        })
      } else {
        try {
          await this.props.apiClient.updateBillingInfo(token.id)
          this.setState({
            expanded: false,
            validation: {
              errors: {},
              valid: true,
            },
          })
          this.getBillingInfo()
        } catch (ex) {
          notification.error({
            message: 'Error processing request',
            description: String(ex),
          })
        }
      }
    })
  }

  private handleCancel = () => {
    this.setState({
      expanded: false,
      validation: {
        valid: true,
        errors: {},
      },
    })
  }

  private getAddress() {
    if (!this.state.billingInfo.address) {
      return ''
    }

    return (
      this.state.billingInfo.address.street1 +
      ', ' +
      this.state.billingInfo.address.city +
      ', ' +
      this.state.billingInfo.address.postalCode +
      ' ' +
      this.state.billingInfo.address.country
    )
  }
}
