import devboxAlwaywsOn from '../../styles/img/connection-types/devbox-alwaysOn.png'
import devbox from '../../styles/img/connection-types/devbox.png'
import dropbox from '../../styles/img/connection-types/dropbox.png'
import ftp from '../../styles/img/connection-types/ftp.png'
import gdrive from '../../styles/img/connection-types/gdrive.png'
import s3 from '../../styles/img/connection-types/s3.png'
import ssh from '../../styles/img/connection-types/ssh.png'
import onedriveconsumer from '../../styles/img/connection-types/onedriveconsumer.png'
import dodroplet from '../../styles/img/connection-types/do-droplet.png'

export const CONNECTION_TYPE: { [x: string]: string } = {
  'devbox-alwaysOn': devboxAlwaywsOn,
  devbox,
  dropbox,
  ftp,
  gdrive,
  s3,
  ssh,
  onedriveconsumer,
  'do-droplet': dodroplet,
}
