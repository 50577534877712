import { notification } from 'antd'
import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  DeleteAccount,
  OnDeleteAccountParams,
} from '../components/DeleteAccount'

type Props = {
  apiClient: ApiClient
}

export class DeleteAccountContainer extends React.Component<Props> {
  render() {
    return <DeleteAccount onDeleteAccount={this.handleDeleteAccount} />
  }

  private handleDeleteAccount = async (params: OnDeleteAccountParams) => {
    try {
      await this.props.apiClient.deleteAccount(params.confirmPassword)
      window.location.replace('https://codeanywhere.com/signout')
    } catch (e) {
      notification.error({
        message: 'An error occurred while deleting your account.',
        description: 'Please try again or contact support.',
      })
    }
  }
}
