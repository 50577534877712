import * as React from 'react'
import { Button, Input } from 'antd'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  userId: number
  email: string

  username: string
  firstName: string

  suggestions: string
  onSuggestionsChange: (suggestion: string) => void
  displayStep: boolean
  goToNextStep: () => void
}

type State = {
  noSuggestionAdded: boolean
}

export class CancelSuggestionsStep extends React.Component<Props, State> {
  public state: State = {
    noSuggestionAdded: false,
  }

  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowSuggestionsEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <div className="cancellation__plan">
          <h1 className="cancellation__plan-title">
            {this.props.firstName !== ''
              ? this.props.firstName
              : this.props.username}
            , what would you like to see in Codeanywhere?
          </h1>
          <h2 className="cancellation__plan-subtitle">
            We are constantly working on new features and would appreciate your
            feedback.
          </h2>
        </div>
        <div
          className="feedback__warning-comment cancellation__warning"
          style={{
            display: this.state.noSuggestionAdded ? 'block' : 'none',
          }}
        >
          Do you have any suggestions or features that you would like to see in
          Codeanywhere?
        </div>
        <div className="cancellation__plan-spacing"></div>
        <Input.TextArea
          value={this.props.suggestions}
          onChange={e => this.props.onSuggestionsChange(e.target.value)}
        ></Input.TextArea>
        <div className="cancellation__plan-spacing"></div>
        <div className="cancellation__actions">
          <Button
            id="cancel-membership-suggestions--button"
            className="cancellation__action cancellation__action--type-cancel"
            onClick={this.handleSuggestionSubmit}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-suggestions--button"
            type="primary"
            className="cancellation__action cancellation__action--type-success"
          >
            <a href="/" target="_self" rel="noopener noreferrer">
              Keep Membership
            </a>
          </Button>
        </div>
      </>
    ) : null
  }

  private handleSuggestionSubmit = async () => {
    let noSuggestionAdded = false

    if (this.props.suggestions === '') {
      noSuggestionAdded = true
    }

    if (noSuggestionAdded) {
      this.setState({
        noSuggestionAdded,
      })
    } else {
      document.querySelector('body')?.scrollTo(0, 0)
      TagManager.dataLayer({
        dataLayer: {
          event: 'cancelFlowSendSuggestions',
          userId: this.props.userId,
          emailHash: String(sha256(this.props.email)),
          suggestions: this.props.suggestions,
        },
      })
      this.props.goToNextStep()
    }
  }
}
