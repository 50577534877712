import React from 'react'
import {
  AccountInformation,
  OnSaveParams,
} from '../components/AccountInformation'
import { ApiClient } from '../api-client/interface/ApiClient'
import { notification } from 'antd'
import * as gravatar from 'gravatar'
import defaultImg from '../styles/img/user.svg'
import axios from 'axios'

type Props = {
  apiClient: ApiClient
}
type State = {
  accountId: number
  firstname: string
  lastname: string
  username: string
  email: string
  emailPasswordConfirm: string
  company: string
  imageUrl: string
  shouldSetInitialPassword: boolean
  verified: boolean
  resent: boolean
}

export class AccountInformationContainer extends React.Component<Props, State> {
  public state: State = {
    accountId: -1,
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    emailPasswordConfirm: '',
    company: '',
    imageUrl: '',
    shouldSetInitialPassword: false,
    verified: true,
    resent: false,
  }

  public componentDidMount() {
    this.getAccountDetails()
  }

  render() {
    return (
      <AccountInformation
        accountId={String(this.state.accountId)}
        username={this.state.username}
        firstname={this.state.firstname}
        lastname={this.state.lastname}
        company={this.state.company}
        email={this.state.email}
        imageUrl={this.state.imageUrl}
        verified={this.state.verified}
        resent={this.state.resent}
        onSave={this.handleSave}
        onResendVerificationClick={this.handleResendVerification}
      />
    )
  }

  private async getAccountDetails() {
    try {
      const details = await this.props.apiClient.getAccountDetails()

      let gravatarImage = ''
      try {
        await axios.get(
          gravatar.url(details.email, {
            size: '200',
            d: '404',
            protocol: 'https',
          })
        )
        gravatarImage = gravatar.url(details.email, {
          size: '200',
          d: '404',
          protocol: 'https',
        })
      } catch {
        gravatarImage = defaultImg
      }

      const imageUrl = details.imageurl || gravatarImage

      this.setState({
        accountId: details.user_id,
        company: details.company,
        email: details.email,
        firstname: details.firstname,
        lastname: details.lastname,
        username: details.username,
        imageUrl: imageUrl,
        verified: details.verified,
        shouldSetInitialPassword: details.promptSetInitialPassword
          ? true
          : false,
      })
    } catch (ex) {
      notification.error({
        message: 'Error getting account details',
        description: String(ex),
      })
    }
  }

  private handleSave = async (params: OnSaveParams) => {
    await this.props.apiClient.updateAccount({
      firstname: params.firstname,
      lastname: params.lastname,
      username: params.username,
      company: params.company,
    })

    if (params.email !== '') {
      await this.props.apiClient.changeEmail(
        params.email,
        params.emailPasswordConfirm
      )
    }

    this.getAccountDetails()
  }

  private handleResendVerification = async () => {
    try {
      await this.props.apiClient.resendVerificationEmail()
      this.setState({
        resent: true,
      })
    } catch (e) {
      notification.error({
        message: 'Could not send the email.',
        description: 'Please try again later.',
      })
    }
  }
}
