import { Checkbox, message, Modal, notification } from 'antd'
import React from 'react'
import { ContainerTableData } from '../components/Containers'
import {
  ContainerStacks,
  PredefinedFilter,
} from '../components/ContainerStacks'
import {
  ContainerStackTableData,
  ContainerStackType,
  tableDataTransform,
} from '../helpers/container-stacks/TableDataTransform'
import { parse as parseQs } from 'querystring'
import { CreateStackModal } from '../components/CreateStackModal'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  CreateCustomStack,
  CreateContainer,
} from '../api-client/interface/Connection'
import { Validate, Validation } from '../helpers/validation/Validation'
import { NoContentBox } from '../components/NoContentBox'
import { NO_CONTENT } from '../helpers/image-imports/NoContent'
import { Redirect } from 'react-router'
import { UpgradePlanDialog } from '../components/UpgradePlanDialog'
import questionIcon from '../styles/img/questionmark.svg'

type Props = {
  apiClient: ApiClient
}

type State = {
  createContainerDialogVisible: boolean
  newContainerName: string
  selectedStack: string
  newContainerAlwaysOn: boolean
  loading: boolean
  transformedStacks: ContainerStackTableData[]
  confirmCreateLoading: boolean
  containers: ContainerTableData[]
  newStack: CreateCustomStack
  selectedNewStackContainer: string
  createStackDialogVisible: boolean
  validation: Validation
  createStackValidation: Validation
  stackIdToDelete: string
  confirmDeleteDialogVisible: boolean
  redirectToContainers: boolean
  containerLimitDialogVisible: boolean
}

export class ContainerStacksContainer extends React.Component<Props, State> {
  public state: State = {
    createContainerDialogVisible: false,
    createStackDialogVisible: false,
    newContainerName: '',
    selectedStack: '',
    newContainerAlwaysOn: false,
    loading: false,
    transformedStacks: [],
    confirmCreateLoading: false,
    containers: [],
    newStack: {
      id: -1,
      name: '',
      description: '',
    },
    selectedNewStackContainer: '',
    validation: {
      valid: true,
      errors: {},
    },
    createStackValidation: {
      valid: true,
      errors: {},
    },
    stackIdToDelete: '',
    confirmDeleteDialogVisible: false,
    redirectToContainers: false,
    containerLimitDialogVisible: false,
  }

  componentDidMount() {
    this.getCustomStacks()
    this.getContainers()

    const selectedNewStackContainer = String(
      parseQs(window.location.search.slice(1))['fromContainer']
    )

    if (
      selectedNewStackContainer &&
      selectedNewStackContainer !== 'undefined'
    ) {
      this.setState({
        selectedNewStackContainer,
        createStackDialogVisible: true,
        newStack: {
          id: parseInt(selectedNewStackContainer),
          name: '',
          description: '',
        },
      })
    }
  }

  render() {
    if (this.state.redirectToContainers) {
      return <Redirect to="/" />
    }

    return (
      <>
        <NoContentBox
          // TODO: Image
          imageUrl={NO_CONTENT['containers']}
          title="You didn't create any custom stacks yet."
          description="Create stacks from your containers to reuse later."
          buttonText="Create stack"
          buttonAction={() => {
            this.setState({
              createStackDialogVisible: true,
            })
          }}
          visible={
            this.state.transformedStacks.length === 0 && !this.state.loading
          }
        />
        <div
          className="card-wrap"
          style={{
            display: this.state.transformedStacks.length > 0 ? 'block' : 'none',
          }}
        >
          <div
            className="card"
            style={{
              padding: '30px',
            }}
          >
            <ContainerStacks
              stacks={this.state.transformedStacks}
              selectedStack={this.state.selectedStack}
              onSelectedStackChange={selectedStack =>
                this.setState({ selectedStack })
              }
              onAlwaysOnChange={alwaysOn => {
                this.setState({
                  newContainerAlwaysOn: alwaysOn,
                })
              }}
              alwaysOn={this.state.newContainerAlwaysOn}
              defaultPredefinedFilter={PredefinedFilter.Custom}
              scrollY={500}
              scrollX={500}
              loadingStacks={this.state.loading}
              createContainerButtonDisabled={this.state.selectedStack === ''}
              displayPredefinedFilter={false}
              showFiltersDropdown={true}
              onCreateContainerClick={() => {
                this.setState({
                  createContainerDialogVisible: true,
                })
              }}
              onCreateStackClick={() => {
                this.setState({
                  createStackDialogVisible: true,
                })
              }}
              onRemoveStackClick={this.handleRemoveStackClick}
            />
          </div>
        </div>

        <CreateStackModal
          visible={this.state.createStackDialogVisible}
          onCancel={() => {
            this.setState({
              createStackDialogVisible: false,
              newStack: {
                id: -1,
                name: '',
                description: '',
              },
            })
          }}
          onConfirmCreate={this.handleConfirmCreateCustomStack}
          newCustomStack={this.state.newStack}
          onCustomStackChange={newStack => {
            this.setState({
              newStack,
            })
          }}
          containerList={this.state.containers}
          selectedContainerKey={this.state.selectedNewStackContainer}
          onSelectedContainerChange={selectedNewStackContainer => {
            this.setState({
              selectedNewStackContainer,
              newStack: {
                ...this.state.newStack,
                id: parseInt(selectedNewStackContainer),
              },
            })
          }}
          validation={this.state.createStackValidation}
        />
        <UpgradePlanDialog
          title="Container limit reached!"
          visible={this.state.containerLimitDialogVisible}
          description={`You have reached your maximum number of active containers. Please upgrade your plan if you want to create more.`}
          onCancel={() => {
            this.setState({
              containerLimitDialogVisible: false,
            })
          }}
        />
        <Modal
          visible={this.state.confirmDeleteDialogVisible}
          title="Delete custom stack"
          onCancel={() => {
            this.setState({
              stackIdToDelete: '',
              confirmDeleteDialogVisible: false,
            })
          }}
          onOk={this.handleRemoveStack}
        >
          <div>Are you sure you want to delete this custom stack?</div>
          <div>This action can not be undone.</div>
        </Modal>
        <Modal
          visible={this.state.createContainerDialogVisible}
          title="Create container"
          onCancel={() => {
            this.setState({
              createContainerDialogVisible: false,
              newContainerName: '',
              newContainerAlwaysOn: false,
            })
          }}
          onOk={this.handleConfirmCreateContainer}
          okButtonProps={{
            loading: this.state.confirmCreateLoading,
          }}
        >
          <div
            className="input-wrap"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.handleConfirmCreateContainer()
              }
            }}
          >
            <div className="mui-input">
              <div className="group">
                <input
                  type="text"
                  value={this.state.newContainerName}
                  onChange={e => {
                    this.setState({
                      newContainerName: e.target.value,
                    })
                  }}
                />
                <span className="bar"></span>
                <label>Container name</label>
              </div>
              <span className="error">
                {this.state.validation.errors['newContainerName'] || ''}
              </span>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={this.state.newContainerAlwaysOn}
              onChange={e => {
                this.setState({
                  newContainerAlwaysOn: e.target.checked,
                })
              }}
            >
              Enable Always-On
            </Checkbox>
            <div
              data-balloon-pos="up"
              data-balloon="Regular containers will be stopped when you sign out. Containers with the applied “Always-On” option will keep running even if you’re not working."
              data-balloon-length="large"
              style={{
                display: 'grid',
                placeItems: 'center',
                height: '15px',
                width: '15px',
              }}
            >
              <img
                src={questionIcon}
                alt="Question icon"
                style={{
                  height: '100%',
                  width: '100%',
                }}
              ></img>
            </div>
          </div>
        </Modal>
      </>
    )
  }

  private async getCustomStacks() {
    this.setState({
      loading: true,
    })

    try {
      const stacks = await this.props.apiClient.getContainerStacks()

      this.setState({
        transformedStacks: tableDataTransform(stacks, false),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while fetching stacks.',
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  private async getContainers() {
    try {
      const containers = await this.props.apiClient.getContainerList()

      this.setState({
        containers: containers
          .map(container => {
            return {
              ...container,
              key: container.id.toString(),
              loading: false,
            }
          })
          .filter(container => {
            return container.state === 10 || container.state === 20
          }),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your container list.',
      })
    }
  }

  private handleConfirmCreateCustomStack = async () => {
    const validation = Validate({ ...this.state.newStack })

    this.setState({
      createStackValidation: validation,
    })

    if (!validation.valid) {
      message.error('Please fill in the required data.')
      return
    }

    try {
      await this.props.apiClient.createCustomStack(this.state.newStack)

      this.awaitCreation(this.state.newStack.name)

      this.setState({
        createStackDialogVisible: false,
        transformedStacks: [
          ...this.state.transformedStacks,
          {
            id: Math.random().toPrecision(3),
            key: Math.random().toPrecision(3),
            name: '',
            desc: 'Creating...',
            stackName: this.state.newStack.name,
            stackId: '0',
            tags: [],
            stackType: ContainerStackType.Custom,
          },
        ],
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while creating the custom stack.',
      })
    }
  }

  private awaitCreation(stackName: string) {
    setTimeout(async () => {
      const stacks = await this.props.apiClient.getContainerStacks()
      const stack = stacks.custom.filter(s => s.name === stackName)
      if (!stack.length) {
        this.awaitCreation(stackName)
      } else {
        let stateStacks = [...this.state.transformedStacks]

        this.setState({
          // @ts-ignore
          transformedStacks: stateStacks.flatMap(s => {
            if (s.stackName !== stackName) {
              return s
            }

            return stack[0].distros.map(distro => {
              return {
                id: distro.id,
                key: 'Stack:' + stack[0].id + 'Distro:' + distro.id,
                name: distro.name,
                desc: distro.desc || stack[0].desc,
                stackName: stack[0].name,
                stackId: stack[0].id,
                tags: stack[0].tags,
                isPredefined: false,
              }
            })
          }),
        })
      }
    }, 1000)
  }

  private handleConfirmCreateContainer = async () => {
    this.setState({
      confirmCreateLoading: true,
    })

    const validation = Validate({
      newContainerName: this.state.newContainerName,
    })

    this.setState({
      validation,
    })

    if (!validation.valid) {
      this.setState({
        confirmCreateLoading: false,
      })
      message.error('Please fill in the required data.')
      return
    }

    const selectedRow = this.state.transformedStacks.filter(
      stack => stack.key === this.state.selectedStack
    )

    try {
      const container: CreateContainer = {
        type: 'devbox',
        name: this.state.newContainerName,
        alwaysOn: this.state.newContainerAlwaysOn,
        stack: selectedRow[0].stackId,
        distro: '',
      }

      await this.props.apiClient.createContainer(container)

      this.setState({
        createContainerDialogVisible: false,
        redirectToContainers: true,
      })
    } catch (e) {
      if (e.upgrade) {
        this.setState({
          createContainerDialogVisible: false,
          containerLimitDialogVisible: true,
        })
      } else {
        notification.error({
          message: 'An error occurred while creating your container',
          description: String(e),
        })
      }
    } finally {
      this.setState({
        confirmCreateLoading: false,
      })
    }
  }

  private handleRemoveStackClick = async (stackId: string) => {
    this.setState({
      stackIdToDelete: stackId,
      confirmDeleteDialogVisible: true,
    })
  }

  private handleRemoveStack = async () => {
    try {
      await this.props.apiClient.removeCustomStack(this.state.stackIdToDelete)

      let stacks = [...this.state.transformedStacks]

      stacks = stacks.filter(
        stack => stack.stackId !== this.state.stackIdToDelete
      )

      this.setState({
        transformedStacks: stacks,
        selectedStack: '',
        stackIdToDelete: '',
        confirmDeleteDialogVisible: false,
      })
    } catch (e) {
      notification.error({
        message: 'An erroor occurred while removing the stack.',
      })
    }
  }
}
