import {
  Distro,
  ContainerStackList,
} from '../../api-client/interface/Connection'
import _ from 'lodash'

export enum ContainerStackType {
  Predefined,
  Custom,
  Team,
}
export type ContainerStackTableData = Distro & {
  key: string
  tags: string[]
  stackType: ContainerStackType
  stackName: string
  stackId: string
}

export const tableDataTransform = (
  stacks: ContainerStackList,
  includePredefined: boolean
): ContainerStackTableData[] => {
  const customStacks = stacks.custom
    ? _.flatMap(stacks.custom, stack => {
        return stack.distros.map(distro => {
          return {
            id: distro.id,
            key: 'Stack:' + stack.id + 'Distro:' + distro.id,
            name: distro.name,
            desc: distro.desc || stack.desc,
            stackName: stack.name,
            stackId: stack.id,
            stackPlatform: stack.id === 'wordpress' ? stack.id : stack.platform,
            tags: stack.tags,
            stackType: ContainerStackType.Custom,
          }
        })
      })
    : []

  const teamStacks = stacks.team
    ? _.flatMap(stacks.team, stack => {
        if (stack.distro) {
          return stack.distro.map(stackDistro => {
            return {
              id: stackDistro.id,
              key: 'Stack:' + stack.id + 'Distro:' + stackDistro.id,
              name: stackDistro.name,
              desc: stackDistro.desc || stack.desc,
              stackName: stack.name,
              stackId: stack.id,
              stackPlatform:
                stack.id === 'wordpress' ? stack.id : stack.platform,
              tags: stack.tags,
              stackType: ContainerStackType.Team,
            }
          })
        }
        return []
      })
    : []

  if (includePredefined) {
    return _.flatMap(stacks.predefined, stack => {
      return stack.distros.map(distro => {
        return {
          id: distro.id,
          key: 'Stack:' + stack.id + 'Distro:' + distro.id,
          name: distro.name,
          desc: distro.desc,
          stackName: stack.name,
          stackId: stack.id,
          stackPlatform: stack.id === 'wordpress' ? stack.id : stack.platform,
          tags: stack.tags,
          stackType: ContainerStackType.Predefined,
        }
      })
    })
      .concat(...customStacks)
      .concat(...teamStacks)
  }

  return customStacks.concat(...teamStacks)
}
