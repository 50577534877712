import * as React from 'react'
import MoneyBack from '../styles/img/money-back.svg'
import JustMe from '../styles/img/addons/just-me.svg'
import { PricingPlan as PricingPlanComponent } from '../components/PricingPlan'
import { AddonPicker } from '../components/AddonPicker'
import {
  AddonName,
  ADDON_DESCRIPTIONS,
  ADDON_PRICE_NEW,
  ADDON_TITLES,
  Subscription,
} from '../api-client/interface/Subscription'
import {
  calculateDiscount,
  PricingPlan,
  PricingPlanCode,
} from '../api-client/interface/PricingPlan'
import { ADDONS } from '../helpers/image-imports/Addons'
import CheckIcon from '../styles/img/check.svg'
import { parse as parseQs } from 'querystring'
import { Coupon } from '../api-client/interface/RecurlyClient'
import { CheckoutCoupon } from '../components/CheckoutCoupon'

export enum PlanPeriod {
  MONTHLY = 'm',
  YEARLY = 'y',
  BIYEARLY = '2y',
}

type Props = {
  planPeriod: PlanPeriod
  pricingPlans: PricingPlan[]
  selectedPlan: PricingPlan
  teamAccounts: number
  onPlanPeriodChange: (pp: PlanPeriod) => void
  onActivePlanChange: (planCode: string) => void
  onTeamAccountsChange: (teamAccounts: number) => void
  goToAddons: () => void

  coupon: Coupon | null
  couponCode: string
  couponActive: boolean
  couponLoading: boolean
  onCouponCodeChange: (couponCode: string) => void
  onCouponApply: () => void
  onCouponActiveChange: (couponActive: boolean) => void

  subscription: Subscription | null
  loadingSubscription: boolean
  onFirstSubscription: boolean
}

type State = {
  teamAddonVisible: boolean
}

export class CheckoutStep1Container extends React.Component<Props, State> {
  public state: State = {
    teamAddonVisible: false,
  }

  componentDidMount() {
    this.cssFixes()
  }

  render() {
    const displayTrial =
      String(parseQs(window.location.search.slice(1))['click']) === 'trial'

    return (
      <>
        <header className="step-one__header ant-row">
          <div className="step-one-header__description ant-col">
            <h3>Save time by deploying a development environment in seconds</h3>
            <ul>
              <li>
                <img src={CheckIcon} alt="" /> Fully featured web-based code
                editor
              </li>
              <li>
                <img src={CheckIcon} alt="" /> Team up with your fellow
                developers and collaborate on your projects
              </li>
              <li>
                <img src={CheckIcon} alt="" /> Pre-installed language runtime
                and tools for all popular programming languages
              </li>
            </ul>
          </div>
          <div
            className="step-one-header__money-back"
            style={{
              display:
                !this.props.loadingSubscription &&
                this.props.onFirstSubscription
                  ? 'flex'
                  : 'none',
            }}
          >
            <div className="ant-col payment-header__badge">
              <img src={MoneyBack} alt="Money back guarantee" />
              <div>
                MONEY BACK
                <br />
                100% GUARANTEE
              </div>
            </div>
          </div>
        </header>

        <div className="plan-period__wrapper">
          <ul className="plan-period">
            <li
              className="plan-period__item"
              data-active={this.props.planPeriod === PlanPeriod.BIYEARLY}
              onClick={() => this.props.onPlanPeriodChange(PlanPeriod.BIYEARLY)}
            >
              2-year plans
            </li>
            <li
              className="plan-period__item"
              data-active={this.props.planPeriod === PlanPeriod.YEARLY}
              onClick={() => this.props.onPlanPeriodChange(PlanPeriod.YEARLY)}
            >
              1-year plans
            </li>
            <li
              className="plan-period__item"
              data-active={this.props.planPeriod === PlanPeriod.MONTHLY}
              onClick={() => this.props.onPlanPeriodChange(PlanPeriod.MONTHLY)}
            >
              Monthly plans
            </li>
          </ul>
        </div>

        <div className="section payment-plans-wrapper">
          <div className="payment-plans">
            {this.props.pricingPlans.map(plan => {
              return (
                <PricingPlanComponent
                  active={this.props.selectedPlan.code === plan.code}
                  planName={plan.name}
                  planCode={plan.code}
                  key={plan.code}
                  planMonthlyPrice={
                    this.props.planPeriod === PlanPeriod.MONTHLY
                      ? plan.price.month
                      : this.props.planPeriod === PlanPeriod.YEARLY
                      ? plan.price.year
                      : plan.price.biyear
                  }
                  discountPercent={parseFloat(
                    calculateDiscount(plan, this.props.planPeriod)
                  )}
                  biYearOriginalPrice={plan.price.month * 24}
                  isBestDeal={
                    plan.code.includes('standard') &&
                    this.props.planPeriod === PlanPeriod.BIYEARLY
                  }
                  planPeriod={this.props.planPeriod}
                  onClick={this.props.onActivePlanChange}
                />
              )
            })}
          </div>
          <div className="payment-plans-info">
            <div>
              {
                // TODO: proper href - all hrefs need fixing
              }
              or{' '}
              <a
                href="https://codeanywhere.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                compare our plans
              </a>
            </div>
            <div
              style={{
                display:
                  !this.props.loadingSubscription && this.props.subscription
                    ? 'block'
                    : 'none',
              }}
            >
              <div>or</div>
              <a href="/cancel-membership" rel="noopener noreferrer">
                Cancel Membership
              </a>
            </div>
            <div
              style={{
                display: displayTrial ? 'block' : 'none',
              }}
            >
              or
            </div>
            <div
              style={{
                display: displayTrial ? 'block' : 'none',
              }}
            >
              <a className="checkout-button payment-plan__button" href="/">
                Start Free 7-Day Trial
              </a>
            </div>
          </div>
        </div>

        <section className="step-one__team">
          <h3 className="step-one-team__title">
            Will you be working in the team?
          </h3>
          <h4
            style={{
              display: this.props.selectedPlan.code.includes('basic')
                ? 'inline'
                : 'none',
            }}
          >
            Team accounts are not available in the Basic plan.{' '}
            <u
              onClick={() => this.props.onActivePlanChange('standard_buy')}
              style={{
                cursor: 'pointer',
                color: 'rgba(127, 63, 152, 1)',
              }}
            >
              Upgrade to Standard
            </u>{' '}
            if you want to invite your students and colleagues, share custom
            stacks and more.
          </h4>
          <div className="step-one-team__options">
            <div
              className={`step-one-team__option`}
              data-active={!!(this.props.teamAccounts === 0)}
              onClick={() => this.props.onTeamAccountsChange(0)}
            >
              <div className="payment-method-card__radio" />
              <div className="step-one-team__name">Just me</div>
              <div className="step-one-team__icon">
                <img src={JustMe} alt="Team icon" />
              </div>
            </div>

            <div
              className={`step-one-team__option${
                this.props.selectedPlan.code.includes('basic')
                  ? ' disabled'
                  : ''
              }`}
              {...(this.props.selectedPlan.code.includes('basic')
                ? {
                    'data-balloon':
                      'Team accounts are not available in the Basic plan.',
                    'data-balloon-pos': 'up',
                  }
                : {})}
              data-active={!!(this.props.teamAccounts > 0)}
              onClick={() => {
                if (this.props.selectedPlan.code.includes('basic')) {
                  return
                }
                this.props.onTeamAccountsChange(1)
              }}
            >
              <div className="payment-method-card__radio" />
              <div className="step-one-team__name">Team</div>
              <div className="step-one-team__icon">
                <img src={ADDONS[AddonName.MultipleAccounts]} alt="Team icon" />
              </div>
            </div>
          </div>
          <section
            className="step-one__team"
            style={{
              display: this.props.teamAccounts ? 'block' : 'none',
            }}
          >
            <h3 className="step-one-team__title">
              How many people will you be working with?
            </h3>
            <div className={'step-one-team__description'}>
              You can buy <strong>Team Accounts</strong> for additional users.
              Owning team accounts allows you to invite your students and
              colleagues, share custom stacks and more.
            </div>
            <div className={'step-one-team__options'}>
              <AddonPicker
                iconSrc={ADDONS[AddonName.MultipleAccounts]}
                iconAlt="Team icon"
                addonTitle={ADDON_TITLES[AddonName.MultipleAccounts]}
                addonDescription={
                  ADDON_DESCRIPTIONS[AddonName.MultipleAccounts]
                }
                addonPrice={
                  ADDON_PRICE_NEW[
                    this.props.selectedPlan.code as PricingPlanCode
                  ][AddonName.MultipleAccounts][PlanPeriod.MONTHLY]
                }
                addonPriceDiscounted={
                  ADDON_PRICE_NEW[
                    this.props.selectedPlan.code as PricingPlanCode
                  ][AddonName.MultipleAccounts][this.props.planPeriod]
                }
                addonNumber={this.props.teamAccounts}
                onAddonNumberChange={this.props.onTeamAccountsChange}
                isIncluded={false}
              />
            </div>
          </section>
        </section>
        <div className="sticky-barrier"></div>
        <div className="addon-picker-footer step-one-footer">
          <div className="ant-row">
            <div className="ant-col ant-col-24 ant-col-sm-12">
              {this.props.loadingSubscription ? (
                <></>
              ) : this.props.couponActive ? (
                <CheckoutCoupon
                  couponCode={this.props.couponCode}
                  valid={this.props.coupon ? this.props.coupon.valid : false}
                  loading={this.props.couponLoading}
                  onCouponCodeChange={this.props.onCouponCodeChange}
                  onApplyClick={this.props.onCouponApply}
                  onCancelClick={() => this.props.onCouponActiveChange(false)}
                />
              ) : (
                <div
                  className="checkout-action"
                  onClick={() => this.props.onCouponActiveChange(true)}
                >
                  Got coupon?
                </div>
              )}
            </div>
            <div className="ant-col ant-col-24 ant-col-sm-12 addon-picker-footer__right">
              <div className="align-center">
                <button
                  className="checkout-button"
                  onClick={this.handleContinueToAddons}
                  id="checkout-continue-button--step1"
                >
                  {this.state.teamAddonVisible ? 'Continue to Addons' : '↓'}
                </button>
                <div>You won't be charged yet</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  private cssFixes() {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        entries => {
          // no intersection with screen
          if (entries[0].intersectionRatio === 0) {
            const container = document.querySelectorAll('.addon-picker-footer')
            container.forEach(c => c.classList.add('is-sticky'))
            this.setState({
              teamAddonVisible: false,
            })
          }

          // fully intersects with screen
          else if (entries[0].intersectionRatio === 1) {
            const container = document.querySelectorAll('.addon-picker-footer')
            container.forEach(c => c.classList.remove('is-sticky'))
            this.setState({
              teamAddonVisible: true,
            })
          }
        },
        { threshold: [0, 1] }
      )

      const body = document.querySelector('body')
      if (body && !body.classList.contains('body--type-checkout')) {
        body.classList.add('body--type-checkout')
      }

      const stickyBarriers = document.querySelectorAll('.sticky-barrier')

      stickyBarriers.forEach(sb => observer.observe(sb))
    }
  }

  private handleContinueToAddons = () => {
    if (!this.state.teamAddonVisible) {
      document.querySelector('.step-one__team')!.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      this.props.goToAddons()
    }
  }
}
