import { Modal } from 'antd'
import React from 'react'

type Props = {
  visible: boolean
  title: string
  confirmText: string | React.ReactNode
  cancelText: string | React.ReactNode
  children: string | React.ReactNode
  onConfirm: () => void
  onCancel: () => void
}

type State = {}

export class NoContainersModal extends React.Component<Props, State> {
  render() {
    return (
      <Modal
        visible={this.props.visible}
        title={this.props.title}
        okText={this.props.confirmText}
        cancelText={this.props.cancelText}
        onOk={this.props.onConfirm}
        onCancel={this.props.onCancel}
      >
        {this.props.children}
      </Modal>
    )
  }
}
