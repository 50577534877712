import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  title: string
  description: string
  imageUrl: string
  buttonText?: string
  buttonLink?: string
  buttonAction?: () => void
  visible: boolean
  buttonId?: string
  displayButton?: boolean
}

export class NoContentBox extends React.PureComponent<Props> {
  render() {
    return (
      <div
        style={{
          display: this.props.visible ? 'flex' : 'none',
          minHeight: this.props.displayButton ? 'initial' : '400px',
        }}
        className="no-content--wrapper"
      >
        <img
          src={this.props.imageUrl}
          alt="No content"
          className="no-content--image"
          style={{
            marginBottom: '50px',
          }}
        />
        <h3 className="no-content--title">{this.props.title}</h3>
        <p className="no-content--description">{this.props.description}</p>
        {this.props.displayButton === false ? (
          <></>
        ) : this.props.buttonLink ? (
          <Link to={this.props.buttonLink} target="_blank">
            <Button type="primary">{this.props.buttonText}</Button>
          </Link>
        ) : (
          <Button
            type="primary"
            style={{
              maxWidth: '200px',
            }}
            onClick={this.props.buttonAction}
            id={this.props.buttonId || ''}
          >
            {this.props.buttonText}
          </Button>
        )}
      </div>
    )
  }
}
