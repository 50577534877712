import React from 'react'
import {
  TwoFactorAuth,
  OnEnableResult,
  OnConfirmParams,
} from '../components/TwoFactorAuth'
import { ApiClient } from '../api-client/interface/ApiClient'
import { notification } from 'antd'

type Props = {
  apiClient: ApiClient
}
type State = {
  isEnabled: boolean
}

export class TwoFactorAuthContainer extends React.Component<Props, State> {
  public state: State = {
    isEnabled: false,
  }

  public componentDidMount() {
    this.get2FAEnabled()
  }

  render() {
    return (
      <TwoFactorAuth
        isEnabled={this.state.isEnabled}
        onEnable={this.handleEnable}
        onConfirm={this.handleConfirm}
        onDisable={this.handleDisable}
        setEnabled={isEnabled => {
          this.setState({
            isEnabled,
          })
        }}
      />
    )
  }

  private async get2FAEnabled() {
    try {
      this.setState({
        isEnabled: await this.props.apiClient.has2FAEnabled(),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your account info',
      })
    }
  }

  private handleEnable = async (): Promise<OnEnableResult> => {
    return await this.props.apiClient.get2FAQRCode()
  }

  private handleDisable = async () => {
    await this.props.apiClient.disable2FA()
    this.setState({
      isEnabled: false,
    })
  }

  private handleConfirm = async (params: OnConfirmParams) => {
    await this.props.apiClient.enable2FA(params.code)
    this.setState({
      isEnabled: true,
    })
  }
}
