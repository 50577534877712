import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { ProductionApiClient } from './api-client/adapters/ProductionApiClient'
import { MockAuthProvider } from './api-client/adapters/MockAuthProvider'
import { MockRecurlyClient } from './api-client/adapters/MockRecurlyClient'
import { ProductionRecurlyClient } from './api-client/adapters/ProductionRecurlyClient'
import { CodeanywhereAuthProvider } from './api-client/adapters/CodeanywhereAuthProvider'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ShareApiClient } from './api-client/adapters/ShareApiClient'
import { ShareApi } from '@codeanywhere/collaboration-service-api-client'
import { entries, assign } from './helpers/polyfills'

import * as dotenv from 'dotenv'
dotenv.config()

const authProvider =
  String(process.env.REACT_APP_TOKEN_FROM) === 'env'
    ? new MockAuthProvider()
    : new CodeanywhereAuthProvider(String(process.env.REACT_APP_LOGIN_URL))

const baseUrl = String(process.env.REACT_APP_API_BASE_URL)
const credentialsUrl = String(process.env.REACT_APP_API_CREDENTIALS_URL)
const notificationSocketUrl = String(
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL
)
const shareApi = new ShareApi({
  basePath: String(process.env.REACT_APP_SHARE_API_URL),
})

const apiClient = new ProductionApiClient({
  baseUrl,
  authProvider,
  // TODO: REMOVE THIS WHEN WE FIX THE API! :D
  credentialsUrl,
  notificationSocketUrl,
})

const recurlyClient =
  process.env.REACT_APP_API_CLIENT === 'production'
    ? new ProductionRecurlyClient(
        String(process.env.REACT_APP_RECURLY_PUBLIC_KEY)
      )
    : new MockRecurlyClient()

//hah for venmo
window.recurly = recurly
window.recurly.configure(String(process.env.REACT_APP_RECURLY_PUBLIC_KEY))

//TODO: production/development version?
const shareApiClient = new ShareApiClient({
  shareApi,
  authProvider,
  notificationSocketUrl,
})

TagManager.initialize({
  gtmId: String(process.env.REACT_APP_TAG_MANAGER_ID),
  auth: String(process.env.REACT_APP_TAG_MANAGER_AUTH),
  preview: String(process.env.REACT_APP_TAG_MANAGER_PREVIEW),
})

Sentry.init({
  dsn: String(process.env.REACT_APP_SENTRY_DSN),
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

if (!Object.entries) {
  Object.entries = entries
}

if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    value: assign,
    writable: true,
    configurable: true,
  })
}

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <App
      apiClient={apiClient}
      recurlyClient={recurlyClient}
      shareApiClient={shareApiClient}
    />
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
