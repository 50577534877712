import { Button, Checkbox, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import {
  ContainerStackTableData,
  ContainerStackType,
} from '../helpers/container-stacks/TableDataTransform'
import { CONTAINER_TYPE } from '../helpers/image-imports/ContainerType'
import questionIcon from '../styles/img/questionmark.svg'

export enum PredefinedFilter {
  Default = -1,
  All = 0,
  Predefined,
  Custom,
  Team,
}

type Props = {
  stacks: ContainerStackTableData[]
  selectedStack: string
  alwaysOn: boolean
  defaultPredefinedFilter: PredefinedFilter
  scrollY: number
  scrollX: number
  loadingStacks: boolean
  createContainerButtonDisabled?: boolean
  displayPredefinedFilter?: boolean
  showFiltersDropdown: boolean
  onAlwaysOnChange: (alwaysOn: boolean) => void
  onSelectedStackChange: (selectedStack: string) => void
  onRemoveStackClick?: (stackId: string) => Promise<void>
  onCreateContainerClick?: () => void
  onCreateStackClick?: () => void
  selectId?: string
  handleConfirmCreate?: () => Promise<void>
}

type State = {
  filterInput: string
  predefinedFilter: PredefinedFilter
}

export class ContainerStacks extends React.Component<Props, State> {
  public state: State = {
    filterInput: '',
    predefinedFilter: PredefinedFilter.Default,
  }

  componentDidMount() {
    this.setState({
      predefinedFilter: this.props.defaultPredefinedFilter,
    })
  }

  render() {
    const uniqueStackKeys: string[] = []

    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            className="mui-input"
            style={{
              width: '30%',
            }}
          >
            <div className="group">
              <input
                type="text"
                value={this.state.filterInput}
                required
                onChange={e => {
                  this.setState({
                    filterInput: e.target.value,
                  })
                }}
              />
              <span className="bar"></span>
              <label>Search stacks</label>
            </div>
          </div>
          <div
            className="mui-select"
            style={{
              width: '30%',
              display:
                this.props.showFiltersDropdown === false ? 'none' : 'block',
            }}
          >
            <select
              name="stack-predefined--filter"
              value={this.state.predefinedFilter.toString()}
              onChange={e => {
                this.setState({
                  predefinedFilter: parseInt(e.target.value),
                })
              }}
            >
              <option value="0">All stacks</option>
              <option
                value="1"
                style={{
                  display:
                    this.props.displayPredefinedFilter !== undefined &&
                    this.props.displayPredefinedFilter === false
                      ? 'none'
                      : 'block',
                }}
              >
                Predefined stacks
              </option>
              <option value="2">Custom stacks</option>
              <option value="3">Team stacks</option>
            </select>
            <span className="bar"></span>
          </div>
        </div>
        <div
          id="container-stacks--table-wrapper"
          onKeyDown={e => {
            if (e.key === 'Enter' && this.props.handleConfirmCreate) {
              this.props.handleConfirmCreate()
            } else if (e.key === 'Enter' && this.props.onCreateContainerClick) {
              this.props.onCreateContainerClick()
            }
          }}
        >
          <Table
            dataSource={this.props.stacks.filter(stack => {
              if (
                stack.name.toLowerCase().includes('centos') ||
                stack.stackName.toLowerCase().includes('firehose') ||
                stack.stackName.toLowerCase().includes('stemi')
              ) {
                return false
              }

              const filterInput = this.state.filterInput.toLowerCase()

              if (
                !this.state.filterInput ||
                stack.stackName.toLowerCase().includes(filterInput) ||
                stack.name.toLowerCase().includes(filterInput) ||
                (stack.desc &&
                  stack.desc.toLowerCase().includes(filterInput)) ||
                (stack.tags &&
                  stack.tags.filter(tag => tag.toLowerCase() === filterInput)
                    .length > 0)
              ) {
                if (uniqueStackKeys.includes(stack.key)) {
                  return false
                } else if (
                  this.state.predefinedFilter === PredefinedFilter.All
                ) {
                  uniqueStackKeys.push(stack.key)
                  return true
                } else if (
                  this.state.predefinedFilter === PredefinedFilter.Custom &&
                  stack.stackType === ContainerStackType.Custom
                ) {
                  uniqueStackKeys.push(stack.key)
                  return true
                } else if (
                  this.state.predefinedFilter === PredefinedFilter.Team &&
                  stack.stackType === ContainerStackType.Team
                ) {
                  uniqueStackKeys.push(stack.key)
                  return true
                } else if (
                  this.state.predefinedFilter === PredefinedFilter.Predefined &&
                  stack.stackType === ContainerStackType.Predefined
                ) {
                  uniqueStackKeys.push(stack.key)
                  return true
                }

                return false
              }

              return false
            })}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: [this.props.selectedStack],
              onChange: selectedKeys =>
                this.props.onSelectedStackChange((selectedKeys as string[])[0]),
            }}
            onRow={record => {
              return {
                onClick: () => {
                  if (record.desc && record.desc === 'Creating...') {
                    return
                  }
                  this.props.onSelectedStackChange(record.key)
                },
              }
            }}
            pagination={false}
            scroll={{ y: this.props.scrollY, x: this.props.scrollX }}
            footer={() => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: this.props.onCreateStackClick
                      ? 'space-between'
                      : 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    checked={this.props.alwaysOn}
                    onChange={e => {
                      this.props.onAlwaysOnChange(e.target.checked)
                    }}
                    style={{
                      display: this.props.onCreateStackClick ? 'none' : 'block',
                    }}
                  >
                    Enable Always-On
                  </Checkbox>
                  <div
                    data-balloon-pos="up"
                    data-balloon="Regular containers will be stopped when you sign out. Containers with the applied “Always-On” option will keep running even if you’re not working."
                    data-balloon-length="large"
                    style={{
                      display: this.props.onCreateStackClick ? 'none' : 'grid',
                      placeItems: 'center',
                      height: '15px',
                      width: '15px',
                    }}
                  >
                    <img
                      src={questionIcon}
                      alt="Question icon"
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    ></img>
                  </div>
                  <Button
                    style={{
                      display: this.props.onCreateStackClick ? 'block' : 'none',
                    }}
                    onClick={() =>
                      this.props.onCreateStackClick
                        ? this.props.onCreateStackClick()
                        : null
                    }
                  >
                    New stack
                  </Button>
                  <Button
                    style={{
                      display: this.props.onCreateContainerClick
                        ? 'block'
                        : 'none',
                    }}
                    onClick={() =>
                      this.props.onCreateContainerClick
                        ? this.props.onCreateContainerClick()
                        : null
                    }
                    disabled={this.props.createContainerButtonDisabled}
                  >
                    Create container from stack
                  </Button>
                </div>
              )
            }}
            loading={this.props.loadingStacks}
          >
            <Column
              title=""
              dataIndex="stackPlatform"
              render={platform => {
                return (
                  <img
                    src={CONTAINER_TYPE[platform]}
                    alt="Stack icon"
                    style={{
                      display:
                        CONTAINER_TYPE[platform] !== undefined
                          ? 'block'
                          : 'none',
                    }}
                  />
                )
              }}
              width="12%"
              align="center"
            />
            <Column
              title="Name"
              dataIndex="stackName"
              width="20%"
              align="center"
            />
            <Column
              title="Description"
              dataIndex="desc"
              render={desc => <span style={{ fontWeight: 100 }}>{desc}</span>}
              width="55%"
            />
            {/* <Column title="OS" dataIndex="name" width="15%" /> */}
            <Column
              title=""
              key="Actions"
              render={(stack: ContainerStackTableData) => {
                if (
                  !this.props.onRemoveStackClick ||
                  stack.desc === 'Creating...'
                ) {
                  return <span></span>
                }

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      onClick={() =>
                        this.props.onRemoveStackClick
                          ? this.props.onRemoveStackClick(stack.stackId)
                          : null
                      }
                    >
                      <svg
                        height="15px"
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21.9 21.9"
                      >
                        <path
                          fill="#7f3f98"
                          d="M14.1 11.3c-.2-.2-.2-.5 0-.7l7.5-7.5c.2-.2.3-.5.3-.7s-.1-.5-.3-.7L20.2.3c-.2-.2-.5-.3-.7-.3-.3 0-.5.1-.7.3l-7.5 7.5c-.2.2-.5.2-.7 0L3.1.3C2.9.1 2.6 0 2.4 0s-.5.1-.7.3L.3 1.7c-.2.2-.3.5-.3.7s.1.5.3.7l7.5 7.5c.2.2.2.5 0 .7L.3 18.8c-.2.2-.3.5-.3.7s.1.5.3.7l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3l7.5-7.5c.2-.2.5-.2.7 0l7.5 7.5c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.2-.2.3-.5.3-.7s-.1-.5-.3-.7l-7.5-7.5z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                )
              }}
              width="5%"
            />
          </Table>
        </div>
      </>
    )
  }
}
