import React from 'react'
import { message, notification } from 'antd'
import { Validate, Validation } from '../helpers/validation/Validation'
import defaultImg from '../styles/img/user.svg'

export type OnSaveParams = {
  firstname: string
  lastname: string
  username: string
  email: string
  emailPasswordConfirm: string
  company: string
}

type Props = {
  accountId: string
  firstname: string
  lastname: string
  username: string
  email: string
  company: string
  imageUrl: string
  verified: boolean
  resent: boolean
  onSave: (params: OnSaveParams) => Promise<void>
  onResendVerificationClick: () => Promise<void>
}

type State = {
  expanded: boolean
  saving: boolean
  firstname: string
  lastname: string
  username: string
  email: string
  emailPasswordConfirm: string
  company: string
  validation: Validation
}
export class AccountInformation extends React.PureComponent<Props, State> {
  public state: State = {
    expanded: false,
    saving: false,
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    emailPasswordConfirm: '',
    company: '',
    validation: {
      valid: true,
      errors: {},
    },
  }

  render() {
    return (
      <div className="card-wrap">
        <h1 id="account_information">Account information</h1>
        <div
          className="email_verification"
          style={{
            display: this.props.verified ? 'none' : 'block',
          }}
        >
          {this.renderResent()}
        </div>
        <div className="card information">
          <div className="card__section col">
            <div className="user">
              <div
                className="user__image"
                style={{
                  backgroundImage: `url(${this.props.imageUrl || defaultImg})`,
                  border: '5px solid #bbb0d0',
                }}
              ></div>
              <div className="user__details">
                <div className="user__name">
                  <span className="title--large">
                    {this.props.firstname} {this.props.lastname}
                  </span>
                </div>
                <div className="user__email">
                  <span>{this.props.username}</span>
                </div>
                <div className="user__email">
                  <span>{this.props.email}</span>
                </div>
                <div className="user__company">
                  <span>{this.props.company}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card__abs-bot row">{this.renderEditButton()}</div>
          {this.renderEditBox()}

          <div className="account_id">Account ID: {this.props.accountId}</div>
        </div>
      </div>
    )
  }

  private renderResent() {
    return !this.props.resent ? (
      <span>
        We have sent a verification email to <b>{this.props.email}</b>.{' '}
        <b>
          <button onClick={this.props.onResendVerificationClick}>Resend</button>
        </b>
      </span>
    ) : (
      <span>Verification e-mail has been resent.</span>
    )
  }

  private handleUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      username: event.target.value,
    })
  }

  private handleFirstnameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      firstname: event.target.value,
    })
  }

  private handleLastnameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      lastname: event.target.value,
    })
  }

  private handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
    })
  }

  private handleEmailPasswordConfirmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      emailPasswordConfirm: event.target.value,
    })
  }

  private handleCompanyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      company: event.target.value,
    })
  }

  private handleEditButtonClick = () => {
    this.setState({
      firstname: this.props.firstname,
      lastname: this.props.lastname,
      username: this.props.username,
      email: '',
      emailPasswordConfirm: '',
      company: this.props.company,
      expanded: true,
    })
  }

  private handleHideButtonClick = () => {
    this.setState({
      expanded: false,
      validation: {
        valid: true,
        errors: {},
      },
    })
  }

  private handleEditSaveClick = async () => {
    if (this.state.saving) {
      return false
    }

    const validation = Validate(
      {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        username: this.state.username,
        email: this.state.email,
      },
      {
        email: new RegExp('^(?![sS])|^S+@S+.S+$'),
      },
      {
        email: 'Not a valid email address',
      }
    )

    this.setState({
      validation,
    })

    if (!validation.valid) {
      message.error('Please fill out the required fields')
      return
    }

    this.setState({
      saving: true,
    })
    try {
      await this.props.onSave({
        company: this.state.company,
        email: this.state.email,
        emailPasswordConfirm: this.state.emailPasswordConfirm,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        username: this.state.username,
      })
      this.setState({
        expanded: false,
      })
    } catch (ex) {
      notification.error({
        message: 'Error processing request',
        description: String(ex),
      })
    } finally {
      this.setState({
        saving: false,
      })
    }
  }

  private renderEditButton() {
    if (!this.state.expanded) {
      return (
        <button
          className="btn-icon"
          data-balloon-pos="up"
          data-balloon="Edit"
          id="account"
          onClick={this.handleEditButtonClick}
        >
          <svg
            height="100%"
            viewBox="0 0 24 24"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
          </svg>
        </button>
      )
    }
  }

  private renderEditBox() {
    if (this.state.expanded) {
      return (
        <div id="user-details-wrap">
          <div className="card__section col">
            <span className="title--gray title--details">
              Update account details
            </span>

            <div className="user-details">
              <form name="accountDetailsForm">
                <div className="input-wrap first-name">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="username"
                        type="text"
                        value={this.state.username}
                        onChange={this.handleUsernameChange}
                      />
                      <span className="bar"></span>
                      <label>Username</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['username'] || ''}
                    </span>
                  </div>
                </div>
                <div className="input-wrap first-name">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="firstName"
                        type="text"
                        value={this.state.firstname}
                        onChange={this.handleFirstnameChange}
                      />
                      <span className="bar"></span>
                      <label>First name</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['firstname'] || ''}
                    </span>
                  </div>
                </div>
                <div className="input-wrap last-name">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="lastName"
                        type="text"
                        value={this.state.lastname}
                        onChange={this.handleLastnameChange}
                      />
                      <span className="bar"></span>
                      <label>Last name</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['lastname'] || ''}
                    </span>
                  </div>
                </div>
                <div className="input-wrap company">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="company"
                        type="text"
                        value={this.state.company}
                        onChange={this.handleCompanyChange}
                      />
                      <span className="bar"></span>
                      <label>Company name</label>
                    </div>
                    <span className="error"></span>
                  </div>
                </div>
                <div className="form__error" id="accountDetailsError"></div>
              </form>
            </div>
          </div>
          <div className="card__section col border-top padding-adjustment">
            <div className="email">
              <span className="title--gray title--email">
                Update e-mail address
              </span>
              <form name="changeEmailForm">
                <div className="input-wrap new-email">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="newEmail"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                      />
                      <span className="bar"></span>
                      <label>New e-mail address</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['email'] || ''}
                    </span>
                  </div>
                </div>
                <div className="input-wrap repeat-email">
                  <div className="mui-input">
                    <div className="group">
                      <input
                        name="password"
                        type="password"
                        value={this.state.emailPasswordConfirm}
                        onChange={this.handleEmailPasswordConfirmChange}
                      />
                      <span className="bar"></span>
                      <label>Enter your password to confirm</label>
                    </div>
                    <span className="error">
                      {this.state.validation.errors['emailPasswordConfirm'] ||
                        ''}
                    </span>
                  </div>
                </div>
                <div className="form__error" id="emailChangeError"></div>
              </form>
            </div>
          </div>
          <div className="card__section">
            <div className="card-footer">
              <button
                className="user-details-close btn btn--transparent1"
                type="reset"
                onClick={this.handleHideButtonClick}
              >
                Cancel
              </button>
              <button
                className="btn btn--transparent1 btn--transparent-color"
                id="saveAccountInformation"
                type="submit"
                onClick={this.handleEditSaveClick}
              >
                {this.state.saving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      )
    }
  }
}
