import { message, notification } from 'antd'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  ApiClient,
  ReferralWidgetData,
} from '../api-client/interface/ApiClient'

type Props = {
  apiClient: ApiClient
}

type State = {
  widgetData: ReferralWidgetData
}

export class ReferralContainer extends React.Component<Props, State> {
  public state: State = {
    widgetData: {
      account_credit_in_cents: 0,
      has_unused_referee_credit: false,
      referees: null,
      referral_code: '',
    },
  }

  componentDidMount() {
    this.getWidgetData()
  }

  render() {
    return (
      <>
        <div className="main__row-item">
          {/* /Share ================ */}
          <div className="card-wrap">
            <h1 id="referral_link">Share</h1>
            <div className="card refer-share">
              <div className="card__section row">
                <div className="refer-share__content">
                  <p>
                    Hey, sign up for a Codeanywhere premium account and we both
                    get $15 in credit:
                  </p>
                  <a
                    id="referral-link"
                    href={`https://codeanywhere.com/referralsignup/?r=${this.state.widgetData.referral_code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`https://codeanywhere.com/referralsignup/?r=${this.state.widgetData.referral_code}`}
                  </a>
                </div>
                <div className="refer-share__links">
                  <div className="refer-share__links__top">
                    <CopyToClipboard
                      text={`https://codeanywhere.com/referralsignup/?r=${this.state.widgetData.referral_code}`}
                      onCopy={() => {
                        message.success('Copied')
                      }}
                    >
                      <div className="card-btn" id="copy-referral-link">
                        Copy link
                      </div>
                    </CopyToClipboard>
                  </div>
                  <div className="refer-share__links__bot">
                    <a href="mailto:?subject=Codeanywhere Referral&amp;body=Hey,%0D%0A%0D%0A sign up for a Codeanywhere premium account and we both get $15 in credit:%0D%0A ">
                      <button
                        className="btn-icon"
                        data-balloon-pos="down"
                        data-balloon="Share via Email"
                      >
                        <svg viewBox="0 0 495 495">
                          <path d="M101.3 141.6v228.9h0.3 308.4 0.8V141.6H101.3zM375.7 167.8l-119.7 91.5 -119.6-91.5H375.7zM127.6 194.1l64.1 49.1 -64.1 64.1V194.1zM127.8 344.2l84.9-84.9 43.2 33.1 43-32.9 84.7 84.7L127.8 344.2 127.8 344.2zM384.4 307.8l-64.4-64.4 64.4-49.3V307.8z"></path>
                        </svg>
                      </button>
                    </a>
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=http%3A//"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="btn-icon"
                        data-balloon-pos="down"
                        data-balloon="Share via Facebook"
                      >
                        <svg viewBox="0 0 512 512">
                          <path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"></path>
                        </svg>
                      </button>
                    </a>
                    <a
                      href="https://twitter.com/home?status=Hey,%20sign%20up%20for%20a%20Codeanywhere%20premium%20account%20and%20we%20both%20get%20$15%20in%20credit%3A%0Ahttps%3A//"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="btn-icon"
                        data-balloon-pos="down"
                        data-balloon="Share via Twitter"
                      >
                        <svg viewBox="0 0 512 512">
                          <path d="M419.6 168.6c-11.7 5.2-24.2 8.7-37.4 10.2 13.4-8.1 23.8-20.8 28.6-36 -12.6 7.5-26.5 12.9-41.3 15.8 -11.9-12.6-28.8-20.6-47.5-20.6 -42 0-72.9 39.2-63.4 79.9 -54.1-2.7-102.1-28.6-134.2-68 -17 29.2-8.8 67.5 20.1 86.9 -10.7-0.3-20.7-3.3-29.5-8.1 -0.7 30.2 20.9 58.4 52.2 64.6 -9.2 2.5-19.2 3.1-29.4 1.1 8.3 25.9 32.3 44.7 60.8 45.2 -27.4 21.4-61.8 31-96.4 27 28.8 18.5 63 29.2 99.8 29.2 120.8 0 189.1-102.1 185-193.6C399.9 193.1 410.9 181.7 419.6 168.6z"></path>
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main__row-item main__row-item--section-share">
          <div className="main__col progress-column">
            {/* /Refferal Tracking ================  */}
            <div className="card-wrap">
              <h1 id="referral_tracking">Referral tracking</h1>
              <div className="card refer-tracking">
                <div className="card__section col">
                  <div className="referr__center">
                    <div className="sub__total">
                      $
                      {(
                        this.state.widgetData.account_credit_in_cents / 100
                      ).toPrecision(3)}{' '}
                      Earned /{' '}
                      <span className="referral__left">$150.00 left</span>
                    </div>
                    <div className="sub__total-desc">Referral credits</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  private async getWidgetData() {
    try {
      this.setState({
        widgetData: await this.props.apiClient.getReferralWidgetData(),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting referral data.',
      })
    }
  }
}
