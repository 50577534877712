import { Modal } from 'antd'
import React from 'react'
import {
  SSHConnection,
  FTPConnection,
  FTPSConnection,
  SSHConnectionAuth,
} from '../api-client/interface/Connection'
import '../styles/CreateConnectionModal.css'
import { Validation } from '../helpers/validation/Validation'

type Props = {
  visible: boolean
  validation: Validation
  title: string
  sshConnection: SSHConnection
  ftpConnection: FTPConnection | FTPSConnection
  connectionType: 'ssh' | 'ftp' | 'ftps'
  loading: boolean
  editing: boolean
  onInputChange: (input: string, value: string | number) => void
  onFtpAuthChange: (value: number) => void
  onSshAuthChange: (value: number) => void
  onConnectionTypeChange: (value: string) => void
  onConfirm: () => Promise<void>
  onCancel: () => void
}

type State = {}

export class ConnectionModal extends React.Component<Props, State> {
  render() {
    return (
      <Modal
        title={this.props.title}
        visible={this.props.visible}
        confirmLoading={this.props.loading}
        onOk={this.props.onConfirm}
        onCancel={this.props.onCancel}
      >
        <div>
          <div
            className="create-connection--wrapper"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.props.onConfirm()
              }
            }}
          >
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.name
                        : this.props.ftpConnection.name
                    }
                    className="conn-name"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('name', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Connection name</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['name'] || ''}
                </span>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-select">
                <div className="group">
                  <select
                    name="connection-type"
                    value={this.props.connectionType}
                    onChange={e =>
                      this.props.onConnectionTypeChange(e.target.value)
                    }
                    disabled={this.props.editing}
                    style={{
                      backgroundColor: this.props.editing
                        ? 'lightgray'
                        : 'white',
                    }}
                  >
                    <option value="ssh">SSH</option>
                    <option value="ftp">FTP Server</option>
                    <option value="ftps">FTPS Server</option>
                  </select>
                  <span className="bar"></span>
                  <label>Type</label>
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.hostname
                        : this.props.ftpConnection.hostname
                    }
                    className="hostname"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('hostname', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Hostname</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['hostname'] || ''}
                </span>
              </div>
            </div>
            <div
              className="input-wrap"
              style={{
                display: this.props.connectionType === 'ssh' ? 'block' : 'none',
              }}
            >
              <div className="mui-select">
                <div className="group">
                  <select
                    name="connection auth"
                    value={this.props.sshConnection.auth.toString()}
                    onChange={e =>
                      this.props.onSshAuthChange(parseInt(e.target.value))
                    }
                  >
                    <option value="1">Password</option>
                    <option value="2">Private key</option>
                    <option value="3">Public key</option>
                  </select>
                  <span className="bar"></span>
                  <label>Authorization</label>
                </div>
              </div>
            </div>
            <div
              className="input-wrap"
              style={{
                display:
                  this.props.connectionType === 'ftps' ? 'block' : 'none',
              }}
            >
              <div className="mui-select">
                <div className="group">
                  <select
                    name="connection auth"
                    value={(this.props.ftpConnection as FTPSConnection).auth}
                    onChange={e =>
                      this.props.onFtpAuthChange(parseInt(e.target.value))
                    }
                  >
                    <option value="1">Explicit FTP over TLS</option>
                    <option value="2">Implicit FTP over TLS</option>
                  </select>
                  <span className="bar"></span>
                  <label>Authorization</label>
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.username
                        : this.props.ftpConnection.username
                    }
                    className="username"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('username', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Username</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['username'] || ''}
                </span>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="number"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.timeout
                        : this.props.ftpConnection.timeout
                    }
                    className="timeout"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange(
                        'timeout',
                        parseInt(e.target.value)
                      )
                    }}
                  />
                  <span className="bar"></span>
                  <label>Timeout</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['timeout'] || ''}
                </span>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="number"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.port
                        : this.props.ftpConnection.port
                    }
                    className="port"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('port', parseInt(e.target.value))
                    }}
                  />
                  <span className="bar"></span>
                  <label>Port</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['port'] || ''}
                </span>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.initialdir
                        : this.props.ftpConnection.initialdir
                    }
                    className="initial-dir"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('initialdir', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Initial dir (optional)</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['initialdir'] || ''}
                </span>
              </div>
            </div>
            <div
              className="input-wrap"
              style={{
                display:
                  this.props.connectionType !== 'ssh' ||
                  this.props.sshConnection.auth !==
                    SSHConnectionAuth['Public Key']
                    ? 'block'
                    : 'none',
              }}
            >
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.password || ''
                        : this.props.ftpConnection.password || ''
                    }
                    className="password"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('password', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Password</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['password'] || ''}
                </span>
              </div>
            </div>
            <div
              className="input-wrap"
              style={{
                display:
                  this.props.connectionType === 'ssh' &&
                  this.props.sshConnection.auth === 2
                    ? 'block'
                    : 'none',
              }}
            >
              <div className="mui-input" style={{ height: 'unset' }}>
                <div className="group">
                  <textarea
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.privateKey || ''
                        : ''
                    }
                    rows={5}
                    cols={30}
                    className="private-key"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('privateKey', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Private key</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['privatekey'] || ''}
                </span>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.webUrl || ''
                        : ''
                    }
                    className="web-url"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('webUrl', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Web URL (optional)</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['webUrl'] || ''}
                </span>
              </div>
            </div>
            <div className="input-wrap">
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={
                      this.props.connectionType === 'ssh'
                        ? this.props.sshConnection.previewRoot
                        : this.props.ftpConnection.previewRoot
                    }
                    className="preview-root"
                    onChange={e => {
                      e.preventDefault()
                      this.props.onInputChange('previewRoot', e.target.value)
                    }}
                  />
                  <span className="bar"></span>
                  <label>Root Directory (optional)</label>
                </div>
                <span className="error">
                  {this.props.validation.errors['previewRoot'] || ''}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
