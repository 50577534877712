import * as React from 'react'
import { PlanPeriod } from '../containers/CheckoutStep1Container'

type Props = {
  active: boolean
  planName: string
  planCode: string
  planMonthlyPrice: number
  discountPercent: number
  biYearOriginalPrice: number
  isBestDeal: boolean
  planPeriod: PlanPeriod
  onClick: (planCode: string) => void
}

export class PricingPlan extends React.PureComponent<Props> {
  render() {
    const priceMultiplier =
      this.props.planPeriod === PlanPeriod.MONTHLY
        ? 1
        : this.props.planPeriod === PlanPeriod.YEARLY
        ? 12
        : 24

    return (
      <div
        className={`pricing-plan${this.props.active ? ' active' : ''}${
          this.props.isBestDeal ? ' best-deal' : ''
        }`}
        onClick={() => this.props.onClick(this.props.planCode)}
        id={`pricing-plan-${this.props.planCode}`}
      >
        <div className="payment-method-card__radio" />
        <div className="pricing-plan-select--row">
          <h3 className="pricing-plan__title">{this.props.planName}</h3>
          <div className="pricing-plan__price">
            <sup>$</sup>
            {this.props.planMonthlyPrice.toFixed(2)}
            <sub>/mo</sub>
          </div>
        </div>
        <div className="summary-addon-price summary-discount__badge pricing-plan__discount-label">
          <span>Save {this.props.discountPercent.toFixed(0)}%</span>
        </div>
        <div className="pricing-plan__discount-biyear">
          <del
            className="summary-discount__amount pricing-plan__discount-original"
            style={{
              display: this.props.discountPercent ? 'inline' : 'none',
            }}
          >
            ${this.props.biYearOriginalPrice.toFixed(2)}
          </del>{' '}
          ${(this.props.planMonthlyPrice * priceMultiplier).toFixed(2)} billed
          every{' '}
          {this.props.planPeriod === PlanPeriod.MONTHLY
            ? 'month'
            : this.props.planPeriod === PlanPeriod.YEARLY
            ? 'year'
            : '2 years'}
          *
        </div>
      </div>
    )
  }
}
