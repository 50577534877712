import {
  CardElement,
  useRecurly,
  UseRecurlyInstance,
} from '@recurly/react-recurly'
import React from 'react'

export function RecurlyFormWrapper(props: {
  onSubmit: (e: any) => void
  onCancel: () => void
  isCreatingSubscription: boolean
  footerVisible: boolean
  setRecurly: (recurly: UseRecurlyInstance) => void
}) {
  const {
    onSubmit,
    onCancel,
    isCreatingSubscription,
    footerVisible,
    setRecurly,
  } = props

  const footer = (
    <div
      className="card__section"
      id="billing-footer"
      style={{ paddingBottom: 0 }}
    >
      <div className="card-footer">
        <button
          className="btn btn--transparent1 billing-close"
          type="reset"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="btn btn--transparent1 btn--transparent-color"
          type="submit"
          name="submit"
          onClick={e => onSubmit(e)}
        >
          {isCreatingSubscription ? 'Pay' : 'Save'}
        </button>
      </div>
    </div>
  )

  const recurly = useRecurly()
  setRecurly(recurly)

  return (
    <>
      <div className="input-wrap credit-card" style={{ marginTop: '20px' }}>
        <div className="mui-input">
          <div className="group" style={{ paddingTop: '10px' }}>
            <CardElement />
            <span className="bar"></span>
            <label style={{ fontWeight: 500 }}>Credit Card Information</label>
          </div>
          <span className="error"></span>
        </div>
      </div>
      {footerVisible ? footer : null}
    </>
  )
}
