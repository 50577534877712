import * as React from 'react'

import '../styles/AddonPicker.css'
import { AddonNumberPicker } from './AddonNumberPicker'

type Props = {
  iconSrc: string
  iconAlt: string
  addonTitle: string
  addonDescription: string
  addonPrice: number
  addonPriceDiscounted?: number
  addonNumber: number
  onAddonNumberChange: (addonNumber: number) => void
  isRecommended?: boolean
  isIncluded: boolean
  onUpgradeToStandardClick?: () => void
}

export class AddonPicker extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={`summary-holder summary-addon summary-addon--type-isolated${
          this.props.addonPrice === -1 || this.props.isIncluded
            ? ' summary-addon--disabled'
            : ''
        }`}
        style={{
          border:
            this.props.addonNumber > 0 ? '1px solid rgba(127, 63, 152, 1)' : '',
        }}
      >
        {this.props.isRecommended && (
          <div className="summary__recommended">Recommended</div>
        )}
        {this.props.addonPrice === -1 && !this.props.isIncluded ? (
          <div className="summary__recommended summary__disabled">
            Basic plan doesn't support this addon,{' '}
            <button
              className="summary__disabled-link"
              onClick={this.props.onUpgradeToStandardClick || (() => {})}
            >
              upgrade to Standard
            </button>
          </div>
        ) : (
          <></>
        )}
        {this.props.isIncluded ? (
          <div className="summary__recommended summary__included">
            Unlimited Connections included in the plan
          </div>
        ) : (
          <></>
        )}
        <div className="summary">
          <div className="summary__icon">
            <img src={this.props.iconSrc} alt={this.props.iconAlt} />
          </div>
          <div className="summary__content">
            <div className="summary__description">
              <h3 className="summary__title">{this.props.addonTitle}</h3>
              <p className="summary__description">
                {this.props.addonDescription}
              </p>
            </div>
            <div className="summary__info">
              <del
                className="summary-addon-price summary-addon-price__deducted"
                style={{
                  display:
                    this.props.addonPriceDiscounted !== this.props.addonPrice
                      ? 'inline-flex'
                      : 'none',
                }}
              >
                ${this.props.addonPrice.toFixed(2)}/mo
              </del>
              <span
                className="summary-addon-price"
                style={{
                  display:
                    this.props.addonPrice !== -1 && !this.props.isIncluded
                      ? 'inline-flex'
                      : 'none',
                }}
              >
                $
                {this.props.addonPriceDiscounted
                  ? this.props.addonPriceDiscounted.toFixed(2)
                  : this.props.addonPrice.toFixed(2)}
                /mo{' '}
              </span>
              <span
                className="summary-addon-price summary-discount__badge"
                style={{
                  display:
                    this.props.addonPriceDiscounted !== this.props.addonPrice
                      ? 'inline'
                      : 'none',
                }}
              >
                Save{' '}
                {(
                  (1 -
                    (this.props.addonPriceDiscounted || 1) /
                      this.props.addonPrice) *
                  100
                ).toFixed(0)}
                %
              </span>
            </div>
            {!this.props.isIncluded ? (
              <AddonNumberPicker
                addonNumber={this.props.addonNumber}
                onAddonNumberChange={this.props.onAddonNumberChange}
                disabled={this.props.addonPrice === -1 || this.props.isIncluded}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  }
}
