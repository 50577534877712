import React from 'react'
import { Subscription } from '../api-client/interface/Subscription'
import moment from 'moment'
import { ContainerTime } from './ContainerTime'
import { Spin } from 'antd'

type Props = {
  plan: string
  childAccount: boolean
  adminEmail: string
  subscription: Subscription | null
  onTrial: boolean
  trialExpiration: string
  containerRam: number
  containerHdd: number
  loading: boolean
  containerTimeUsage?: number
  containerTimeLimit?: number
  onUpgradeClick: () => void
  onReactivateClick: () => void
}

export class SubscriptionInformation extends React.PureComponent<Props> {
  render() {
    return (
      <div className="card-wrap">
        <h1>Subscription</h1>
        <div className="card subscription">
          <div
            style={{
              display: this.props.loading ? 'flex' : 'none',
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Spin size="large" />
            <div
              style={{
                flexBasis: '100%',
                textAlign: 'center',
                marginTop: '5px',
              }}
            >
              Loading subscription info...
            </div>
          </div>
          <div className="card__section row">
            <div className="sub-wrap">
              <div className="sub">
                <div className="card-title2 center">
                  {this.props.childAccount
                    ? this.props.plan
                    : this.props.subscription
                    ? this.props.subscription.plan_name.replace('Plan', '')
                    : 'Free Trial'}
                </div>

                <div className="resource containers">
                  <div className="resource__bot">
                    <div className="resource__name">RAM:</div>
                    <div className="resource__num">
                      {this.props.containerRam} MB <span>(per container)</span>
                    </div>
                  </div>
                </div>

                <div className="resource remote ">
                  <div className="resource__bot">
                    <div className="resource__name">HDD:</div>
                    <div className="resource__num">
                      {this.props.containerHdd} GB <span>(per container)</span>
                    </div>
                  </div>
                </div>

                {this.props.containerTimeLimit !== undefined &&
                this.props.containerTimeUsage !== undefined &&
                this.props.onTrial ? (
                  <div
                    className="upgrade-wrap"
                    style={{
                      borderTop: '0',
                      width: '90%',
                    }}
                  >
                    <ContainerTime
                      containerTimeUsage={this.props.containerTimeUsage}
                      containerTimeLimit={this.props.containerTimeLimit}
                    />
                    <div
                      style={{
                        color: '#717171',
                        fontSize: '10px',
                        marginTop: '8px',
                        textAlign: 'left',
                      }}
                    >
                      Need more time? Upgrade now and get unlimited
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="card__section">
                  <div className="card-footer">
                    <button
                      className="btn btn--purple dialog-upgrade"
                      onClick={this.props.onReactivateClick}
                      id="btn-reactivate-plan"
                      style={{
                        display:
                          this.props.subscription &&
                          this.props.subscription.state === 'canceled'
                            ? 'inline'
                            : 'none',
                      }}
                    >
                      Reactivate
                    </button>
                    <button
                      className="btn btn--purple dialog-upgrade"
                      onClick={this.props.onUpgradeClick}
                      id="btn-change-plan"
                      style={{
                        display:
                          !this.props.subscription ||
                          this.props.subscription.state !== 'canceled'
                            ? 'inline'
                            : 'none',
                      }}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>

              <div className="sub">
                {this.props.childAccount ? (
                  <span>
                    Your billing is managed by your team, please contact your
                    team administrator {this.props.adminEmail}.
                  </span>
                ) : (
                  <>
                    <div className="sub-row">
                      <div className="sub__item">Status:</div>
                      <div className="sub__value">
                        {this.props.subscription
                          ? this.props.subscription.state
                          : this.props.onTrial &&
                            moment(this.props.trialExpiration).diff(
                              moment.now(),
                              'minute'
                            ) < 0
                          ? 'expired'
                          : 'active'}
                      </div>
                    </div>
                    <div
                      className="sub-row"
                      style={{
                        display: this.props.onTrial ? 'none' : 'flex',
                      }}
                    >
                      <div className="sub__item">Start date:</div>
                      <div className="sub__value">
                        {this.props.subscription
                          ? moment(this.props.subscription.activated_at).format(
                              'MMM Do YYYY'
                            )
                          : ''}
                      </div>
                    </div>
                    <div className="sub-row">
                      <div className="sub__item">
                        {this.props.onTrial ||
                        (this.props.subscription &&
                          this.props.subscription.state !== 'active')
                          ? 'Expires at:'
                          : 'Recurring date:'}
                      </div>
                      <div className="sub__value">
                        {this.props.onTrial
                          ? moment(this.props.trialExpiration).format(
                              'MMM Do YYYY'
                            )
                          : this.props.subscription
                          ? moment(
                              this.props.subscription.current_period_ends_at
                            ).format('MMM Do YYYY')
                          : ''}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="subscription__pending"
            style={{
              display:
                this.props.subscription &&
                this.props.subscription.pending_subscription
                  ? 'block'
                  : 'none',
            }}
          >
            <span>Note:</span> On{' '}
            <span>
              {this.props.subscription
                ? moment(this.props.subscription.current_period_ends_at).format(
                    'MMM Do YYYY'
                  )
                : ''}
            </span>
            , this subscription will change{' '}
            <span>
              {this.props.subscription ? this.props.subscription.plan_name : ''}
            </span>{' '}
            to{' '}
            <span>
              {this.props.subscription &&
              this.props.subscription.pending_subscription
                ? this.props.subscription.pending_subscription.plan_name
                : ''}
            </span>
            .
          </div>

          <div
            className="subscription__pending"
            style={{
              display:
                this.props.subscription &&
                this.props.subscription.state === 'canceled'
                  ? 'block'
                  : 'none',
            }}
          >
            <span>Note:</span> On{' '}
            <span>
              {this.props.subscription
                ? moment(this.props.subscription.current_period_ends_at).format(
                    'MMM Do YYYY'
                  )
                : ''}
            </span>
            , your subscription will be canceled.
          </div>
        </div>
      </div>
    )
  }
}
