import * as React from 'react'
import MoneyBack from '../styles/img/money-back.svg'
import { PlanPeriod } from './CheckoutStep1Container'
import { CheckoutSummaryItem } from '../components/CheckoutSummaryItem'

import {
  AddonName,
  ADDON_DESCRIPTIONS,
  ADDON_INCLUDED_PRICE,
  ADDON_PRICE_NEW,
  ADDON_TITLES,
  calculateAddonDiscount,
  Subscription,
  SubscriptionAddons,
} from '../api-client/interface/Subscription'
import { CheckoutStep3BillingInfo } from '../components/CheckoutStep3BillingInfo'
import { Validation } from '../helpers/validation/Validation'
import { UseRecurlyInstance } from '@recurly/react-recurly'
import {
  calculateDiscount,
  PricingPlan,
  PricingPlanCode,
} from '../api-client/interface/PricingPlan'
import { ADDONS } from '../helpers/image-imports/Addons'
import { PAYMENT } from '../helpers/image-imports/Payment'
import { CheckoutTermsOfService } from '../components/CheckoutTermsOfService'
import { CheckoutBillingSecurity } from '../components/CheckoutBillingSecurity'
import { Coupon } from '../api-client/interface/RecurlyClient'
import { CheckoutCoupon } from '../components/CheckoutCoupon'
import AddIcon from '../styles/img/add.svg'
import CAGlyph from '../styles/img/codeanywhere_glyph.svg'
import { Spin } from 'antd'
import moment from 'moment'
import { BillingAgreementLabel } from '../components/BillingAgreementLabel'
import { BillingAgreementNotice } from '../components/BillingAgreementNotice'

export enum PaymentMethod {
  AMAZON_PAY = 'amazonPay',
  APPLE_PAY = 'applePay',
  CARD = 'card',
  GOOGLE_PAY = 'googlePay',
  PAY_PAL = 'payPal',
  SAVED = 'saved',
  VENMO = 'venmo',
}

// TODO: Consider rename
export type BillingInfoDto = {
  firstName: string
  lastName: string
  postalCode: string
  country: string
  company: string
  vatNumber: string
  address: string
  city: string
  firstSix?: string
  lastFour?: string
  year?: string
  month?: string
  paypalAgreement?: string
  amazonPayAgreement?: string
  type?: string
}

type Props = {
  paymentMethod: PaymentMethod
  selectedPlan: PricingPlan
  planPeriod: PlanPeriod
  addons: SubscriptionAddons
  hasBillingInformation: boolean
  creatingSubscription: boolean
  onPaymentMethodChange: (paymentMethod: PaymentMethod) => void
  onAddonNumberChange: (addonName: AddonName, newVal: number) => void
  onConfirm: (total: string) => Promise<void>
  getAmazonPayToken: () => Promise<void>

  // Billing info
  validation: Validation
  billingInfo: BillingInfoDto
  additionalFieldsActive: boolean
  form: React.RefObject<HTMLFormElement>
  onBillingInfoChange: (billingInfo: BillingInfoDto) => void
  setRecurly: (recurly: UseRecurlyInstance) => void
  onAdditionalFieldsChange: (additionalFieldsActive: boolean) => void

  coupon: Coupon | null
  couponCode: string
  couponActive: boolean
  couponLoading: boolean
  onCouponCodeChange: (couponCode: string) => void
  onCouponApply: () => void
  onCouponActiveChange: (couponActive: boolean) => void

  creditInCents: number

  subscription: Subscription | null
  loadingSubscription: boolean
  refundPeriodExpired: boolean
  onFirstSubscription: boolean

  displayAmazonPayButton: boolean
  displayAmazonPayNow: boolean
  disableAmazonPayNow: boolean

  loadingPaymentConfirm: boolean

  applePayReady: boolean
  onTotalChange: (total: string) => Promise<void>
}

type State = {
  paymentConfirmed: boolean
}

export class CheckoutStep3Container extends React.Component<Props, State> {
  public state: State = {
    paymentConfirmed: false,
  }

  private prevTotal = ''

  render() {
    const planPrice =
      this.props.planPeriod === PlanPeriod.MONTHLY
        ? this.props.selectedPlan.price.month
        : this.props.planPeriod === PlanPeriod.YEARLY
        ? this.props.selectedPlan.price.year
        : this.props.selectedPlan.price.biyear

    const chargeImmediately = this.calculateChargedImmediately()
    const remainingCash = this.calculateRemainingCash()
    const planChanging = this.planChanging()
    const planPeriodChanging = this.planPeriodChanging()
    const totalForSubmit = Math.max(
      chargeImmediately - this.props.creditInCents / 100,
      0
    ).toFixed(2)

    if (totalForSubmit !== this.prevTotal) {
      this.props.onTotalChange(totalForSubmit)
      this.prevTotal = totalForSubmit
    }

    // @ts-ignore
    const applePayUnsupported = !window.ApplePaySession
    const comingSoon = true

    const paymentMethod =
      this.props.billingInfo.type === 'paypal'
        ? 'PayPal'
        : this.props.billingInfo.type === 'apple_pay'
        ? 'Apple Pay'
        : this.props.billingInfo.type === 'amazon_pay'
        ? 'Amazon Pay'
        : 'Venmo'

    return (
      <div className="">
        <div className="ant-row payment-header">
          <div className="ant-col">
            <h3 className="payment-header__title">Select a payment method</h3>
          </div>
          <div
            className="ant-col payment-header__badge"
            style={{
              display:
                !this.props.loadingSubscription &&
                this.props.onFirstSubscription
                  ? 'flex'
                  : 'none',
            }}
          >
            <img src={MoneyBack} alt="Money back guarantee" />
            <div>
              MONEY BACK
              <br />
              100% GUARANTEE
            </div>
          </div>
        </div>
        <div className="ant-row payment-method">
          <div className="ant-col ant-col-24 ant-col-sm-8 payment-method__cards">
            <article
              className="payment-method__card"
              onClick={() =>
                this.props.onPaymentMethodChange(PaymentMethod.SAVED)
              }
              data-active={this.props.paymentMethod === PaymentMethod.SAVED}
              style={{
                display: this.props.hasBillingInformation ? 'flex' : 'none',
              }}
            >
              <div className={'payment-method-card__radio'} />
              <div>
                <div className="payment-method-card__text">
                  Use your saved billing information
                </div>
              </div>
            </article>
            <article
              className="payment-method__card"
              onClick={() =>
                this.props.onPaymentMethodChange(PaymentMethod.CARD)
              }
              data-active={this.props.paymentMethod === PaymentMethod.CARD}
            >
              <div className={'payment-method-card__radio'} />
              <div>
                <div className="payment-method-card__text">Credit cards</div>
                <div className="payment-method-card__icons">
                  <img
                    src={PAYMENT['Maestro']}
                    alt=""
                    className="payment-method-card__icon"
                  />
                  <img
                    src={PAYMENT['VisaElectron']}
                    alt=""
                    className="payment-method-card__icon"
                  />
                  <img
                    src={PAYMENT['MasterCard']}
                    alt=""
                    className="payment-method-card__icon"
                  />
                  <img
                    src={PAYMENT['Visa']}
                    alt=""
                    className="payment-method-card__icon"
                  />
                  <img
                    src={PAYMENT['Amex']}
                    alt=""
                    className="payment-method-card__icon"
                  />
                  <img
                    src={PAYMENT['Discover']}
                    alt=""
                    className="payment-method-card__icon"
                  />
                </div>
              </div>
            </article>
            {/* <article
              className="payment-method__card"
              onClick={() =>
                this.props.onPaymentMethodChange(PaymentMethod.GOOGLE_PAY)
              }
              data-active={
                this.props.paymentMethod === PaymentMethod.GOOGLE_PAY
              }
            >
              <div className={'payment-method-card__radio'} />
              <div>
                <div className="payment-method-card__text">Google Pay</div>
                <div className="payment-method-card__icons">
                  <img
                    src={PAYMENT['GooglePay']}
                    alt=""
                    className="payment-method-card__icon"
                  />
                </div>
              </div>
            </article>
            */}
            <article
              className="payment-method__card"
              onClick={() =>
                this.props.onPaymentMethodChange(PaymentMethod.PAY_PAL)
              }
              data-active={this.props.paymentMethod === PaymentMethod.PAY_PAL}
            >
              <div className={'payment-method-card__radio'} />
              <div>
                <div className="payment-method-card__text">PayPal</div>
                <div className="payment-method-card__icons">
                  <img
                    src={PAYMENT['PaypalIcon']}
                    alt=""
                    className="payment-method-card__icon"
                    style={{
                      maxHeight: '10px',
                    }}
                  />
                </div>
              </div>
            </article>
            <div
              {...(applePayUnsupported
                ? {
                    'data-balloon-pos': 'left',
                    'data-balloon-length': 'medium',
                    'data-balloon': 'Apple Pay is only supported in Safari.',
                    cursor: 'unset',
                  }
                : {})}
            >
              <article
                className={
                  applePayUnsupported
                    ? 'payment-method__card payment-method__disabled'
                    : 'payment-method__card'
                }
                onClick={() =>
                  this.props.onPaymentMethodChange(PaymentMethod.APPLE_PAY)
                }
                data-active={
                  this.props.paymentMethod === PaymentMethod.APPLE_PAY
                }
              >
                <div className={'payment-method-card__radio'} />
                <div>
                  <div className="payment-method-card__text">Apple Pay</div>
                  <div className="payment-method-card__icons">
                    <img
                      src={PAYMENT['ApplePayBlack']}
                      alt=""
                      className="payment-method-card__icon"
                      style={{
                        maxHeight: '10px',
                      }}
                    />
                  </div>
                </div>
              </article>
            </div>
            <div
              {...(comingSoon
                ? {
                    'data-balloon-pos': 'left',
                    'data-balloon': 'Coming soon.',
                    cursor: 'unset',
                  }
                : {})}
            >
              <article
                className={
                  comingSoon
                    ? 'payment-method__card payment-method__disabled'
                    : 'payment-method__card'
                }
                onClick={() =>
                  this.props.onPaymentMethodChange(PaymentMethod.AMAZON_PAY)
                }
                data-active={
                  this.props.paymentMethod === PaymentMethod.AMAZON_PAY
                }
              >
                <div className={'payment-method-card__radio'} />
                <div>
                  <div className="payment-method-card__text">Amazon Pay</div>
                  <div className="payment-method-card__icons">
                    <img
                      src={PAYMENT['AmazonPayIcon']}
                      alt=""
                      className="payment-method-card__icon"
                      style={{
                        maxHeight: '10px',
                      }}
                    />
                  </div>
                </div>
              </article>
            </div>
            <article
              className="payment-method__card"
              onClick={() =>
                this.props.onPaymentMethodChange(PaymentMethod.VENMO)
              }
              data-active={this.props.paymentMethod === PaymentMethod.VENMO}
            >
              <div className={'payment-method-card__radio'} />
              <div>
                <div className="payment-method-card__text">Venmo</div>
                <div className="payment-method-card__icons">
                  <img
                    src={PAYMENT['VenmoBlue']}
                    alt=""
                    className="payment-method-card__icon"
                    style={{
                      maxHeight: '5px',
                    }}
                  />
                </div>
              </div>
            </article>
          </div>
          <div className="ant-col ant-col-24 ant-col-sm-16">
            <div className="payment-form">
              <div className="step3--payment-method-col--order-summary order-section">
                <h3 className="order-title">Order summary</h3>
                <CheckoutSummaryItem
                  iconSrc={CAGlyph}
                  iconAlt="Pricing plan icon"
                  title={`Codeanywhere ${this.props.selectedPlan.name} Account`}
                  planPeriod={this.props.planPeriod}
                  monthlyPrice={planPrice}
                  discount={calculateDiscount(
                    this.props.selectedPlan,
                    this.props.planPeriod
                  )}
                />
                {Object.entries(this.props.addons)
                  .filter(([, value]) => value > 0)
                  .map(([key, value]) => {
                    const addonPrice =
                      ADDON_PRICE_NEW[
                        this.props.selectedPlan.code as PricingPlanCode
                      ][key as AddonName][this.props.planPeriod]

                    return (
                      <CheckoutSummaryItem
                        key={key}
                        iconSrc={ADDONS[key as AddonName]}
                        iconAlt={`${key} icon`}
                        title={ADDON_TITLES[key as AddonName]}
                        planPeriod={this.props.planPeriod}
                        monthlyPrice={addonPrice}
                        // TODO: Calculate addon discount
                        discount={calculateAddonDiscount(
                          key as AddonName,
                          this.props.selectedPlan.code as PricingPlanCode,
                          this.props.planPeriod
                        )}
                        addonNumber={value}
                        onAddonNumberChange={newVal =>
                          this.props.onAddonNumberChange(
                            key as AddonName,
                            newVal
                          )
                        }
                      />
                    )
                  })}
                <div className="order-total">
                  {this.props.couponActive ? (
                    <div className="total">
                      <CheckoutCoupon
                        couponCode={this.props.couponCode}
                        valid={
                          this.props.coupon ? this.props.coupon.valid : false
                        }
                        loading={this.props.couponLoading}
                        onCouponCodeChange={this.props.onCouponCodeChange}
                        onApplyClick={this.props.onCouponApply}
                        onCancelClick={() =>
                          this.props.onCouponActiveChange(false)
                        }
                      />
                      <span
                        className="order-total__price coupon-value"
                        style={{
                          display:
                            this.props.coupon && this.props.coupon.valid
                              ? 'inline-block'
                              : 'none',
                        }}
                      >
                        {this.props.coupon
                          ? this.props.coupon.discount.type === 'fixed'
                            ? `USD -${this.props.coupon.discount.amount.toFixed(
                                2
                              )}`
                            : `-${this.props.coupon.discount.amount * 100}%`
                          : ''}
                      </span>
                    </div>
                  ) : (
                    <span
                      className="checkout-action"
                      style={{ marginBottom: '20px' }}
                      onClick={() => this.props.onCouponActiveChange(true)}
                    >
                      Got coupon?
                    </span>
                  )}
                  <div
                    className="order-total__refund"
                    style={{
                      display:
                        this.props.subscription &&
                        !(planChanging || planPeriodChanging) &&
                        chargeImmediately < 0
                          ? 'block'
                          : 'none',
                    }}
                  >
                    Note: Since you are downgrading your subscription, you will
                    receive ${(-chargeImmediately).toFixed(2)} in app credits
                    which will be used for future payments.
                  </div>
                  <div
                    className="order-total__refund"
                    style={{
                      display:
                        this.props.subscription &&
                        (planChanging || planPeriodChanging)
                          ? 'block'
                          : 'none',
                    }}
                  >
                    Note: Since you are changing your plan, the rest of your
                    term will be refunded as ${remainingCash.toFixed(2)} in app
                    credits which will be used for future payments.
                  </div>
                  <div
                    className="total"
                    style={{
                      display: this.props.creditInCents > 0 ? 'flex' : 'none',
                    }}
                  >
                    <span className="order-total__title">Account credits</span>
                    <span className="order-total__price">
                      USD {(this.props.creditInCents / 100).toFixed(2)}
                    </span>
                  </div>
                  <div className="total">
                    <span className="order-total__title">
                      {this.props.subscription &&
                      (planChanging || planPeriodChanging)
                        ? 'Total'
                        : 'Charge immediately'}
                    </span>
                    <span
                      className="order-total__price"
                      style={{
                        fontSize: 'calc(25px + (200vw - 640px) / 880)',
                      }}
                    >
                      USD {Math.max(chargeImmediately, 0).toFixed(2)}
                      {this.props.subscription &&
                      (planChanging || planPeriodChanging)
                        ? '*'
                        : ''}
                    </span>
                  </div>
                  <div
                    className="total"
                    style={{
                      display: this.props.creditInCents > 0 ? 'flex' : 'none',
                    }}
                  >
                    <span className="order-total__title">
                      Account credits after processing
                    </span>
                    <span className="order-total__price">
                      USD{' '}
                      {Math.max(
                        Math.max(
                          this.props.creditInCents / 100 - chargeImmediately,
                          0
                        ) +
                          (planChanging || planPeriodChanging
                            ? remainingCash
                            : 0),
                        0
                      ).toFixed(2)}
                    </span>
                  </div>
                  <div
                    className="total"
                    style={{
                      display:
                        this.props.subscription && !planChanging
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    <span className="order-total__title">Recurring total</span>
                    <span className="order-total__price">
                      USD {this.calculateFullPrice().toFixed(2)}*
                    </span>
                  </div>
                </div>
                {Object.entries(this.props.addons)
                  .filter(
                    ([key, value]) =>
                      value === 0 &&
                      ADDON_PRICE_NEW[
                        this.props.selectedPlan.code as PricingPlanCode
                      ][key as AddonName][this.props.planPeriod] !== -1 &&
                      ADDON_PRICE_NEW[
                        this.props.selectedPlan.code as PricingPlanCode
                      ][key as AddonName][this.props.planPeriod] !==
                        ADDON_INCLUDED_PRICE
                  )
                  .map(([key]) => {
                    const addonPrice =
                      ADDON_PRICE_NEW[
                        this.props.selectedPlan.code as PricingPlanCode
                      ][key as AddonName][this.props.planPeriod]

                    return (
                      <div className="summary-addon-holder" key={key}>
                        <div className="summary-addon">
                          <div className="summary__icon">
                            <img
                              src={ADDONS[key as AddonName]}
                              alt={`${key} icon`}
                            />
                          </div>
                          <div className="summary__content">
                            <div>
                              <div className="summary__info">
                                <div className="summary__description">
                                  <div className="summary__title">
                                    {ADDON_TITLES[key as AddonName]}
                                    {addonPrice !== -1
                                      ? ` ($${addonPrice.toFixed(2)}/mo)`
                                      : ''}
                                  </div>
                                  <div className="summary__description">
                                    {ADDON_DESCRIPTIONS[key as AddonName]}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="addon-add__button"
                            onClick={() => {
                              if (addonPrice === -1) {
                                return
                              }

                              this.props.onAddonNumberChange(
                                key as AddonName,
                                1
                              )
                            }}
                          >
                            <span>
                              <img src={AddIcon} alt="Add addon icon" />
                            </span>
                            Add
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div
                className="step3--payment-method-col--card"
                style={{
                  display:
                    this.props.paymentMethod === PaymentMethod.CARD
                      ? 'block'
                      : 'none',
                }}
              >
                <CheckoutStep3BillingInfo
                  form={this.props.form}
                  validation={this.props.validation}
                  billingInfo={this.props.billingInfo}
                  additionalFieldsActive={this.props.additionalFieldsActive}
                  onAdditionalFieldsChange={this.props.onAdditionalFieldsChange}
                  onBillingInfoChange={this.props.onBillingInfoChange}
                  setRecurly={this.props.setRecurly}
                  onConfirm={() => this.props.onConfirm(totalForSubmit)}
                  loadingPaymentConfirm={this.props.loadingPaymentConfirm}
                  subscription={this.props.subscription}
                  loadingSubscription={this.props.loadingSubscription}
                  refundPeriodExpired={this.props.refundPeriodExpired}
                  onFirstSubscription={this.props.onFirstSubscription}
                />
              </div>
              {/* <div
                className="step3--payment-method clearfix ant-row"
                style={{
                  display:
                    this.props.paymentMethod === PaymentMethod.GOOGLE_PAY
                      ? 'block'
                      : 'none',
                }}
              >
                <CheckoutTermsOfService />
                <button
                  className="google-pay"
                  id="checkout-google-pay-button"
                ></button>
                <div className="ant-row billing-security-wrapper">
                  <CheckoutBillingSecurity
                    subscription={this.props.subscription}
                    loadingSubscription={this.props.loadingSubscription}
                    refundPeriodExpired={this.props.refundPeriodExpired}
                    onFirstSubscription={this.props.onFirstSubscription}
                  />
                </div>
              </div> */}
              <div
                className="step3--payment-method clearfix ant-row"
                style={{
                  display:
                    this.props.paymentMethod === PaymentMethod.PAY_PAL
                      ? 'block'
                      : 'none',
                }}
              >
                <CheckoutTermsOfService />
                <div className={'ant-col ant-col-24'}>
                  <button
                    {...(this.props.loadingPaymentConfirm
                      ? {
                          'data-balloon-pos': 'right',
                          'data-balloon':
                            'Please continue the payment process in the popup.',
                        }
                      : {})}
                    className={
                      this.props.loadingPaymentConfirm
                        ? 'checkout-button checkout-button--type-paypal checkout-button--type-paypal--is-disabled'
                        : 'checkout-button checkout-button--type-paypal'
                    }
                    disabled={this.props.loadingPaymentConfirm}
                    onClick={() => this.props.onConfirm(totalForSubmit)}
                    id="checkout-paypal-button"
                  >
                    <span>Continue with </span>
                    <img src={PAYMENT['PaypalPay']} alt="PayPal icon" />
                    <Spin
                      size="default"
                      style={{
                        display: this.props.loadingPaymentConfirm
                          ? 'inline-block'
                          : 'none',
                      }}
                    />
                  </button>
                </div>
                <CheckoutBillingSecurity
                  subscription={this.props.subscription}
                  loadingSubscription={this.props.loadingSubscription}
                  refundPeriodExpired={this.props.refundPeriodExpired}
                  onFirstSubscription={this.props.onFirstSubscription}
                />
              </div>
              <div
                className="step3--payment-method clearfix ant-row"
                style={{
                  display:
                    this.props.paymentMethod === PaymentMethod.APPLE_PAY
                      ? 'block'
                      : 'none',
                }}
              >
                <CheckoutTermsOfService />
                <div className={'ant-col ant-col-24'}>
                  <button
                    {...(this.props.loadingPaymentConfirm
                      ? {
                          'data-balloon-pos': 'right',
                          'data-balloon':
                            'Please continue the payment process in the popup.',
                        }
                      : {})}
                    className={
                      this.props.loadingPaymentConfirm
                        ? 'checkout-button checkout-button--type-applepay checkout-button--type-applepay--is-disabled'
                        : 'checkout-button checkout-button--type-applepay'
                    }
                    disabled={
                      this.props.loadingPaymentConfirm ||
                      !this.props.applePayReady
                    }
                    onClick={() => this.props.onConfirm(totalForSubmit)}
                    id="checkout-applepay-button"
                  >
                    <span>Continue with </span>
                    <img src={PAYMENT['ApplePayWhite']} alt="Apple Pay icon" />
                    <Spin
                      size="default"
                      style={{
                        display: this.props.loadingPaymentConfirm
                          ? 'inline-block'
                          : 'none',
                      }}
                    />
                  </button>
                </div>
                <CheckoutBillingSecurity
                  subscription={this.props.subscription}
                  loadingSubscription={this.props.loadingSubscription}
                  refundPeriodExpired={this.props.refundPeriodExpired}
                  onFirstSubscription={this.props.onFirstSubscription}
                />
              </div>
              <div
                className="step3--payment-method clearfix ant-row"
                style={{
                  display:
                    this.props.paymentMethod === PaymentMethod.AMAZON_PAY
                      ? 'block'
                      : 'none',
                }}
              >
                <CheckoutTermsOfService />
                <div className={'ant-col ant-col-24'}>
                  <button
                    id="pay-with-amazon"
                    onClick={() => this.props.getAmazonPayToken()}
                    style={{
                      display: this.props.displayAmazonPayButton
                        ? 'initial'
                        : 'none',
                    }}
                  ></button>
                  <div className={'step3-amazon-wallet-consent--wrapper'}>
                    <div id="wallet"></div>
                    <div id="consent"></div>
                  </div>
                  <div
                    className={'ant-col ant-col24'}
                    style={{
                      marginTop: this.props.displayAmazonPayNow ? '20px' : '',
                    }}
                  ></div>
                  <button
                    {...(this.props.disableAmazonPayNow
                      ? {
                          'data-balloon-pos': 'right',
                          'data-balloon':
                            'Please allow the selected payment method to be used for future payments',
                        }
                      : {})}
                    type="submit"
                    className={
                      this.props.disableAmazonPayNow
                        ? 'checkout-button pay-now-disabled'
                        : 'checkout-button'
                    }
                    onClick={() => {
                      this.props.onConfirm(totalForSubmit)
                      this.setState({
                        paymentConfirmed: true,
                      })
                    }}
                    id="checkout-confirm-payment"
                    disabled={
                      this.props.disableAmazonPayNow ||
                      this.props.creatingSubscription ||
                      this.state.paymentConfirmed
                    }
                    style={{
                      display: this.props.displayAmazonPayNow
                        ? 'block'
                        : 'none',
                    }}
                  >
                    Pay now
                    <Spin
                      size="default"
                      style={{
                        display: this.props.creatingSubscription
                          ? 'inline-block'
                          : 'none',
                      }}
                    />
                  </button>
                </div>
                <CheckoutBillingSecurity
                  subscription={this.props.subscription}
                  loadingSubscription={this.props.loadingSubscription}
                  refundPeriodExpired={this.props.refundPeriodExpired}
                  onFirstSubscription={this.props.onFirstSubscription}
                />
              </div>
              <div
                className="step3--payment-method clearfix ant-row"
                style={{
                  display:
                    this.props.paymentMethod === PaymentMethod.VENMO
                      ? 'block'
                      : 'none',
                }}
              >
                <CheckoutTermsOfService />
                <div className={'ant-col ant-col-24'}>
                  <button
                    {...(this.props.loadingPaymentConfirm
                      ? {
                          'data-balloon-pos': 'right',
                          'data-balloon':
                            'Please continue the payment process in the popup.',
                        }
                      : {})}
                    className={
                      this.props.loadingPaymentConfirm
                        ? 'checkout-button checkout-button--type-venmo checkout-button--type-venmo--is-disabled'
                        : 'checkout-button checkout-button--type-venmo'
                    }
                    disabled={this.props.loadingPaymentConfirm}
                    onClick={() => this.props.onConfirm(totalForSubmit)}
                    id="checkout-venmo-button"
                  >
                    <span>Continue with </span>
                    <img src={PAYMENT['VenmoBlue']} alt="Venmo icon" />
                    <Spin
                      size="default"
                      style={{
                        display: this.props.loadingPaymentConfirm
                          ? 'inline-block'
                          : 'none',
                      }}
                    />
                  </button>
                </div>
                <CheckoutBillingSecurity
                  subscription={this.props.subscription}
                  loadingSubscription={this.props.loadingSubscription}
                  refundPeriodExpired={this.props.refundPeriodExpired}
                  onFirstSubscription={this.props.onFirstSubscription}
                />
              </div>
              <div
                className="payment-information__saved"
                style={{
                  display:
                    this.props.paymentMethod === PaymentMethod.SAVED
                      ? 'block'
                      : 'none',
                }}
              >
                <div className={'ant-row'}>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                  >
                    <label>Name: </label>{' '}
                    {`${this.props.billingInfo.firstName} ${this.props.billingInfo.lastName}`}
                  </div>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                  >
                    <label>Address: </label>{' '}
                    {`${this.props.billingInfo.postalCode}, ${
                      this.props.billingInfo.city
                    }${
                      this.props.billingInfo.address
                        ? `, ${this.props.billingInfo.address}`
                        : ''
                    }`}
                  </div>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                  >
                    <label>Country: </label>{' '}
                    {`${this.props.billingInfo.country}`}
                  </div>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                    style={{
                      display:
                        this.props.billingInfo.type === 'credit_card'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <label>Card: </label>{' '}
                    {`${this.props.billingInfo.firstSix}******${this.props.billingInfo.lastFour}`}
                  </div>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                    style={{
                      display:
                        this.props.billingInfo.type === 'credit_card'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <label>Expiration Date: </label>{' '}
                    {`${this.props.billingInfo.month}/${this.props.billingInfo.year}`}
                  </div>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                    style={{
                      display:
                        this.props.billingInfo.type === 'credit_card'
                          ? 'none'
                          : 'block',
                    }}
                  >
                    <label>Payment method: </label> {paymentMethod}
                  </div>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field billing-information__card'
                    }
                    style={{
                      display:
                        this.props.billingInfo.type === 'credit_card'
                          ? 'none'
                          : 'block',
                    }}
                  >
                    <BillingAgreementLabel {...this.props.billingInfo} />
                  </div>
                  <BillingAgreementNotice
                    {...this.props.billingInfo}
                    inCheckout={true}
                  />
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                    style={{
                      display: this.props.billingInfo.company
                        ? 'block'
                        : 'none',
                    }}
                  >
                    <label>Company: </label>{' '}
                    {`${this.props.billingInfo.company}`}
                  </div>
                  <div
                    className={
                      'ant-col ant-col-24 payment-information-saved__field'
                    }
                    style={{
                      display: this.props.billingInfo.vatNumber
                        ? 'block'
                        : 'none',
                    }}
                  >
                    <label>VAT Number: </label>{' '}
                    {`${this.props.billingInfo.vatNumber}`}
                  </div>
                  <CheckoutTermsOfService />
                  <div
                    className={
                      'ant-col ant-col24 step3-confirm-payment--wrapper'
                    }
                  >
                    <button
                      type="submit"
                      className="checkout-button"
                      onClick={() => {
                        this.props.onConfirm(totalForSubmit)
                        this.setState({
                          paymentConfirmed: true,
                        })
                      }}
                      id="checkout-confirm-payment"
                      disabled={
                        this.props.creatingSubscription ||
                        this.state.paymentConfirmed
                      }
                    >
                      Pay now
                      <Spin
                        size="default"
                        style={{
                          display: this.props.creatingSubscription
                            ? 'inline-block'
                            : 'none',
                        }}
                      />
                    </button>
                  </div>
                  <div className={'ant-col ant-col24'}>
                    <div className="ant-row billing-security-wrapper">
                      <CheckoutBillingSecurity
                        subscription={this.props.subscription}
                        loadingSubscription={this.props.loadingSubscription}
                        refundPeriodExpired={this.props.refundPeriodExpired}
                        onFirstSubscription={this.props.onFirstSubscription}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private calculateFullPrice() {
    let planPrice = 0,
      priceMultiplier = 0

    switch (this.props.planPeriod) {
      case PlanPeriod.MONTHLY:
        planPrice = this.props.selectedPlan.price.month
        priceMultiplier = 1
        break
      case PlanPeriod.YEARLY:
        planPrice = this.props.selectedPlan.price.year
        priceMultiplier = 12
        break
      case PlanPeriod.BIYEARLY:
        planPrice = this.props.selectedPlan.price.biyear
        priceMultiplier = 24
        break
    }

    let totalPrice = planPrice

    Object.entries(this.props.addons).forEach(([key, value]) => {
      totalPrice +=
        ADDON_PRICE_NEW[this.props.selectedPlan.code as PricingPlanCode][
          key as AddonName
        ][this.props.planPeriod] * value
    })

    totalPrice *= priceMultiplier

    return totalPrice
  }

  private getCurrentSubscriptionTotal() {
    if (!this.props.subscription) {
      return 0
    }

    if (this.props.subscription.unit_amount_in_cents) {
      let currentSubscriptionTotal =
        parseInt(this.props.subscription.unit_amount_in_cents, 10) / 100

      Object.entries(
        this.props.subscription.subscription_add_ons.subscription_add_on
      ).forEach(([key, value]) => {
        currentSubscriptionTotal +=
          (parseInt(value.quantity, 10) *
            parseInt(value.unit_amount_in_cents, 10)) /
          100
      })

      return currentSubscriptionTotal
    }

    return 0
  }

  private calculateTotalWithCoupon() {
    let totalPrice = this.calculateFullPrice()

    if (this.props.coupon) {
      if (this.props.coupon.discount.type === 'fixed') {
        totalPrice -= this.props.coupon.discount.amount
      } else {
        totalPrice = parseFloat(
          (totalPrice * (1 - this.props.coupon.discount.amount)).toPrecision(2)
        )
      }
    }

    return totalPrice
  }

  private calculateRemainingCash() {
    if (!this.props.subscription) {
      return 0
    }
    return this.calculateProrationFactor() * this.getCurrentSubscriptionTotal()
  }

  private calculateProratedTotal() {
    if (!this.props.subscription) {
      return 0
    }

    return (
      this.calculateProrationFactor() *
      (this.calculateTotalWithCoupon() - this.getCurrentSubscriptionTotal())
    )
  }

  private calculateChargedImmediately() {
    if (!this.props.subscription || this.planChanging()) {
      return this.calculateTotalWithCoupon()
    }

    if (!this.planPeriodChanging() && this.planChanging()) {
      return this.calculateProrationFactor() * this.calculateTotalWithCoupon()
    }

    return this.calculateProratedTotal()
  }

  private calculateProrationFactor() {
    if (!this.props.subscription) {
      return 0
    }

    const currentBillingPeriodDuration = moment
      .duration(
        moment(this.props.subscription.current_period_ends_at).diff(
          moment(this.props.subscription.current_period_started_at)
        )
      )
      .asSeconds()

    const timeRemaining = moment
      .duration(
        moment(this.props.subscription.current_period_ends_at).diff(
          moment.now()
        )
      )
      .asSeconds()

    return timeRemaining / currentBillingPeriodDuration
  }

  private planChanging() {
    if (
      !this.props.subscription ||
      !this.props.subscription.plan_code.includes(this.props.selectedPlan.code)
    ) {
      return true
    }

    return false
  }

  private planPeriodChanging() {
    if (!this.props.subscription) {
      return true
    }

    const selectedPlanPeriod =
      this.props.planPeriod === PlanPeriod.MONTHLY
        ? 1
        : this.props.planPeriod === PlanPeriod.YEARLY
        ? 12
        : 24

    return (
      selectedPlanPeriod !==
      this.getPlanPeriodFromCode(this.props.subscription.plan_code)
    )
  }

  private getPlanPeriodFromCode(planCode: string) {
    if (planCode.includes('_m_') || planCode.startsWith('m_')) return 1
    if (planCode.includes('_y_') || planCode.startsWith('y_')) return 12
    if (planCode.includes('_2y_')) return 24
    if (planCode.startsWith('3y_')) return 36
    if (planCode.startsWith('5y_')) return 60

    if (planCode === 'p01') return 1
    if (planCode === 'p02') return 12

    return -1
  }
}
