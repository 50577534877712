import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { ReferralContainer } from '../containers/ReferralContainer'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  setNavigationTitle: (title: string) => void
  userId: number
  email: string
}

export class ReferFriendPage extends React.Component<Props> {
  componentDidMount() {
    this.props.setNavigationTitle('Refer a friend')

    TagManager.dataLayer({
      dataLayer: {
        event: 'openReferFriendScreen',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return (
      <div className="main refer-friend show-flex">
        <div className="main__row">
          <div className="main__row-item">
            {/* /THREE STEPS ================ */}
            <div className="three-steps">
              <div className="card-wrap">
                <div className="card refer">
                  <div className="card__section col">
                    <span className="refer__title">Step One</span>
                    <p className="refer__text">
                      <span>Refer friends</span> with a{' '}
                      <span>$15 discount</span> using your link.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-wrap">
                <div className="card refer">
                  <div className="card__section col">
                    <span className="refer__title">Step Two</span>
                    <p className="refer__text">
                      <span>Friends subscribe</span> with your referral link.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-wrap">
                <div className="card refer">
                  <div className="card__section col">
                    <span className="refer__title">Step Three</span>
                    <p className="refer__text">
                      You also <span>receive $15 off</span> your subscription.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReferralContainer apiClient={this.props.apiClient} />
        </div>
      </div>
    )
  }
}
