import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  ContainerConnection,
  CancelSubscriptionFeedback,
} from '../api-client/interface/Connection'
import { notification, Spin, message } from 'antd'
import moment from 'moment'
import { CancelWarningStep } from '../components/CancelWarningStep'
import { CancelTwoYearStep } from '../components/CancelTwoYearStep'
import { CancelFeedbackStep } from '../components/CancelFeedbackStep'
import { CancelSupportStep } from '../components/CancelSupportStep'
import { CancelCreditsStep } from '../components/CancelCreditsStep'
import { CancelNewEditorStep } from '../components/CancelNewEditorStep'
import { CancelSuggestionsStep } from '../components/CancelSuggestionsStep'
import { CancelConfirmStep } from '../components/CancelConfirmStep'
import { PlanPeriod } from '../containers/CheckoutStep1Container'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  setNavigationTitle: (title: string) => void
}

export enum StepName {
  WARNING = 1,
  TWOYEAR = 2,
  FEEDBACK = 3,
  SUPPORT = 4,
  CREDITS = 5,
  NEWEDITOR = 6,
  SUGGESTIONS = 7,
  CONFIRM = 8,
}

export type CancellationStep = {
  step: StepName
  progressWidth: number
}

export enum CreditsPercentage {
  'NONE' = 0,
  'TWENTY' = 20,
  'FIFTY' = 50,
}

type State = {
  loading: boolean
  userId: number
  username: string
  email: string
  activatedAt: string
  firstName: string
  planPeriod: PlanPeriod
  refundPeriodExpired: boolean
  alwaysOnContainers: ContainerConnection[]
  biyearlyMinMonthPrice: number
  subscriptionExpirationDate: string
  cancelSubscriptionFeedback: CancelSubscriptionFeedback
  cancelRivalEditor: string
  suggestions: string
  steps: CancellationStep[]
  currStepIndex: number
  creditsPercentage: CreditsPercentage
  currentSubscriptionTotal: number
  creditsAmmount: number
  subscriptionUUID: string
  plan: string
  planCode: string
  cancelCreditUsed: boolean
  onFirstSubscription: boolean
  stepActionLoading: boolean
  cancellationConfirmed: boolean
}

export class CancelMembershipPage extends React.Component<Props, State> {
  public state: State = {
    loading: true,
    userId: -1,
    username: '',
    email: '',
    activatedAt: '',
    firstName: '',
    planPeriod: PlanPeriod.MONTHLY,
    refundPeriodExpired: true,
    alwaysOnContainers: [],
    biyearlyMinMonthPrice: -1,
    subscriptionExpirationDate: '',
    cancelSubscriptionFeedback: {
      reasons: [],
      comment: '',
    },
    cancelRivalEditor: '',
    suggestions: '',
    steps: [
      {
        step: StepName.WARNING,
        progressWidth: 0,
      },
    ],
    currStepIndex: 0,
    creditsPercentage: CreditsPercentage.NONE,
    currentSubscriptionTotal: 0,
    creditsAmmount: 0,
    subscriptionUUID: '',
    plan: '',
    planCode: '',
    cancelCreditUsed: true,
    onFirstSubscription: false,
    stepActionLoading: false,
    cancellationConfirmed: false,
  }

  componentDidMount() {
    this.getSubscriptionData()
    this.props.setNavigationTitle('Cancel membership')
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={'spinner-screen'}>
            <Spin size="large" />
          </div>
        </div>
      )
    }

    const currStep = this.state.steps[this.state.currStepIndex]

    return (
      <div
        className="checkout cancel"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="cancellation">
          <div className="cancellation__progress">
            <div
              className="cancellation__progress-bar"
              style={{
                width: currStep.progressWidth + '%',
              }}
            ></div>
            <div className="cancellation__progress-steps">
              <div
                className={`cancellation__progress-step ${
                  true ? 'cancellation__progress-step--active' : false
                }`}
              ></div>

              <div
                className={`cancellation__progress-step ${
                  currStep.progressWidth === 100
                    ? 'cancellation__progress-step--active'
                    : false
                }`}
              ></div>
            </div>
          </div>
          <CancelWarningStep
            userId={this.state.userId}
            email={this.state.email}
            username={this.state.username}
            firstName={this.state.firstName}
            hasTwoYearPlan={this.state.planPeriod === PlanPeriod.BIYEARLY}
            alwaysOnContainers={this.state.alwaysOnContainers}
            displayStep={currStep.step === StepName.WARNING}
            goToNextStep={this.goToNextStep}
          />
          <CancelTwoYearStep
            userId={this.state.userId}
            email={this.state.email}
            username={this.state.username}
            firstName={this.state.firstName}
            biyearlyMinMonthPrice={this.state.biyearlyMinMonthPrice}
            subscriptionExpirationDate={this.state.subscriptionExpirationDate}
            displayStep={currStep.step === StepName.TWOYEAR}
            goToNextStep={this.goToNextStep}
          />
          <CancelFeedbackStep
            userId={this.state.userId}
            email={this.state.email}
            displayStep={currStep.step === StepName.FEEDBACK}
            feedback={this.state.cancelSubscriptionFeedback}
            onReasonsChange={reasons => {
              this.setState({
                cancelSubscriptionFeedback: {
                  ...this.state.cancelSubscriptionFeedback,
                  reasons,
                },
              })
            }}
            onRivalEditorChange={(rivalEditor: string) => {
              this.setState({
                cancelSubscriptionFeedback: {
                  ...this.state.cancelSubscriptionFeedback,
                  rivalEditor,
                },
              })
            }}
            onCommentChange={comment => {
              this.setState({
                cancelSubscriptionFeedback: {
                  ...this.state.cancelSubscriptionFeedback,
                  comment,
                },
              })
            }}
            handleCancellationReasons={this.handleCancellationReasons}
          />
          <CancelSupportStep
            userId={this.state.userId}
            email={this.state.email}
            username={this.state.username}
            firstName={this.state.firstName}
            submitSupportTicket={this.submitSupportTicket}
            displayStep={currStep.step === StepName.SUPPORT}
            goToNextStep={this.goToNextStep}
            loading={this.state.stepActionLoading}
          />
          <CancelCreditsStep
            userId={this.state.userId}
            email={this.state.email}
            username={this.state.username}
            firstName={this.state.firstName}
            creditsAmmount={this.state.creditsAmmount}
            createCancelCredit={this.createCancelCredit}
            displayStep={currStep.step === StepName.CREDITS}
            goToNextStep={this.goToNextStep}
            loading={this.state.stepActionLoading}
          />
          <CancelNewEditorStep
            userId={this.state.userId}
            email={this.state.email}
            username={this.state.username}
            firstName={this.state.firstName}
            displayStep={currStep.step === StepName.NEWEDITOR}
            goToNextStep={this.goToNextStep}
          />
          <CancelSuggestionsStep
            userId={this.state.userId}
            email={this.state.email}
            username={this.state.username}
            firstName={this.state.firstName}
            suggestions={this.state.suggestions}
            onSuggestionsChange={suggestions => {
              this.setState({
                suggestions,
              })
            }}
            displayStep={currStep.step === StepName.SUGGESTIONS}
            goToNextStep={this.goToNextStep}
          />
          <CancelConfirmStep
            userId={this.state.userId}
            email={this.state.email}
            cancelMembership={this.cancelMembership}
            refundPeriodExpired={this.state.refundPeriodExpired}
            subscriptionExpirationDate={this.state.subscriptionExpirationDate}
            displayStep={currStep.step === StepName.CONFIRM}
            onFirstSubscription={this.state.onFirstSubscription}
            loading={this.state.stepActionLoading}
            cancellationConfirmed={this.state.cancellationConfirmed}
          />
        </div>
        <div className="back-to-dashboard">
          <a href="/" target="_self" rel="noopener noreferrer">
            &#60; Back to dashboard
          </a>
        </div>
        <div className="billing-description checkout-footer__bottom">
          <span>Copyright © 2021 Codeanywhere.com</span>
          <a href="mailto:support@codeanywhere.com">support@codeanywhere.com</a>
          <a
            href="https://codeanywhere.com/tos"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </div>
      </div>
    )
  }

  private async getSubscriptionData() {
    await this.getAccountDetails()
    await this.getAlwaysOnContainers()
    await this.getPricingPlansInfo()

    this.setState({
      loading: false,
    })
  }

  private async getAccountDetails() {
    try {
      const accountDetails = await this.props.apiClient.getAccountDetails()
      const subscription = await this.props.apiClient.getAccountSubscription(
        true
      )

      const onFirstSubscription =
        (await this.props.apiClient.getAllAccountSubscriptions()).length === 1

      if (accountDetails.trial) window.location.replace('/')

      let refundPeriodExpired = true
      let activatedAt = ''
      let subscriptionExpirationDate = ''
      if (subscription) {
        const refundPeriodExpiration = moment(subscription.activated_at).add(
          7,
          'day'
        )

        refundPeriodExpired =
          moment().diff(refundPeriodExpiration, 'minute') > 0
        activatedAt = subscription.activated_at
        subscriptionExpirationDate = subscription.current_period_ends_at
      }

      let currentSubscriptionTotal = 0
      let subscriptionUUID = ''
      let plan = ''
      let planCode = ''
      if (subscription && subscription.unit_amount_in_cents) {
        currentSubscriptionTotal =
          parseInt(subscription.unit_amount_in_cents, 10) / 100

        Object.entries(
          subscription.subscription_add_ons.subscription_add_on
        ).forEach(([key, value]) => {
          currentSubscriptionTotal +=
            (parseInt(value.quantity, 10) *
              parseInt(value.unit_amount_in_cents, 10)) /
            100
        })

        subscriptionUUID = subscription.uuid
        plan = subscription.plan_name
        planCode = subscription.plan_code
      }

      const steps = this.state.steps

      if (!accountDetails.plancode.includes('_2y_')) {
        steps.push({
          step: StepName.TWOYEAR,
          progressWidth: 25,
        })
      }

      steps.push({
        step: StepName.FEEDBACK,
        progressWidth: 50,
      })

      this.setState({
        userId: accountDetails.user_id,
        username: accountDetails.username,
        email: accountDetails.email,
        activatedAt,
        firstName: accountDetails.firstname ? accountDetails.firstname : '',
        steps,
        planPeriod: accountDetails.plancode.includes('_2y_')
          ? PlanPeriod.BIYEARLY
          : accountDetails.plancode.includes('_y_')
          ? PlanPeriod.YEARLY
          : PlanPeriod.MONTHLY,
        refundPeriodExpired,
        subscriptionExpirationDate,
        currentSubscriptionTotal,
        subscriptionUUID,
        plan,
        planCode,
        onFirstSubscription,
      })
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occurred while getting your account info',
      })
    }
  }

  private async getAlwaysOnContainers() {
    try {
      const alwaysOnContainers = (
        await this.props.apiClient.getContainerList()
      ).filter(container => container.alwaysOn === 1)

      this.setState({
        alwaysOnContainers,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your always-on containers',
      })
    }
  }

  private async getPricingPlansInfo() {
    try {
      const pricingPlans = await this.props.apiClient.getPricingPlans()

      this.setState({
        biyearlyMinMonthPrice: pricingPlans[0].price.biyear,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting the pricing plans',
      })
    }
  }

  private goToNextStep = () => {
    this.setState({
      currStepIndex: this.state.currStepIndex + 1,
    })
  }

  private handleCancellationReasons = async () => {
    this.setState({
      loading: true,
    })

    const steps = this.state.steps
    let newSteps = 0,
      startIndex = steps.length

    let creditsPercentage = CreditsPercentage.NONE

    //check if cancel credit already used
    let cancelCreditUsed = true
    if (
      this.state.refundPeriodExpired &&
      (this.state.cancelSubscriptionFeedback.reasons.includes('notUsing') ||
        this.state.cancelSubscriptionFeedback.reasons.includes('techIssues') ||
        this.state.cancelSubscriptionFeedback.reasons.includes(
          'notAsAdvertised'
        ) ||
        this.state.cancelSubscriptionFeedback.reasons.includes('expensive'))
    ) {
      try {
        cancelCreditUsed = await this.props.apiClient.cancelCreditUsed()
      } catch (e) {
        notification.error({
          message:
            'An error occurred while fetching your account credits information.',
        })
      }
    }

    //support step
    if (
      this.state.cancelSubscriptionFeedback.reasons.includes(
        'badCustomerSupport'
      ) ||
      this.state.cancelSubscriptionFeedback.reasons.includes('techIssues') ||
      this.state.cancelSubscriptionFeedback.reasons.includes('notAsAdvertised')
    ) {
      steps.push({
        step: StepName.SUPPORT,
        progressWidth: 0,
      })
      newSteps++
    }

    //credits step
    if (
      !cancelCreditUsed &&
      this.state.refundPeriodExpired &&
      this.state.cancelSubscriptionFeedback.reasons.includes('notUsing')
    ) {
      steps.push({
        step: StepName.CREDITS,
        progressWidth: 0,
      })
      creditsPercentage = CreditsPercentage.FIFTY
      newSteps++
    } else if (
      !cancelCreditUsed &&
      this.state.refundPeriodExpired &&
      (this.state.cancelSubscriptionFeedback.reasons.includes('techIssues') ||
        this.state.cancelSubscriptionFeedback.reasons.includes(
          'notAsAdvertised'
        ) ||
        this.state.cancelSubscriptionFeedback.reasons.includes('expensive'))
    ) {
      steps.push({
        step: StepName.CREDITS,
        progressWidth: 0,
      })
      creditsPercentage = CreditsPercentage.TWENTY
      newSteps++
    }

    //editor step
    if (this.state.cancelSubscriptionFeedback.reasons.includes('slow')) {
      steps.push({
        step: StepName.NEWEDITOR,
        progressWidth: 0,
      })
      newSteps++
    }

    //suggestions step
    if (
      this.state.cancelSubscriptionFeedback.reasons.includes('lackOfFeatures')
    ) {
      steps.push({
        step: StepName.SUGGESTIONS,
        progressWidth: 0,
      })
      newSteps++
    }

    //confirm step
    steps.push({
      step: StepName.CONFIRM,
      progressWidth: 100,
    })
    newSteps++
    let feedbackStepProgressWidth = 50 / newSteps

    this.setState({
      currStepIndex: this.state.currStepIndex + 1,
      steps:
        newSteps === 1
          ? steps
          : steps.map((step, index) => {
              if (index >= startIndex && index < steps.length - 1) {
                return {
                  ...step,
                  progressWidth:
                    50 + feedbackStepProgressWidth * (index - startIndex + 1),
                }
              }

              return step
            }),
      creditsPercentage,
      creditsAmmount: Math.max(
        (creditsPercentage / 100) * this.state.currentSubscriptionTotal,
        15
      ),
      loading: false,
    })
  }

  private submitSupportTicket = async () => {
    this.setState({ stepActionLoading: true })

    try {
      await this.props.apiClient.submitSupportTicket(
        this.state.cancelSubscriptionFeedback
      )
    } catch (e) {
      notification.error({
        message: 'An error occurred while submitting your support ticket.',
      })
      return
    } finally {
      this.setState({ stepActionLoading: false })
    }

    message.success('Support ticket successfully submitted.')
    setTimeout(() => (window.location.href = '/'), 2000)
  }

  private createCancelCredit = async () => {
    this.setState({ stepActionLoading: true })

    try {
      await this.props.apiClient.createCancelCredit(
        this.state.creditsPercentage
      )
    } catch (e) {
      notification.error({
        message:
          'An error occurred while applying credits to your account balance.',
      })
      return
    } finally {
      this.setState({ stepActionLoading: false })
    }

    message.success('The credits have been applied to your account.')
    setTimeout(() => (window.location.href = '/'), 2000)
  }

  private cancelMembership = async () => {
    const rivalEditorParam =
      this.state.cancelSubscriptionFeedback.rivalEditor || ''

    this.setState({ stepActionLoading: true })

    try {
      await this.props.apiClient.submitCancellationReasonsAndSuggestions(
        this.state.userId,
        this.state.email,
        this.state.planCode,
        moment(this.state.activatedAt).format('DD.MM.YYYY HH:mm:ss'),
        moment().format('DD.MM.YYYY HH:mm:ss'),
        this.state.cancelSubscriptionFeedback.reasons.join(','),
        this.state.cancelSubscriptionFeedback.comment,
        rivalEditorParam,
        this.state.suggestions
      )
    } catch (e) {
      notification.error({
        message:
          'An error occurred while submitting your cancellation reasons and suggestions.',
      })
    }
    try {
      await this.props.apiClient.cancelSubscription(
        this.state.cancelSubscriptionFeedback,
        this.state.subscriptionUUID
      )
    } catch (e) {
      notification.error({
        message: 'An error occurred while submitting your cancel request.',
      })
      this.setState({ stepActionLoading: false })
      return
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'subscriptionCanceled',
        conversionValue: this.state.plan
          ? this.state.planCode
          : 'ERROR - Not on a plan',
        userId: this.state.userId,
        emailHash: String(sha256(this.state.email)),
      },
    })

    this.setState({
      stepActionLoading: false,
      cancellationConfirmed: true,
    })

    notification.info({
      message: 'Your membership has been cancelled.',
    })

    setTimeout(() => (window.location.href = '/'), 2000)
  }
}
