import { Modal } from 'antd'
import React from 'react'
import { AccountConnection } from '../api-client/interface/AccountConnection'
import { CONNECTED_ACCOUNT } from '../helpers/image-imports/ConnectedAccount'

const PROVIDER_NAMES = new Map<string, string>([
  ['github', 'Github'],
  ['bitbucket', 'Bitbucket'],
  ['google', 'Google'],
  ['digitalocean', 'Digital Ocean'],
  ['dropbox', 'Dropbox'],
  ['onedriveconsumer', 'OneDrive'],
  ['facebook', 'Facebook'],
])

type Props = {
  connectedAccounts: AccountConnection[]
  onRemoveAccount: (provider: string, authId: string) => Promise<void>
  onConnectButtonClick: (authLink: string) => Promise<void>
}

type State = {
  connecting: boolean
  removeConfirmModalVisible: boolean
  removeProvider: string
  removeAuthId: string
}

export class ConnectedAccounts extends React.PureComponent<Props, State> {
  public state: State = {
    connecting: false,
    removeConfirmModalVisible: false,
    removeProvider: '',
    removeAuthId: '',
  }

  render() {
    return (
      <>
        <div className="card-wrap">
          <h1 id="connected_accounts">Connected accounts</h1>
          <div className="card accounts">
            <div className="card__section col no-padding">
              {this.renderList()}
              <div className="accounts__overlay">
                <div className="accounts__overlay-text">
                  Connecting an account...
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          onCancel={() => {
            this.setState({
              removeConfirmModalVisible: false,
              removeAuthId: '',
              removeProvider: '',
            })
          }}
          onOk={async () => {
            await this.props.onRemoveAccount(
              this.state.removeProvider,
              this.state.removeAuthId
            )
            this.setState({
              removeConfirmModalVisible: false,
            })
          }}
          visible={this.state.removeConfirmModalVisible}
        >
          <div>Are you sure you want to remove this connection?</div>
        </Modal>
      </>
    )
  }

  private renderList() {
    return this.props.connectedAccounts.map(ca => {
      return (
        <div className="acc-item" key={ca.provider}>
          <div className="acc-item__acc">
            <div className="acc-item__image">
              <img
                src={CONNECTED_ACCOUNT[ca.provider]}
                alt={PROVIDER_NAMES.get(ca.provider) || 'Provider icon'}
              />
            </div>
            <div className="acc-item__name">
              {PROVIDER_NAMES.get(ca.provider)}
            </div>
            <div className="acc-item__button connect">
              {(ca.multiple_authorizations && ca.authorizations) ||
              !ca.authorizations ? (
                <button
                  className="connect"
                  onClick={() =>
                    this.props.onConnectButtonClick(ca.authorization_link)
                  }
                >
                  Connect
                </button>
              ) : (
                <div />
              )}
            </div>
          </div>
          {ca.authorizations &&
            ca.authorizations.map(account => (
              <div className="acc-item__list" key={account.id}>
                <div>
                  <div className="acc-item__button acc-item__button--type-disconnect acc-item__button--negative">
                    <button
                      className="disconnect pink"
                      onClick={() =>
                        this.setState({
                          removeConfirmModalVisible: true,
                          removeAuthId: account.id.toString(),
                          removeProvider: ca.provider,
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21.9 21.9"
                      >
                        <path
                          fill="#7f3f98"
                          d="M14.1 11.3c-.2-.2-.2-.5 0-.7l7.5-7.5c.2-.2.3-.5.3-.7s-.1-.5-.3-.7L20.2.3c-.2-.2-.5-.3-.7-.3-.3 0-.5.1-.7.3l-7.5 7.5c-.2.2-.5.2-.7 0L3.1.3C2.9.1 2.6 0 2.4 0s-.5.1-.7.3L.3 1.7c-.2.2-.3.5-.3.7s.1.5.3.7l7.5 7.5c.2.2.2.5 0 .7L.3 18.8c-.2.2-.3.5-.3.7s.1.5.3.7l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3l7.5-7.5c.2-.2.5-.2.7 0l7.5 7.5c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.2-.2.3-.5.3-.7s-.1-.5-.3-.7l-7.5-7.5z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="acc-item__name">
                    {account.username || account.provider_email}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )
    })
  }
}
