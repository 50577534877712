import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { notification } from 'antd'
import { Profile } from '../components/Profile'

type Props = {
  apiClient: ApiClient
}

type State = {
  loading: boolean
  firstname: string
  lastname: string
  email: string
  plan: string
  ram: number
  hdd: number
  imageUrl: string
  childAccount: boolean
  adminEmail: string
  containerTimeUsage: number | undefined
  containerTimeLimit: number | undefined
}

export class ProfileContainer extends React.Component<Props> {
  public state: State = {
    loading: true,
    firstname: '',
    lastname: '',
    email: '',
    plan: '',
    ram: 0,
    hdd: 0,
    imageUrl: '',
    containerTimeLimit: undefined,
    containerTimeUsage: undefined,
    adminEmail: '',
    childAccount: false,
  }

  public componentDidMount() {
    this.getAccountDetails()
  }

  render() {
    return this.state.loading ? (
      <div>please wait...</div>
    ) : (
      <Profile
        email={this.state.email}
        firstname={this.state.firstname}
        lastname={this.state.lastname}
        hdd={this.state.hdd}
        ram={this.state.ram}
        imageUrl={this.state.imageUrl}
        plan={this.state.plan}
        adminEmail={this.state.adminEmail}
        containerTimeUsage={this.state.containerTimeUsage}
        containerTimeLimit={this.state.containerTimeLimit}
      />
    )
  }

  private async getAccountDetails() {
    try {
      this.setState({
        loading: true,
      })
      const details = await this.props.apiClient.getAccountDetails()

      this.setState({
        loading: false,
        company: details.company,
        email: details.email,
        firstname: details.firstname,
        lastname: details.lastname,
        username: details.username,
        imageUrl: details.imageurl,
        containerTimeUsage: details.usage.containerTime,
        containerTimeLimit: details.limits.containerTime,
        plan: details.planname,
        childAccount: details.child_account,
        adminEmail: details.admin_email || '',
        ram: parseInt(details.limits['devbox-ram']),
        hdd: parseInt(details.limits['devbox-hdd']),
      })

      if (!details.child_account) {
        this.getAccountSubscription()
      }
    } catch (ex) {
      notification.error({
        message: 'Error getting account details',
        description: String(ex),
      })
    }
  }

  private async getAccountSubscription() {
    try {
      const subscription = await this.props.apiClient.getAccountSubscription()

      this.setState({
        plan: subscription ? subscription.plan_name : 'Free Trial',
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your subscription',
      })
    }
  }
}
