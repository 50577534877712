import React from 'react'
import {
  ChangePassword,
  OnChangePasswordParams,
} from '../components/ChangePassword'
import { ApiClient } from '../api-client/interface/ApiClient'
import { notification } from 'antd'

type Props = {
  apiClient: ApiClient
}

type State = {
  hasInitialPassword: boolean
}

export class ChangePasswordContainer extends React.Component<Props, State> {
  public state: State = {
    hasInitialPassword: false,
  }

  componentDidMount() {
    this.setHasInitialPassword()
  }

  render() {
    return (
      <ChangePassword
        hasInitialPassword={this.state.hasInitialPassword}
        onChangePassword={this.handleChangePassword}
        onSetInitialPassword={this.handleSetInitialPassoword}
      />
    )
  }

  private handleChangePassword = async (params: OnChangePasswordParams) => {
    await this.props.apiClient.updateAccountPassword(
      params.oldPassword,
      params.newPassword,
      params.confirmPassword
    )
    setTimeout(() => (window.location.href = '/account'), 500)
  }

  private handleSetInitialPassoword = async (password: string) => {
    await this.props.apiClient.setInitialPassword(password)
    setTimeout(() => (window.location.href = '/account'), 500)
  }

  private async setHasInitialPassword() {
    try {
      this.setState({
        hasInitialPassword: await this.props.apiClient.hasInitialPassword(),
      })
    } catch (e) {
      notification.error({
        message: 'Could not check initial password',
      })
    }
  }
}
