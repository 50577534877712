import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { ManagedAccountsContainer } from '../containers/ManagedAccountsContainer'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  setNavigationTitle: (title: string) => void
  userId: number
  email: string
}

export class ManagedAccountsPage extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.setNavigationTitle('Team accounts')

    TagManager.dataLayer({
      dataLayer: {
        event: 'openManagedAccountsScreen',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return (
      <div className="main managed-accounts">
        <ManagedAccountsContainer apiClient={this.props.apiClient} />
      </div>
    )
  }
}
