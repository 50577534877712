import React, { FormEvent } from 'react'
import { notification } from 'antd'
import { Switch } from 'antd'
import QRCode from 'qrcode.react'
import { Validation } from '../helpers/validation/Validation'

export type OnConfirmParams = {
  code: string
}

export type OnEnableResult = {
  code: string
  qrFullPath: string
}

type Props = {
  isEnabled: boolean
  onEnable: () => Promise<OnEnableResult>
  onDisable: () => Promise<void>
  onConfirm: (params: OnConfirmParams) => Promise<void>
  setEnabled: (isEnabled: boolean) => void
}

type State = {
  waitingForQRCode: boolean
  processingConfirmation: boolean
  processingDisable: boolean
  formStep: number
  qrFullPath: string
  confirmCode: string
  confirmCodeTextFieldValue: string
  validation: Validation
}

export class TwoFactorAuth extends React.PureComponent<Props, State> {
  public state: State = {
    waitingForQRCode: false,
    processingConfirmation: false,
    processingDisable: false,
    formStep: 1,
    qrFullPath: '',
    confirmCode: '',
    confirmCodeTextFieldValue: '',
    validation: {
      valid: true,
      errors: {},
    },
  }

  render() {
    return (
      <div className="card-wrap">
        <h1 id="2_factor_authentication">2-Factor Authentication</h1>
        <div className="card two-factor">
          <div className="card__section row">
            <div className="two-factor__txt">
              Enable 2-Factor Authentication
            </div>
            <div className="two-factor__btn">
              <div className="switch two-factor">
                <Switch
                  checked={this.props.isEnabled}
                  onChange={this.handleEnabledChange}
                  disabled={
                    this.state.formStep > 1 ||
                    this.state.waitingForQRCode ||
                    this.state.processingConfirmation ||
                    this.state.processingDisable
                  }
                />
                {this.state.waitingForQRCode ? (
                  <div>Please wait for the QR code...</div>
                ) : (
                  <div />
                )}
                {this.state.processingConfirmation ||
                this.state.processingDisable ? (
                  <div>Please wait...</div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          {this.renderSecondStep()}
          {this.renderThirdStep()}
        </div>
      </div>
    )
  }

  private renderSecondStep = () => {
    if (
      this.state.formStep === 2 &&
      !this.state.waitingForQRCode &&
      !this.state.processingDisable
    ) {
      return (
        <div
          className="card__section card__section--type-qr-code row"
          id="qr-scan"
        >
          <div className="two-factor__text">
            Please use your authentication app (e.g. Google Authenticator or
            Duo) to scan this QR code.
          </div>
          <div className="two-factor__qr-code" id="qrcode" title="test">
            <QRCode value={this.state.qrFullPath} />
          </div>
          <div className="two-factor__alternative-code">
            Code: <span id="manual-code">{this.state.confirmCode}</span>
          </div>
          <div className="card-footer">
            <button
              className="btn btn--purple"
              id="qr-code-next"
              onClick={this.handleSecondStepNextButtonClick}
            >
              Next
            </button>
          </div>
        </div>
      )
    }
  }

  private renderThirdStep = () => {
    if (this.state.formStep === 3 && !this.state.processingConfirmation) {
      return (
        <div>
          <form
            className="card__section card__section--type-qr-code row"
            id="qr-confirm"
            onSubmit={this.handleConfirmCodeFormSubmit}
          >
            <div className="two-factor__text">
              Please enter the confirmation code you see inside your
              authentication app.
            </div>

            <div className="input-wrap two-factor__confirmation-code">
              <div className="mui-input">
                <div className="group">
                  <input
                    id="confirmationCode"
                    placeholder="Confirmation Code"
                    onChange={this.handleConfirmationCodeTextChange}
                    value={this.state.confirmCodeTextFieldValue}
                  />
                  <span className="bar"></span>
                  <label>Confirmation code</label>
                </div>
                <span className="error">
                  {this.state.validation.errors['confirmCode'] || ''}
                </span>
              </div>
            </div>
            <div
              className="form__error form__error--type-two-factor"
              id="confirmationCodeError"
            ></div>

            <div className="card-footer">
              <button
                className="btn btn--purple"
                id="qr-code-confirm"
                type="submit"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      )
    }
  }

  private handleConfirmCodeFormSubmit = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    try {
      this.setState({
        processingConfirmation: true,
      })
      await this.props.onConfirm({
        code: this.state.confirmCodeTextFieldValue,
      })
      this.setState({
        processingConfirmation: false,
        formStep: 1,
      })
    } catch (ex) {
      this.setState({
        formStep: 3,
        processingConfirmation: false,
      })
    }
  }

  private handleConfirmationCodeTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      confirmCodeTextFieldValue: event.target.value,
    })
  }

  private handleEnabledChange = async (checked: boolean) => {
    try {
      if (checked) {
        this.setState({
          waitingForQRCode: true,
        })
        this.props.setEnabled(true)
        const qr = await this.props.onEnable()
        this.setState({
          confirmCode: qr.code,
          qrFullPath: qr.qrFullPath,
          formStep: 2,
        })
      } else {
        this.setState({
          processingDisable: true,
        })
        await this.props.onDisable()
      }
    } catch (ex) {
      notification.error({
        message: 'Error processing request',
        description: String(ex),
      })
    } finally {
      this.setState({
        waitingForQRCode: false,
        processingDisable: false,
      })
    }
  }

  private handleSecondStepNextButtonClick = () => {
    this.setState({
      formStep: 3,
    })
  }
}
