import * as React from 'react'
import { Button, Modal } from 'antd'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  userId: number
  email: string

  username: string
  firstName: string

  creditsAmmount: number
  createCancelCredit: () => Promise<void>
  displayStep: boolean
  goToNextStep: () => void

  loading: boolean
}

type State = {
  modalVisible: boolean
}

export class CancelCreditsStep extends React.Component<Props, State> {
  public state: State = {
    modalVisible: false,
  }

  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowCreditsEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return !this.props.displayStep ? null : (
      <>
        <div className="cancellation__plan">
          <h1 className="cancellation__plan-title">
            {this.props.firstName !== ''
              ? this.props.firstName
              : this.props.username}
            , we value your loyalty!
          </h1>
          <h2 className="cancellation__plan-subtitle">
            Before you go, would credits help? Take{' '}
            <span className="green-emphasis">
              ${this.props.creditsAmmount.toFixed(2)}
            </span>{' '}
            off your next payment.
          </h2>
        </div>
        <div className="cancellation-ilustration-wrapper">
          <div className="cancel-credits-gift cancel-img"></div>
        </div>
        <div className="cancellation__plan-spacing"></div>
        <div className="cancellation__actions">
          <Button
            id="cancel-membership-coupon--button"
            className="cancellation__action cancellation__action--type-cancel"
            onClick={() => {
              document.querySelector('body')?.scrollTo(0, 0)
              this.props.goToNextStep()
            }}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-coupon--button"
            type="primary"
            className="cancellation__action cancellation__action--type-success"
            onClick={() => this.setState({ modalVisible: true })}
          >
            Claim credits
          </Button>
        </div>
        <Modal
          title="Apply credits"
          visible={this.state.modalVisible}
          onOk={() => {
            this.props.createCancelCredit()
            TagManager.dataLayer({
              dataLayer: {
                event: 'cancelFlowClaimCredits',
                userId: this.props.userId,
                emailHash: String(sha256(this.props.email)),
                creditsAmmount: this.props.creditsAmmount,
              },
            })
          }}
          onCancel={() => this.setState({ modalVisible: false })}
          width={650}
          okText="Claim"
          confirmLoading={this.props.loading}
        >
          <div className="cancellation-step__modal-content">
            <div>
              Thank you! The credits will be applied to your account balance.
            </div>
            <div className="cancel-credits-discount cancel-img cancel-modal-img"></div>
          </div>
        </Modal>
      </>
    )
  }
}
