import { notification } from 'antd'
import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Invoice } from '../api-client/interface/Invoice'
import { Invoices } from '../components/Invoices'

type Props = {
  apiClient: ApiClient
}

type State = {
  invoices: Invoice[]
}

export class InvoicesContainer extends React.Component<Props, State> {
  public state: State = {
    invoices: [],
  }

  componentDidMount() {
    this.getInvoices()
  }
  render() {
    return (
      <Invoices
        invoiceList={this.state.invoices}
        onDownloadPDF={this.downloadInvoicePDF}
      />
    )
  }

  private async getInvoices() {
    try {
      this.setState({
        invoices: await this.props.apiClient.getInvoices(),
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your invoices',
      })
    }
  }

  private downloadInvoicePDF = async (invoiceId: string) => {
    try {
      await this.props.apiClient.downloadInvoicePdf(invoiceId)
    } catch (ex) {
      notification['error']({
        message: 'Error getting invoice PDF',
        description: String(ex),
      })
    }
  }
}
