import React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  expanded: boolean
  onCloseClick: () => void
  onNavigationClick: () => Promise<void>
  isChildAccount: boolean
}

export class SidebarNavigation extends React.Component<Props> {
  render() {
    return (
      <nav className={`side-nav ${this.props.expanded ? 'slide' : ''}`}>
        <div
          className="mobile-nav-close"
          onClick={() => this.props.onCloseClick()}
        >
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </div>
        <ul>
          <li>
            <NavLink
              className="side-nav-link"
              to="/"
              exact
              activeClassName="active"
              onClick={this.props.onNavigationClick}
            >
              Containers
            </NavLink>
          </li>
          <li>
            <NavLink
              className="side-nav-link"
              to="/shared-with-me"
              activeClassName="active"
              onClick={this.props.onNavigationClick}
            >
              Shared with me
            </NavLink>
          </li>
          <li>
            <NavLink
              className="side-nav-link"
              to="/connections"
              activeClassName="active"
              onClick={this.props.onNavigationClick}
            >
              Connections
            </NavLink>
          </li>
          <li>
            <NavLink
              className="side-nav-link"
              activeClassName="active"
              to="/account"
              onClick={this.props.onNavigationClick}
            >
              Account
            </NavLink>
          </li>
          {this.props.isChildAccount ? (
            <></>
          ) : (
            <li>
              <NavLink
                className="side-nav-link"
                to="/managed-accounts"
                activeClassName="active"
                onClick={this.props.onNavigationClick}
              >
                Team accounts
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              className="side-nav-link"
              to="/custom-stacks"
              activeClassName="active"
              onClick={this.props.onNavigationClick}
            >
              Custom stacks
            </NavLink>
          </li>
          <li>
            <NavLink
              className="side-nav-link"
              activeClassName="active"
              to="/billing"
              onClick={this.props.onNavigationClick}
            >
              Billing
            </NavLink>
          </li>
          <li>
            <NavLink
              className="side-nav-link"
              to="/refer-a-friend"
              activeClassName="active"
              onClick={this.props.onNavigationClick}
            >
              Refer a friend
            </NavLink>
          </li>
        </ul>
      </nav>
    )
  }
}
