import { Button, message, Modal, notification } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  SSHConnection,
  FTPConnection,
  FTPSConnection,
  SSHConnectionAuth,
  ConnectionType,
  ContainerConnection,
} from '../api-client/interface/Connection'
import { Connections } from '../components/Connections'
import { ConnectionModal } from '../components/ConnectionModal'
import { NoContainersModal } from '../components/NoContainersModal'
import { Validate, Validation } from '../helpers/validation/Validation'
import { NoContentBox } from '../components/NoContentBox'
import { NO_CONTENT } from '../helpers/image-imports/NoContent'

type Props = {
  apiClient: ApiClient
}

type State = {
  connections: ConnectionType[]
  connectionDialogVisible: boolean
  containers: ContainerConnection[]
  validation: Validation
  loadingConnections: boolean
  editing: boolean
  sshConnection: SSHConnection
  ftpConnection: FTPConnection | FTPSConnection
  connectionType: 'ssh' | 'ftp' | 'ftps'
  loadingModal: boolean
  editContainerFields: {
    id: number
    name: string
  }
  editContainerDialogVisible: boolean
  containersDialogVisible: boolean
  errorLogModalVisible: boolean
  errorLog: string
  errorTitle: string
  searchInput: string
  searchType: 'all' | 'ftp' | 'ssh' | 'ftps'
}

export class ConnectionsContainer extends React.Component<Props, State> {
  public state: State = {
    connections: [],
    connectionDialogVisible: false,
    containers: [],
    validation: {
      valid: true,
      errors: {},
    },
    loadingConnections: false,
    containersDialogVisible: false,
    editing: false,
    sshConnection: {
      id: -1,
      type: 'ssh',
      name: '',
      hostname: '',
      username: '',
      initialdir: '',
      timeout: 20,
      port: 22,
      webUrl: '',
      previewRoot: '',
      auth: SSHConnectionAuth['Password'],
      password: '',
      privateKey: '',
    },
    ftpConnection: {
      id: -1,
      type: 'ftp',
      name: '',
      hostname: '',
      initialdir: '',
      username: '',
      password: '',
      timeout: 20,
      port: 21,
      webUrl: '',
      previewRoot: '',
    },
    connectionType: 'ssh',
    loadingModal: false,
    editContainerFields: {
      id: -1,
      name: '',
    },
    editContainerDialogVisible: false,
    errorLogModalVisible: false,
    errorLog: '',
    errorTitle: '',
    searchInput: '',
    searchType: 'all',
  }

  componentDidMount() {
    this.getConnectionList()
    this.getContainersList()
  }

  render() {
    return (
      <div
        className="connection-list--wrapper"
        style={{ padding: '30px', backgroundColor: '#fff' }}
      >
        <NoContainersModal
          visible={this.state.containersDialogVisible}
          title="Warning!"
          onCancel={() => {
            this.setState({
              containersDialogVisible: false,
              connectionDialogVisible: true,
              editing: false,
            })
          }}
          onConfirm={() => {}}
          confirmText={<Link to={'/?create=true'}>Create a container</Link>}
          cancelText={'Later'}
        >
          To view and edit files on your connections, please create a container
          as this is a prerequisite to run the IDE.
        </NoContainersModal>
        <NoContentBox
          visible={
            this.state.connections.length === 0 &&
            !this.state.loadingConnections
          }
          // TODO: Image
          imageUrl={NO_CONTENT['containers']}
          title="Looks like you don't have any connections!"
          description="Connect your SSH or FTP servers with only a couple of clicks and access their files directly from the editor."
          buttonText="Create connection"
          buttonAction={() => {
            this.state.containers.length === 0
              ? this.setState({ containersDialogVisible: true })
              : this.setState({ connectionDialogVisible: true, editing: false })
          }}
        />
        <div
          style={{
            width: '100%',
            display:
              this.state.connections.length > 0 || this.state.loadingConnections
                ? 'flex'
                : 'none',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                this.setState({ connectionDialogVisible: true })
              }}
              style={{
                marginBottom: '20px',
                marginRight: '20px',
              }}
              type="primary"
            >
              New connection
            </Button>
            <Button
              onClick={this.getConnectionList}
              style={{
                marginBottom: '20px',
              }}
              loading={this.state.loadingConnections}
            >
              Refresh
            </Button>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              className="input-wrap"
              style={{
                marginRight: '20px',
              }}
            >
              <div className="mui-input">
                <div className="group">
                  <input
                    type="text"
                    value={this.state.searchInput}
                    className="conn-search"
                    onChange={e => {
                      e.preventDefault()
                      this.setState({
                        searchInput: e.target.value,
                      })
                    }}
                  />
                  <span className="bar"></span>
                  <label>Search</label>
                </div>
              </div>
            </div>

            <div className="input-wrap">
              <div className="mui-select">
                <div className="group">
                  <select
                    name="connection-type"
                    value={this.state.searchType}
                    onChange={e =>
                      this.setState({
                        searchType: e.target.value as
                          | 'all'
                          | 'ssh'
                          | 'ftp'
                          | 'ftps',
                      })
                    }
                  >
                    <option value="all">All</option>
                    <option value="ssh">SSH</option>
                    <option value="ftp">FTP Server</option>
                    <option value="ftps">FTPS Server</option>
                  </select>
                  <span className="bar"></span>
                  <label>Filter</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display:
              this.state.connections.length > 0 || this.state.loadingConnections
                ? 'block'
                : 'none',
          }}
        >
          <Connections
            connections={this.state.connections
              .filter(
                c =>
                  (this.state.searchType === 'all' ||
                    c.type === this.state.searchType) &&
                  ((c.name &&
                    c.name
                      .toLowerCase()
                      .includes(this.state.searchInput.toLowerCase())) ||
                    (c.hostname &&
                      c.hostname
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())))
              )
              .map(connection => {
                return {
                  ...connection,
                  key: connection.id.toString(),
                }
              })}
            loadingConnections={this.state.loadingConnections}
            onRemoveConnection={this.handleRemoveConnection}
            onEditConnectionClick={this.handleEditConnectionClick}
            onEditContainerClick={(id, name) => {
              this.setState({
                editContainerFields: {
                  id,
                  name,
                },
                editContainerDialogVisible: true,
                editing: true,
              })
            }}
            onRefreshConnections={this.getConnectionList}
          />
        </div>
        <ConnectionModal
          visible={this.state.connectionDialogVisible}
          validation={this.state.validation}
          title={this.state.editing ? 'Edit connection' : 'Create connection'}
          editing={this.state.editing}
          sshConnection={this.state.sshConnection}
          ftpConnection={this.state.ftpConnection}
          connectionType={this.state.connectionType}
          loading={this.state.loadingModal}
          onConfirm={this.handleConfirmConnection}
          onCancel={() => {
            this.resetForm()
            this.setState({
              connectionDialogVisible: false,
              editing: false,
            })
          }}
          onInputChange={this.handleConnectionInputChange}
          onFtpAuthChange={auth => {
            let ftpConnection = this.state.ftpConnection as FTPSConnection
            ftpConnection.auth = auth

            if (auth === 2) {
              ftpConnection.port = 990
            } else {
              ftpConnection.port = 21
            }

            this.setState({
              ftpConnection,
            })
          }}
          onSshAuthChange={auth => {
            this.setState({
              sshConnection: {
                ...this.state.sshConnection,
                auth,
              },
            })
          }}
          onConnectionTypeChange={type => {
            this.setState({
              connectionType: type as 'ssh' | 'ftp' | 'ftps',
            })
          }}
        />
        <Modal
          title={this.state.errorTitle}
          visible={this.state.errorLogModalVisible}
          onCancel={() => {
            this.setState({
              errorLogModalVisible: false,
              errorLog: '',
              errorTitle: '',
            })
          }}
          cancelText="Close"
        >
          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: '5px',
            }}
          >
            {this.state.errorLog
              .split('\\r')
              .join('')
              .split('\\n')
              .map(s => {
                return <span key={Math.random().toPrecision(4)}>{s}</span>
              })}
          </div>
        </Modal>
      </div>
    )
  }

  private handleConfirmConnection = async () => {
    this.setState({
      loadingModal: true,
    })

    let connection =
      this.state.connectionType === 'ssh'
        ? this.state.sshConnection
        : this.state.ftpConnection

    let { webUrl, previewRoot, initialdir, ...toValidate } = { ...connection }

    if (toValidate.type === 'ssh') {
      if (toValidate.auth !== SSHConnectionAuth['Password']) {
        let { password, ...noPassword } = { ...toValidate }
        toValidate = noPassword
      }

      if (toValidate.auth !== SSHConnectionAuth['Private Key']) {
        let { privateKey, ...noPrivateKey } = { ...toValidate }
        toValidate = noPrivateKey
      }
    }

    const validation = Validate(
      {
        ...toValidate,
      },
      {
        hostname: new RegExp(
          '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$'
        ),
      },
      {
        hostname: 'Not a valid hostname',
      }
    )

    this.setState({
      validation,
    })

    if (!validation.valid) {
      this.setState({
        loadingModal: false,
      })

      message.error('Connection is not valid')
      return
    }

    try {
      if (this.state.editing) {
        await this.props.apiClient.editConnection(connection)
      } else {
        await this.props.apiClient.addConnection(connection)
      }

      this.getConnectionList()
      this.setState({
        connectionDialogVisible: false,
      })
    } catch (e) {
      this.setState({
        errorLogModalVisible: true,
        errorLog: e.log || e.toString(),
        errorTitle: e.error || '',
      })
    } finally {
      this.setState({
        loadingModal: false,
      })
    }
  }

  private handleConnectionInputChange = (
    inputName: string,
    newVal: string | number
  ) => {
    let currConnection: { [x: string]: any } = {}
    currConnection[inputName] = newVal

    if (this.state.connectionType === 'ssh') {
      this.setState({
        sshConnection: {
          ...this.state.sshConnection,
          ...currConnection,
        },
      })
    } else {
      this.setState({
        ftpConnection: {
          ...this.state.ftpConnection,
          ...currConnection,
        },
      })
    }
  }

  private handleRemoveConnection = async (connectionId: number) => {
    await this.props.apiClient.removeConnection(connectionId)
    this.getConnectionList()
  }

  private handleEditConnectionClick = async (connection: ConnectionType) => {
    try {
      const connectionInfo = await this.props.apiClient.getConnection(
        connection.id
      )

      if (connection.type === 'ssh') {
        this.setState({
          sshConnection: connectionInfo as SSHConnection,
          editing: true,
          connectionDialogVisible: true,
          connectionType: 'ssh',
        })
      } else if (connection.type !== 'devbox') {
        this.setState({
          ftpConnection: connectionInfo as FTPConnection | FTPSConnection,
          connectionDialogVisible: true,
          editing: true,
          connectionType: connection.type,
        })
      }
    } catch (e) {
      notification.error({
        message: 'Error getting connection info',
      })
    }
  }

  private getContainersList = async () => {
    try {
      this.setState({
        containers: (await this.props.apiClient.getContainerList()).map(
          container => {
            const transformed = container as ContainerConnection

            return {
              key: transformed.id.toString(),
              ...transformed,
            }
          }
        ),
      })
    } catch (e) {
      notification.error({
        message: 'Error getting containers list.',
      })
    }
  }

  private getConnectionList = async () => {
    this.setState({
      loadingConnections: true,
    })

    try {
      this.setState({
        connections: (await this.props.apiClient.getConnectionList())
          .filter(conn => conn.type !== 'devbox')
          .map(conn => {
            const transformed = conn as ConnectionType

            return {
              key: transformed.id.toString(),
              ...transformed,
            }
          }),
      })
    } catch (e) {
      notification.error({
        message: 'Error getting connection list.',
      })
    } finally {
      this.setState({
        loadingConnections: false,
      })
    }
  }

  private resetForm() {
    this.setState({
      sshConnection: {
        id: -1,
        type: 'ssh',
        name: '',
        hostname: '',
        username: '',
        initialdir: '',
        timeout: 20,
        port: 22,
        webUrl: '',
        previewRoot: '',
        auth: SSHConnectionAuth['Password'],
        password: '',
        privateKey: '',
      },
      ftpConnection: {
        id: -1,
        type: 'ftp',
        name: '',
        hostname: '',
        initialdir: '',
        username: '',
        password: '',
        timeout: 20,
        port: 21,
        webUrl: '',
        previewRoot: '',
      },
      connectionType: 'ssh',
    })
  }
}
