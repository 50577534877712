import AmazonPayIcon from '../../styles/img/credit-cards/amazon-pay-icon.svg'
import AmazonPay from '../../styles/img/credit-cards/amazon-pay.svg'
import Amex from '../../styles/img/credit-cards/amex.svg'
import ApplePayBlack from '../../styles/img/credit-cards/apple-pay-black.svg'
import ApplePayWhite from '../../styles/img/credit-cards/apple-pay-white.svg'
import Discover from '../../styles/img/credit-cards/discover.svg'
import GooglePay from '../../styles/img/credit-cards/google-pay.svg'
import Maestro from '../../styles/img/credit-cards/maestro.svg'
import MasterCard from '../../styles/img/credit-cards/mastercard.svg'
import PaypalPay from '../../styles/img/credit-cards/paypal-pay.svg'
import PaypalIcon from '../../styles/img/credit-cards/paypal-icon.svg'
import VenmoBlue from '../../styles/img/credit-cards/venmo-blue.svg'
import VenmoWhite from '../../styles/img/credit-cards/venmo-white.svg'
import Visa from '../../styles/img/credit-cards/visa.svg'
import VisaElectron from '../../styles/img/credit-cards/visa-electron.svg'

export const PAYMENT: { [x: string]: string } = {
  AmazonPay,
  AmazonPayIcon,
  Amex,
  ApplePayBlack,
  ApplePayWhite,
  Discover,
  GooglePay,
  Maestro,
  MasterCard,
  PaypalPay,
  PaypalIcon,
  VenmoBlue,
  VenmoWhite,
  Visa,
  VisaElectron,
}
