import { Button, Card, Pagination, Spin } from 'antd'
import React from 'react'
import { Share } from '@codeanywhere/collaboration-service-api-client'

type Props = {
  loadingShares: boolean
  shares: Share[]
  currPage: number
  pageSize: number
  loadingShareId: string
  onCurrPageChange: (nextPage: number) => void
  onPageSizeChange: (pageSize: number) => void
  onLeaveShareClick: (shareId: string) => void
}

export class ShareCards extends React.Component<Props> {
  render() {
    if (this.props.loadingShares) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={'spinner-screen'}>
            <Spin size="large" />
          </div>
        </div>
      )
    }
    return (
      <>
        <div className="container-cards--wrapper">
          {this.props.shares
            .slice(
              (this.props.currPage - 1) * this.props.pageSize,
              this.props.currPage * this.props.pageSize
            )
            .map(share => {
              return (
                <Card
                  key={share.id}
                  title={share.containerName || ''}
                  extra={
                    <Button
                      type="default"
                      loading={this.props.loadingShareId === share.id}
                      onClick={() => this.props.onLeaveShareClick(share.id)}
                    >
                      Leave
                    </Button>
                  }
                >
                  <div
                    className="container-card--footer"
                    style={{ marginTop: 0, alignItems: 'center' }}
                  >
                    <div>
                      <strong>Owner:</strong> {share.admin.username}
                    </div>
                    <div className="open-ide--wrapper">
                      <Button id="open-ide--button" type="primary">
                        <a
                          href={share.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Open IDE
                        </a>
                      </Button>
                    </div>
                  </div>
                </Card>
              )
            })}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            showSizeChanger
            total={this.props.shares.length}
            showTotal={total => `${total} shares`}
            defaultPageSize={this.props.pageSize}
            onChange={this.props.onCurrPageChange}
            onShowSizeChange={(curr, pageSize) =>
              this.props.onPageSizeChange(pageSize)
            }
            current={this.props.currPage}
          />
        </div>
      </>
    )
  }
}
