import React from 'react'
import { AccountInformationContainer } from '../containers/AccountInformationContainer'
import { DeleteAccountContainer } from '../containers/DeleteAccountContainer'
import { ChangePasswordContainer } from '../containers/ChangePasswordContainer'
import { ConnectedAccountsContainer } from '../containers/ConnectedAccountsContainer'
import { TwoFactorAuthContainer } from '../containers/TwoFactorAuthContainer'
import { ApiClient } from '../api-client/interface/ApiClient'
import { notification } from 'antd'
import { AccountPublicKeyContainer } from '../containers/AccountPublicKeyContainer'
import { AccountLimitsContainer } from '../containers/AccountLimitsContainer'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'

type Props = {
  apiClient: ApiClient
  setNavigationTitle: (title: string) => void
  userId: number
  email: string
}

type State = {
  isChildAccount: boolean
  loading: boolean
  hasActiveSubscription: boolean
}

export class AccountPage extends React.Component<Props, State> {
  public state: State = {
    isChildAccount: false,
    loading: true,
    hasActiveSubscription: false,
  }

  componentDidMount() {
    this.getAccountData()
    this.props.setNavigationTitle('Account')

    TagManager.dataLayer({
      dataLayer: {
        event: 'openAccountScreen',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
  }

  render() {
    return (
      <div className="main account">
        <div className="main__col2">
          <AccountInformationContainer apiClient={this.props.apiClient} />
          <ConnectedAccountsContainer apiClient={this.props.apiClient} />
          <AccountPublicKeyContainer apiClient={this.props.apiClient} />
        </div>

        <div className="main__col2">
          <AccountLimitsContainer apiClient={this.props.apiClient} />

          <TwoFactorAuthContainer apiClient={this.props.apiClient} />
          <ChangePasswordContainer apiClient={this.props.apiClient} />

          {this.state.loading || this.state.hasActiveSubscription ? (
            <></>
          ) : (
            <DeleteAccountContainer apiClient={this.props.apiClient} />
          )}
        </div>
      </div>
    )
  }

  private async getAccountData() {
    const isChildAccount = await this.getIsChildAccount()
    await this.getAccountSubscription(isChildAccount)

    this.setState({
      loading: false,
    })
  }

  private async getIsChildAccount() {
    try {
      const account = await this.props.apiClient.getAccountDetails()

      this.setState({
        isChildAccount: account.child_account,
      })
      return account.child_account
    } catch (e) {
      notification.error({
        message: 'Error getting your account info',
      })
      return false
    }
  }

  private async getAccountSubscription(isChildAccount: boolean) {
    try {
      const subscription = await this.props.apiClient.getAccountSubscription(
        true
      )
      this.setState({
        hasActiveSubscription: subscription !== null,
      })
    } catch (ex) {
      //silently fail
      if (isChildAccount) {
        return
      }
      notification.error({
        message: 'An error occurred while getting your subscription',
      })
    }
  }
}
